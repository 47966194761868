import { Grid, GridProps, useTheme } from "@mui/material";
import Box from "components/Box";
import { CustomThemeOptions } from "libs/theme";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonWrapper from "./components/ButtonWrapper";
import KeysAction from "./components/KeysAction";
import KeyTables from "./components/KeysTable";
import LogsAction from "./components/LogsAction";
import LogsTable from "./components/LogsTable";
import DevelopContext from "./context/DevelopContext";

const BoxWrapper = ({ children, style, ...props }: GridProps) => {
  return (
    <Box style={{ padding: 10, ...style }} {...props}>
      {children}
    </Box>
  );
};

export default function Develop() {
  const theme = useTheme() as CustomThemeOptions;
  const { search } = useLocation();
  const navigate = useNavigate();
  const keyTableRef = useRef<any>(null);
  const logTableRef = useRef<any>(null);

  const queryParams = new URLSearchParams(search);
  const tabParam = queryParams.get("tab") ?? "api-keys";

  return (
    <DevelopContext.Provider
      value={{
        keyTableRef,
        logTableRef,
      }}
    >
      <BoxWrapper>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container style={{ gap: 8 }}>
              {[
                {
                  label: "API Keys",
                  value: "api-keys",
                },
                {
                  label: "Logs",
                  value: "api-logs",
                },
              ].map((item, index) => {
                return (
                  <ButtonWrapper
                    style={{
                      backgroundColor:
                        tabParam === item.value
                          ? theme.palette.primary.light
                          : "transparent",
                      color:
                        tabParam === item.value
                          ? theme.palette.primary.main
                          : theme.palette.blueGray[500],
                      height: 40,
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.main,
                        boxShadow: "none",
                      },
                    }}
                    onClick={() => {
                      navigate(`/develop?tab=${item.value}`);
                    }}
                    key={index}
                  >
                    {item.label}
                  </ButtonWrapper>
                );
              })}
            </Grid>
          </Grid>
          <Grid item>
            {tabParam === "api-keys" ? <KeysAction /> : null}
            {tabParam === "api-logs" ? <LogsAction /> : null}
          </Grid>
        </Grid>
      </BoxWrapper>

      <BoxWrapper
        style={{ marginTop: 20, padding: 20, justifyContent: "start" }}
      >
        {tabParam === "api-keys" ? <KeyTables /> : null}
        {tabParam === "api-logs" ? <LogsTable /> : null}
      </BoxWrapper>
    </DevelopContext.Provider>
  );
}
