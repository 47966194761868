import { ButtonProps, Grid } from "@mui/material";
import Dialog, { DialogProps } from "components/Dialog";
import Text from "components/Text";
import { useTheme } from "hooks/useTheme";
import ButtonWrapper from "pages/develop/components/ButtonWrapper";
import { FC } from "react";

type Props = DialogProps & {
  header?: string;
  onConfirm?: () => void;
  message?: string;
  variant?: "default" | "danger" | "warning";
  customActions?: React.ReactNode;
  children?: React.ReactNode;
};
const ConfirmDialog: FC<Props> = ({
  header,
  message,
  onConfirm,
  variant = "default",
  customActions,
  children,
  ...props
}) => {
  const theme = useTheme();

  const btnColor: ButtonProps["color"] =
    variant === "danger"
      ? "error"
      : variant === "warning"
      ? "warning"
      : "primary";

  return (
    <Dialog {...props}>
      <Grid
        container
        justifyContent="center"
        alignItems={"center"}
        direction={"column"}
      >
        {/* <TopUpIcon /> */}

        <Text variant="h3" color={theme.palette.blueGray[600]} fontWeight={600}>
          {header ?? "Confirmation"}
        </Text>
        <Text variant="h5" style={{ marginTop: "12px" }} fontWeight={400}>
          {message ?? "Are you sure you want to proceed?"}
        </Text>
        {children}
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ marginTop: 30, gap: 16 }}
        >
          {customActions ? (
            customActions
          ) : (
            <>
              <ButtonWrapper
                variant="outlined"
                style={{
                  backgroundColor: "inherit",
                  border: 1.5,
                  flexGrow: 1,
                  height: "44px",
                }}
                sx={{
                  "&:hover": {
                    border: 1.5,
                  },
                }}
                onClick={(e) => props.onClose?.(e, "backdropClick")}
              >
                Cancel
              </ButtonWrapper>
              <ButtonWrapper
                style={{
                  height: "44px",
                  flexGrow: 1,
                  ...(btnColor === "error"
                    ? {
                        backgroundColor: theme.palette.deepOrange[500],
                      }
                    : btnColor === "warning"
                    ? {
                        backgroundColor: "rgb(245, 124, 0)",
                      }
                    : {}),
                }}
                color={btnColor}
                onClick={onConfirm}
                type="submit"
              >
                Confirm
              </ButtonWrapper>
            </>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmDialog;
