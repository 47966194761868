import { RootState } from "libs/store";
import { useSelector } from "react-redux";

export const useHasApiAccess = () => {
  const { user, showAPIWarning } = useSelector(
    (state: RootState) => state.user
  );

  const has_api_access = user?.has_api_access ?? true;
  const height = !has_api_access && showAPIWarning ? 64 : 0;

  return {
    has_api_access,
    showAPIWarning,
    height,
  };
};
