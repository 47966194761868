import { useTheme } from "@mui/material";
import { InputProps as InputPasswordPropsComp } from "components/Input";
import InputComp from "components/InputPassword";
import Text from "components/Text";
import { CustomThemeOptions } from "libs/theme";
import { FC } from "react";
import { Controller, ControllerProps } from "react-hook-form";

export type InputPasswordProps = Omit<ControllerProps, "render" | "control"> &
  InputPasswordPropsComp & {
    control: any;
  };
const Input: FC<InputPasswordProps> = ({
  control,
  name,
  inputStyle,
  label,
  style,
  ...restProps
}) => {
  const theme = useTheme() as CustomThemeOptions;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <InputComp
          {...field}
          textHelper={
            fieldState.error ? (
              <Text
                variant="h6"
                style={{ color: theme.palette.deepOrange[500] }}
              >
                {fieldState.error.message}
              </Text>
            ) : undefined
          }
          {...{ inputStyle, label, style }}
        />
      )}
      {...restProps}
    />
  );
};

export default Input;
