import { Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import SubscriptionsTable from "./Plans/SubscriptionsTable";
import MySubscriptionTable from "./MySubscription/MySubscriptionTable";

enum TabValue {
  PLAN,
  MY_SUBSCRIPTIONS,
}
const SubscriptionMain = () => {
  const [tab, setTab] = useState<TabValue>(TabValue.PLAN);

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTab(newValue);
  };

  return (
    <Grid container>
      <Tabs value={tab} onChange={handleChange}>
        <Tab
          value={TabValue.PLAN}
          style={{ textTransform: "none" }}
          label="Subscription Plans"
        />
        <Tab
          value={TabValue.MY_SUBSCRIPTIONS}
          style={{ textTransform: "none" }}
          label="My Subscriptions"
        />
      </Tabs>
      {tab === TabValue.PLAN && <SubscriptionsTable />}
      {tab === TabValue.MY_SUBSCRIPTIONS && <MySubscriptionTable />}
    </Grid>
  );
};

export default SubscriptionMain;
