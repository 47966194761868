import { InputAdornment, useTheme } from "@mui/material";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { CustomThemeOptions } from "libs/theme";
import { FC, useState } from "react";
import {
  DateRangePicker as DateRangePickerLib,
  DateRange,
  DateRangePickerProps,
  defaultStaticRanges,
} from "react-date-range";
import Input from "./Input";
import { useIsMobile } from "hooks/useIsMobile";
import CustomPopover from "./CustomPopover";
import { isEqual, subMonths } from "date-fns";

type Props = DateRangePickerProps & {
  value: {
    startDate: Date;
    endDate: Date;
  };
  onChange: (value: { startDate: Date; endDate: Date }) => void;
  label?: string;
};
const DateRangePicker: FC<Props> = ({
  value,
  onChange,
  label,
  ...restProps
}) => {
  const theme = useTheme() as CustomThemeOptions;
  const [state, setState] = useState(value);

  const isMobile = useIsMobile();

  const handleSelect = (ranges: any) => {
    setState(ranges.selection);
  };

  return (
    <>
      <CustomPopover
        PopoverHandler={
          <Input
            style={{
              width: 223,
              height: 36,
            }}
            sx={{
              "& .MuiInputBase-input": {
                padding: "0px !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgb(208 216 221)",
              },
            }}
            inputStyle={{
              height: 36,
              padding: "8px 12px",
              borderRadius: "5px !important",
            }}
            inputProps={{
              style: {
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "19.6px",
                color: theme.palette.blueGray[500],
                padding: "0px !important",
                height: 36,
              },
            }}
            endAdornment={
              <InputAdornment position="end">
                <CalendarIcon />
              </InputAdornment>
            }
            readOnly
            value={`${
              value.startDate
                ? value.startDate.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })
                : ""
            } - ${
              value.endDate
                ? value.endDate.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })
                : ""
            }`}
          />
        }
        PopoverContent={
          isMobile ? (
            <DateRange
              onChange={handleSelect}
              moveRangeOnFirstSelection
              ranges={[state]}
              direction="horizontal"
              {...restProps}
            />
          ) : (
            <DateRangePickerLib
              onChange={handleSelect}
              moveRangeOnFirstSelection
              ranges={[state]}
              direction="horizontal"
              staticRanges={[
                ...defaultStaticRanges,
                {
                  label: "Last 3 Months",
                  range: () => ({
                    startDate: subMonths(new Date(), 3),
                    endDate: new Date(),
                  }),
                  isSelected: () => false,
                },
              ]}
              {...restProps}
            />
          )
        }
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => {
          if (
            !isEqual(state.startDate, value.startDate) ||
            !isEqual(state.endDate, value.endDate)
          ) {
            onChange(state);
          }
        }}
        label={label}
      />
    </>
  );
};

export default DateRangePicker;
