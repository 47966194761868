const LockSvg = ({ fill = "#607D8B" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M10 14.9589C10.7502 14.9589 11.3583 14.3507 11.3583 13.6005C11.3583 12.8503 10.7502 12.2422 10 12.2422C9.24981 12.2422 8.64166 12.8503 8.64166 13.6005C8.64166 14.3507 9.24981 14.9589 10 14.9589Z"
        fill={fill}
      />
      <path
        d="M13.875 8.36719H6.12502C2.70835 8.36719 1.66669 9.40885 1.66669 12.8255V14.3755C1.66669 17.7922 2.70835 18.8339 6.12502 18.8339H13.875C17.2917 18.8339 18.3334 17.7922 18.3334 14.3755V12.8255C18.3334 9.40885 17.2917 8.36719 13.875 8.36719ZM10 16.1172C8.60835 16.1172 7.48335 14.9839 7.48335 13.6005C7.48335 12.2172 8.60835 11.0839 10 11.0839C11.3917 11.0839 12.5167 12.2172 12.5167 13.6005C12.5167 14.9839 11.3917 16.1172 10 16.1172Z"
        fill={fill}
      />
      <path
        opacity="0.4"
        d="M5.93333 8.37435V7.39935C5.93333 4.95768 6.625 3.33268 10 3.33268C13.375 3.33268 14.0667 4.95768 14.0667 7.39935V8.37435C14.4917 8.38268 14.875 8.39935 15.2333 8.44935V7.39935C15.2333 5.14935 14.6917 2.16602 10 2.16602C5.30833 2.16602 4.76666 5.14935 4.76666 7.39935V8.44102C5.11666 8.39935 5.50833 8.37435 5.93333 8.37435Z"
        fill={fill}
      />
    </svg>
  );
};

export default LockSvg;
