import axios from "libs/axios";
import { stringify } from "querystring";

export type TimePeriodStats = {
  selected_period: {
    start_date: string;
    end_date: string;
  };
  aggregate_stats: {
    total_requests: number;
    total_cost: number;
    average_latency: number;
  };
  timeseries: {
    interval: string;
    data_points: {
      timestamp: string;
      requests: number;
      cost: number;
      average_latency: number;
    }[];
  };
};

type Props = {
  summary: {
    current_balance: number;
    costs: {
      current_month: number;
      all_time: number;
    };
    requests: {
      current_month: number;
      all_time: number;
    };
  };
  time_period_stats: TimePeriodStats;
};

type Parameter = {
  date_range?: any;
  filter_by?: "service" | "api_key";
  filter_id?: number;
};

export const usageAnalytics = async (
  params: Parameter
): Promise<Props | undefined> => {
  try {
    const response = await axios.get(
      `/stats/usage-analytics?${stringify(params)}`
    );
    return response.data;
  } catch (error) {
    return undefined;
  }
};
