import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import { getSupportNotifications, getTimezones } from "actions/misc";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import Divide from "components/Divide";
import Text from "components/Text";
import CheckboxGroup from "components/form-control/CheckboxGroup";
import Input from "components/form-control/Input";
import { setUser } from "features/userSlice";
import { useIsMobile } from "hooks/useIsMobile";
import { RootState } from "libs/store";
import { CustomThemeOptions } from "libs/theme";
import { emailRule } from "pages/auth/utils/rules";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../actions/updateUser";
import Autocomplete from "components/Autocomplete";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";

const MyAccount = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();
  const theme = useTheme() as CustomThemeOptions;

  const { Backdrop, close, open } = useBackdrop();

  const { control, handleSubmit, reset, getValues } = useForm();

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const {
    data: supportNotifications,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["support_notifications"],
    queryFn: getSupportNotifications,
    staleTime: Infinity,
    enabled: false,
  });

  const {
    data: timezones,
    refetch: refetchTimezones,
    isFetching: isFetchingTimezones,
  } = useQuery({
    queryKey: ["timezones"],
    queryFn: getTimezones,
    staleTime: Infinity,
    enabled: false,
  });

  useEffect(() => {
    if (user?.id) {
      reset({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        notifications: user.notifications.map(String) as any,
        timezone_str: user.timezone_str,
      });
      refetchTimezones();
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  const mutation = useMutation(updateUser, {
    onSuccess: (data) => {
      dispatch(setUser(data));
      close();
    },
    onError: () => {
      close();
    },
  });

  const onSubmit = (data: any) => {
    if (data.email?.trim() !== user?.email) {
      setOpenConfirmModal(true);
      return;
    }
    handleConfirmSubmit();
  };

  const handleConfirmSubmit = () => {
    const data: any = getValues();
    open();
    mutation.mutate({
      ...data,
      user_id: user?.id,
      notification_ids: data.notifications.map(Number),
    });
  };

  const isMobile = useIsMobile();

  const notificationOptions = useMemo(() => {
    return (
      supportNotifications?.map((item) => ({
        label: item.label,
        value: String(item.id),
      })) ?? []
    );
  }, [supportNotifications]);

  const timezoneOptions = useMemo(() => {
    return (
      timezones?.map((item: string) => ({
        label: item,
        value: item,
      })) ?? []
    );
  }, [timezones]);

  return (
    <Grid container direction="column" style={{ padding: 10 }}>
      <Grid item>
        <Text variant="h2">Profile Settings</Text>
      </Grid>

      <Divide
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
      />

      <Grid item style={{ width: isMobile ? "unset" : 720 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" style={{ gap: 20 }}>
            <Grid item>
              <Grid container style={{ gap: 20 }}>
                <Grid item style={{ flexGrow: 1 }}>
                  <Input
                    control={control}
                    name="first_name"
                    label="First Name"
                    rules={{
                      required: "First Name is required",
                    }}
                  />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <Input
                    control={control}
                    name="last_name"
                    label="Last Name"
                    rules={{
                      required: "Last Name is required",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Input
                control={control}
                name="email"
                label="Email Address"
                rules={emailRule}
              />
            </Grid>
            {!!timezoneOptions.length && !isFetchingTimezones && (
              <Grid item>
                <Controller
                  control={control}
                  name="timezone_str"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Autocomplete
                        onChange={(event, item) => {
                          onChange(item.value);
                        }}
                        value={value}
                        label="Time Zone"
                        options={timezoneOptions}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: 42,
                            padding: "8px 16px",
                          },
                          "& .MuiInputBase-input": {
                            height: 0,
                            paddingLeft: "0px !important",
                          },
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            )}

            {!!notificationOptions.length && !isFetching && (
              <Grid item>
                <Grid container direction="column">
                  <CheckboxGroup
                    control={control}
                    title="Notifications"
                    name="notifications"
                    labelStyle={{
                      color: theme.palette.blueGray[800],
                    }}
                    options={notificationOptions}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Button fullWidth type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <ConfirmDialog
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        variant="warning"
        onConfirm={handleConfirmSubmit}
        header="Warning"
        message="If you change your email, you will need to verify the new address
        before you can log in again. Do you want to proceed?"
      />
      <Backdrop />
    </Grid>
  );
};

export default MyAccount;
