import { useState } from "react";
import BackdropComp from "../Backdrop";

export const useBackdrop = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);

  const close = () => setIsOpen(false);

  const Backdrop = () => <BackdropComp open={isOpen} />;

  return { Backdrop, open, close };
};
