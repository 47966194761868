import { Grid } from "@mui/material";
import { useTheme } from "@mui/system";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import Chip from "components/Chip";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import DataTable from "components/DataTable";
import { useParams } from "hooks/useParams";
import { CustomThemeOptions } from "libs/theme";
import { createSubscription } from "pages/billing/actions/createSubscription";
import BillingContext from "pages/billing/context/BillingContext";
import { useContext, useMemo } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { formatCurrency, formatNumeric } from "utils/mix";
import SubscriptionsHeaderTable from "./SubscriptionsHeaderTable";

const SubscriptionsTable = () => {
  const theme = useTheme() as CustomThemeOptions;
  const { Backdrop, close, open } = useBackdrop();
  const navigate = useNavigate();
  const mutation = useMutation(createSubscription, {
    onSuccess: () => {
      close();
    },
    onError: () => {
      close();
    },
  });
  const { query } = useContext(BillingContext);

  const planId = useParams("plan_id");

  const handleSubscription = (plan_id: number) => {
    open();
    mutation.mutate({
      plan_id,
    });
  };

  const handleClose = () => {
    navigate("/billing?tab=subscriptions");
  };

  const newQuery = useMemo(() => {
    if (query?.service) {
      return {
        ...query,
        service_id: query?.service?.id,
      };
    }
    return query;
  }, [query]);

  return (
    <>
      <DataTable
        columns={[
          {
            id: "service_name",
            label: "Service name",
            style: {
              minWidth: 120,
            },
            cellRender(value, rawData, isExpanded) {
              return (
                <Grid
                  container
                  style={{
                    gap: 10,
                    fontWeight: 600,
                    color: theme.palette.blueGray[800],
                  }}
                  alignItems={"center"}
                >
                  {rawData.service.name}
                  {rawData.is_private && <ChipWrapper text="Private" />}
                </Grid>
              );
            },
          },
          {
            id: "name",
            label: "Plan Name",
            style: {
              fontWeight: 600,
              minWidth: 200,
              color: theme.palette.blueGray[800],
            },
          },
          {
            id: "price",
            label: "Price",
            style: {
              fontWeight: 600,
              color: theme.palette.blueGray[800],
            },
            sortable: true,
            cellRender(value) {
              return formatCurrency(value);
            },
          },
          {
            id: "cost_per_request",
            label: "Cost Per Request",
            style: {
              fontWeight: 600,
              color: theme.palette.blueGray[800],
            },
            cellRender(value) {
              return formatCurrency(value);
            },
          },
          {
            id: "concurrency",
            label: "Concurrency",
            sortable: true,
            style: {
              fontWeight: 600,
              color: theme.palette.blueGray[800],
            },
            cellRender(value) {
              return formatNumeric(value);
            },
          },
        ]}
        customActions={(row) => (
          <Button
            style={{ height: 36 }}
            onClick={() => {
              navigate(`/billing?tab=subscriptions&plan_id=${row.id}`);
            }}
          >
            Subscribe
          </Button>
        )}
        header={SubscriptionsHeaderTable}
        fetchUrl="products/subscription-plans"
        query={newQuery}
      />
      <ConfirmDialog
        open={!!planId}
        onClose={handleClose}
        onConfirm={() => {
          handleClose();
          handleSubscription(Number(planId));
        }}
      />
      <Backdrop />
    </>
  );
};

export default SubscriptionsTable;

const ChipWrapper = ({ text }: { text: string }) => {
  const theme = useTheme();
  return (
    <Chip
      text={text}
      color={theme.palette.primary["main"]}
      dotDisplay={false}
      style={{
        padding: "3px 12px",
        fontWeight: 400,
      }}
    />
  );
};
