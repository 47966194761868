import {
  CSSObject,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Theme,
  styled,
  useTheme,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import LogoSmall from "assets/images/logo-small.png";
import Logo from "assets/images/logo.png";
import { CustomThemeOptions } from "libs/theme";
import { FC, cloneElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "routes/path";
import Text from "./Text";
import { useIsMobile, useMobileVersion } from "hooks/useIsMobile";
import { useHasApiAccess } from "hooks/useHasApiAccess";

export const drawerWidth = 252;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 76,
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type Props = {
  open: boolean;
  onClose: () => void;
};
const Sidebar: FC<Props> = ({ open, onClose }) => {
  const { pathname } = useLocation();
  const theme = useTheme() as CustomThemeOptions;
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { height } = useHasApiAccess();

  const DrawComp = useMobileVersion({
    desktop: Drawer,
    mobile: MuiDrawer,
  });
  return (
    <DrawComp
      open={open}
      onClose={onClose}
      {...(isMobile
        ? {
            disableScrollLock: true,
          }
        : {
            variant: "permanent",
          })}
      sx={{
        "& .MuiDrawer-paper": {
          top: height,
        },
      }}
    >
      <DrawerHeader
        style={{
          justifyContent: "flex-start",
          ...(open
            ? {
                paddingTop: 20,
                paddingLeft: 28,
              }
            : {
                padding: 20,
              }),
        }}
      >
        <img
          src={open ? Logo : LogoSmall}
          onClick={() => navigate("/")}
          width={open ? 138 : 36}
          style={{
            cursor: "pointer",
          }}
          alt="Logo"
        />
      </DrawerHeader>
      <List
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        {Paths.filter((el) => !el.isHeader && !el.isInvisible).map(
          (path, index) => {
            const isMatch = pathname === path.path;
            return (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor: isMatch
                    ? theme.palette.primary["light"]
                    : undefined,
                  color: isMatch ? theme.palette.primary["main"] : undefined,
                  borderRadius: "8px",
                  marginBottom: "6px",
                  marginTop: "6px",
                }}
                onClick={() => {
                  onClose();
                  // If path is external, open in new tab
                  if (path.isExternal) {
                    window.open(path.path, "_blank");
                    return;
                  }
                  navigate(path.path);
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  {path.icon && (
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 0,
                        justifyContent: "center",
                      }}
                    >
                      {cloneElement(path.icon as any, {
                        fill: isMatch
                          ? theme.palette.primary["main"]
                          : path.color,
                      })}
                    </ListItemIcon>
                  )}
                  {open && (
                    <Text
                      variant="h6"
                      style={{
                        fontWeight: isMatch ? 500 : 400,
                      }}
                    >
                      {path.name}
                    </Text>
                  )}
                </ListItemButton>
              </ListItem>
            );
          }
        )}
      </List>
    </DrawComp>
  );
};

export default Sidebar;
