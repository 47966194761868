import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export const isTokenExpired = () => {
  const token = Cookies.get("token");

  if (!token) {
    return true;
  }
  const currentTime = Date.now() / 1000;

  const decodedToken = jwtDecode(token);
  if (!decodedToken) {
    return false;
  }
  if ((decodedToken?.exp ?? 0) < currentTime) {
    return true;
  } else {
    return false;
  }
};

export const useAuth = () => {
  const isAuthenticated = () => {
    return !isTokenExpired();
  };

  return { isAuthenticated };
};
