import { Grid } from "@mui/material";
import Button from "components/Button";
import Text from "components/Text";
import NotFoundImg from "assets/images/404.png";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      direction="column"
      style={{
        height: "100vh",
      }}
    >
      <img src={NotFoundImg} alt="" width={500} />
      <Text variant="h1" style={{ marginTop: 20 }}>
        Something went wrong.
      </Text>
      <Text variant="body1">
        Sorry, We can't find the page you're looking for.
      </Text>
      <Button
        onClick={() => {
          navigate("/");
        }}
        style={{ marginTop: 10 }}
      >
        Go back to Home
      </Button>
    </Grid>
  );
}
