import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

export const createSubscription = async (data: { plan_id: number }) => {
  try {
    const response = await axios.post("/billing/create-subscription", data);
    toast.success("Subscription created successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to create. Please try again"
    );
    throw error;
  }
};
