import { useTheme } from "@emotion/react";
import { Grid, Skeleton } from "@mui/material";
import Text from "components/Text";
import { CustomThemeOptions } from "libs/theme";
import { CSSProperties, FC } from "react";

type Props = {
  title: string;
  body: string;
  icon: React.ReactNode;
  style?: CSSProperties;
  isLoading?: boolean;
};
const Card: FC<Props> = ({ title, body, icon, style, isLoading }) => {
  const theme = useTheme() as CustomThemeOptions;
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-end"
      style={{
        padding: 20,
        borderRadius: 16,
        boxShadow:
          "0px 0px 2px 0px rgba(153, 169, 182, 0.20), 0px 12px 24px -4px rgba(153, 169, 182, 0.12)",
        minWidth: 324,
        ...style,
      }}
    >
      <Grid item>
        <Text
          variant="h5"
          style={{
            color: theme.palette.blueGray[400],
            fontWeight: 400,
          }}
        >
          {isLoading ? <Skeleton width={107} /> : title}
        </Text>
        <Text
          variant="h1"
          style={{
            color: theme.palette.blueGray[800],
            marginTop: 6,
          }}
        >
          {isLoading ? <Skeleton width={80} /> : body}
        </Text>
      </Grid>
      {icon}
    </Grid>
  );
};

export default Card;
