import React from "react";
import { Status } from "../components/Subscriptions/MySubscription/MySubscriptionTable";

type Props = {
  query: {
    service_id: string;
    private: boolean;
    status: string;
    service?: any;
  };
  setQuery: (query: any) => void;
  tableMySubscriptionRef: any;
};

export const defaultQuery = {
  service_id: "0",
  service: {
    id: "0",
    name: "All Services",
    isEmpty: true,
  },
  private: false,
  status: Status.ACTIVE,
};
const BillingContext = React.createContext<Props>({
  query: { ...defaultQuery },
  setQuery: () => {},
  tableMySubscriptionRef: null,
});

export default BillingContext;
