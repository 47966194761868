import { Grid, OutlinedInput, OutlinedInputProps } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import Text from "./Text";

export type InputProps = Omit<OutlinedInputProps, "label"> & {
  inputStyle?: OutlinedInputProps["style"];
  label?: string;
  textHelper?: string | React.ReactNode;
  numeric?: boolean;
};
const Input = forwardRef<any, InputProps>(
  (
    { inputStyle, textHelper, style, placeholder, label, numeric, ...props },
    ref
  ) => {
    const [value, setValue] = useState<any>(props.value ?? "");

    useEffect(() => {
      if (!numeric && props.value !== value) {
        setValue(props.value as any);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value, numeric]);

    const getTextHelper = () => {
      if (textHelper) {
        if (typeof textHelper === "string") {
          return <Text>{textHelper}</Text>;
        }
        return textHelper;
      }
      return undefined;
    };
    return (
      <Grid style={style} container direction={"column"}>
        {label && (
          <Text variant="h6" style={{ marginBottom: 8 }}>
            {label}
          </Text>
        )}
        {numeric ? (
          <NumericFormat
            {...(props as any)}
            value={value}
            onChange={props.onChange}
            label=""
            style={{
              ...inputStyle,
            }}
            placeholder={placeholder || label}
            customInput={OutlinedInput}
            thousandSeparator
          />
        ) : (
          <OutlinedInput
            ref={ref}
            {...props}
            value={value}
            onChange={props.onChange}
            label=""
            style={{
              ...inputStyle,
            }}
            placeholder={placeholder || label}
          />
        )}
        {getTextHelper()}
      </Grid>
    );
  }
);

export default Input;
