import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";
export interface IncreaseQuotaRequest {
  service_id: number;
  new_concurrency?: number;
  new_per_request_price?: number;
}
export const updateQuota = async (body: IncreaseQuotaRequest) => {
  try {
    if (!body.new_concurrency) {
      delete body.new_concurrency;
    }

    if (!body.new_per_request_price) {
      delete body.new_per_request_price;
    }

    const res = await axios.post(`quotas/quota-increase-request`, body);
    toast.success(
      "Your quota increase request has been submitted successfully."
    );
    return res.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to update. Please try again"
    );
    throw error;
  }
};
