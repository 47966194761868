import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import Divide from "components/Divide";
import Input from "components/Input";
import InputPassword from "components/InputPassword";
import Switch from "components/Switch";
import Text from "components/Text";
import { setUser } from "features/userSlice";
import { RootState } from "libs/store";
import { CustomThemeOptions } from "libs/theme";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toggleTwoFA } from "../actions/toogleTwoFA";
import { twoFASetup } from "../actions/twoFASetup";
import { useIsMobile, useMobileVersion } from "hooks/useIsMobile";
import { toast } from "react-toastify";

const defaultOtpAuth = {
  provision_uri: "",
  two_fa_secret: "",
  otp: "",
};
const TwoFASecurity = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();
  const theme = useTheme() as CustomThemeOptions;

  const { Backdrop, close, open } = useBackdrop();
  const [isEnable, setIsEnable] = useState(user?.two_factor_enabled ?? false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [password, setPassword] = useState("");
  const [otpAuth, setOtpAuth] = useState({ ...defaultOtpAuth });

  const mutation = useMutation(twoFASetup, {
    onSuccess: ({ two_fa_secret, provision_uri }) => {
      setIsShowConfirm(false);
      setPassword("");
      setOtpAuth({ two_fa_secret, provision_uri, otp: "" });
      close();
    },
    onError: () => {
      close();
    },
  });

  useEffect(() => {
    if (user?.id) {
      setIsEnable(user.two_factor_enabled ?? false);
    }
  }, [user]);

  const mutationToggle2FA = useMutation(toggleTwoFA, {
    onSuccess: () => {
      const newUser = Object.assign({}, user);
      newUser.two_factor_enabled = isEnable;
      dispatch(setUser(newUser));
      setIsEnable(!isEnable);
      setOtpAuth({ ...defaultOtpAuth });
      close();
    },
    onError: () => {
      close();
    },
  });

  const handleEnableConfig = () => {
    if (password) {
      open();
      mutation.mutate({
        password,
      });
    }
  };

  const onSubmit = () => {
    open();
    mutationToggle2FA.mutate({
      otp: otpAuth.otp,
      two_fa_status: isEnable ? "disable" : "enable",
    });
  };

  const isMobile = useIsMobile();

  const btnStyle = useMobileVersion({
    desktop: {},
    mobile: {
      width: "unset",
      flexGrow: 1,
    },
  });
  return (
    <Grid
      container
      direction="column"
      style={{ padding: 10, minHeight: isMobile ? "unset" : 708 }}
    >
      <Grid item>
        <Text variant="h2">Two-factor authentication (2FA)</Text>
      </Grid>

      <Divide
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <Grid item>
        <Grid
          container
          width={isMobile ? "unset" : 400}
          justifyContent="space-between"
        >
          <Grid item>
            <Text
              variant="h5"
              fontWeight={500}
              color={theme.palette.blueGray[800]}
            >
              Two Factor Authentication (2FA)
            </Text>
          </Grid>
          <Grid
            item
            sx={
              isMobile
                ? {
                    "& .MuiFormControlLabel-root": {
                      marginLeft: 0,
                    },
                  }
                : {}
            }
          >
            <Switch
              checked={isEnable}
              label={isEnable ? "Enabled" : "Disabled"}
              onChange={() => {
                if (!isEnable && otpAuth.two_fa_secret) {
                  toast.warning(
                    `Please enter the authentication code to complete the 2FA setup.`
                  );
                  return;
                }
                setIsShowConfirm(true);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {isShowConfirm && (
        <Grid
          container
          width={isMobile ? "unset" : 480}
          style={{ marginTop: 40 }}
        >
          <Grid item>
            <Text
              variant="h4"
              color={theme.palette.blueGray[800]}
              fontWeight={500}
            >
              Do you want to {isEnable ? "disable" : "enable"} 2FA?
            </Text>
          </Grid>
          <Grid item>
            <Text
              variant="body1"
              color={theme.palette.blueGray[500]}
              style={{ marginTop: 12 }}
            >
              Please enter the current password.
            </Text>
          </Grid>
          <Grid item container wrap="nowrap">
            <InputPassword
              style={{ marginTop: 20 }}
              label="Current Password"
              type="password"
              value={password}
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid
            item
            container
            style={{ marginTop: 20, gap: 16 }}
            direction={"row"}
          >
            <Button
              variant="outlined"
              style={{
                width: 232,
                backgroundColor: "inherit",
                border: 1.5,
                height: "44px",
                ...btnStyle,
              }}
              sx={{
                "&:hover": {
                  border: 1.5,
                },
              }}
              onClick={() => setIsShowConfirm(false)}
            >
              Cancel
            </Button>
            <Button
              style={{ width: 232, ...btnStyle }}
              onClick={handleEnableConfig}
            >
              Done
            </Button>
          </Grid>
        </Grid>
      )}

      {Boolean(otpAuth.two_fa_secret) ? (
        !isEnable ? (
          <Grid container style={{ marginTop: 40, gap: 40 }}>
            <Grid item>
              <QRCode
                style={{
                  width: 250,
                  height: 250,
                }}
                value={otpAuth.provision_uri}
              />
            </Grid>
            <Grid item width={isMobile ? "unset" : 632}>
              <Grid container style={{ gap: 10 }} direction="column">
                <Grid item>
                  <Row
                    str1="Step 1:"
                    str2={
                      <Text>
                        Open your preferred authenticator app. While you can use
                        any app such as <strong>Google Authenticator</strong> or{" "}
                        <strong>Microsoft Authenticator</strong>, we highly
                        recommend{" "}
                        <a
                          href="https://authy.com/download/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Authy
                        </a>
                      </Text>
                    }
                  />
                </Grid>
                <Grid item>
                  <Row
                    str1="Step 2:"
                    str2={
                      <span>
                        Scan the <strong>QR Code</strong> or copy and paste the
                        secret in their 2FA app{" "}
                        <strong>{otpAuth.two_fa_secret}</strong>
                      </span>
                    }
                  />
                </Grid>
                <Grid item>
                  <Row str1="Step 3:" str2="Enter the generated code below" />
                </Grid>
                <Grid item>
                  <Input
                    label="Authentication Code"
                    placeholder="XXXX XXXX XXXX"
                    style={{ width: isMobile ? "unset" : 448 }}
                    value={otpAuth.otp}
                    onChange={(e) =>
                      setOtpAuth({ ...otpAuth, otp: e.target.value })
                    }
                  />
                </Grid>
                <Grid item style={{ marginTop: 4 }}>
                  <Button
                    style={{ width: isMobile ? "100%" : 448 }}
                    onClick={onSubmit}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            style={{ marginTop: 20, gap: 20 }}
            direction={"column"}
          >
            <Grid item>
              <Input
                label="Authentication Code"
                placeholder="XXXX XXXX XXXX"
                style={{ width: 448 }}
                value={otpAuth.otp}
                onChange={(e) =>
                  setOtpAuth({ ...otpAuth, otp: e.target.value })
                }
              />
            </Grid>
            <Grid item style={{ marginTop: 4 }}>
              <Button style={{ width: 448 }} onClick={onSubmit}>
                Save
              </Button>
            </Grid>
          </Grid>
        )
      ) : null}

      <Backdrop />
    </Grid>
  );
};

export default TwoFASecurity;

const Row = ({ str1, str2 }: { str1: string; str2: React.ReactNode }) => {
  const theme = useTheme() as CustomThemeOptions;
  const isMobile = useIsMobile();
  return (
    <Grid
      container
      style={{
        gap: 10,
        display: "grid",
        gridTemplateColumns: isMobile ? undefined : "51px calc(100% - 51px)",
      }}
    >
      <Text variant="h5" fontWeight={600} color={theme.palette.blueGray[900]}>
        {str1}
      </Text>
      <Text variant="h5" fontWeight={400} color={theme.palette.blueGray[600]}>
        {str2}
      </Text>
    </Grid>
  );
};
