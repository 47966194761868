import { Grid } from "@mui/material";
import Autocomplete from "components/Autocomplete";
import CheckboxGroup from "components/CheckboxGroup";
import { useIsMobile, useMobileVersion } from "hooks/useIsMobile";
import BillingContext from "pages/billing/context/BillingContext";
import { useContext } from "react";

const SubscriptionsHeaderTable = () => {
  const isMobile = useIsMobile();

  const { query, setQuery } = useContext(BillingContext);

  const Search = useMobileVersion({
    desktop: () => (
      <Grid item>
        {/* <Input
          sx={{
            borderRadius: "8px !important",
            height: "36px",
            "& .MuiOutlinedInput-input": {
              padding: "8px 16px !important",
              borderRadius: "8px !important",
            },
          }}
          placeholder="Search"
          style={{
            height: 36,
            width: 263,
          }}
        /> */}
        <CheckboxGroup
          checked={query.private}
          onChange={(e) => {
            setQuery((prev: any) => ({
              ...prev,
              private: e.target.checked,
            }));
          }}
          label="Show Private"
        />
      </Grid>
    ),
    mobile: () => (
      <Grid item style={{ flexGrow: 1 }}>
        <CheckboxGroup
          checked={query.private}
          onChange={(e) => {
            setQuery((prev: any) => ({
              ...prev,
              private: e.target.checked,
            }));
          }}
          label="Show Private"
        />
      </Grid>
    ),
  });

  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems={isMobile ? "flex-start" : "center"}
      direction={isMobile ? "column" : "row"}
      gap={1}
      style={{ marginBottom: 5, marginTop: isMobile ? 5 : 0 }}
    >
      <Search />
      <Grid item>
        <Grid container style={{ gap: 12 }} direction={"row"}>
          <Grid item>
            <Autocomplete
              style={{
                width: 200,
              }}
              value={query.service}
              onChange={(event, value) => {
                setQuery((prev: any) => ({
                  ...prev,
                  service: value,
                }));
              }}
              height={37}
              disableClearable={true as any}
              options={[
                {
                  id: "0",
                  name: "All Services",
                  isEmpty: true,
                },
              ]}
              url="/products/services"
              getOptionLabel={(option) => option.name}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubscriptionsHeaderTable;
