import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

export const createApiKey = async (data: { service_id: string }) => {
  try {
    const response = await axios.post("/devs/create-api-key", {
      ...data,
      label: null,
    });
    toast.success("API Key created successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to update. Please try again"
    );
    throw error;
  }
};
