import { Grid } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import { ReactComponent as BurgerIcon } from "assets/icons/burger.svg";
import { ReactComponent as MessageQuestion } from "assets/icons/message-question.svg";
import LogoSmall from "assets/images/logo-small.png";
import Text from "components/Text";
import { setShowAPIWarning } from "features/userSlice";
import { useHasApiAccess } from "hooks/useHasApiAccess";
import { useIsMobile, useMobileVersion } from "hooks/useIsMobile";
import { useSidebarExpand } from "hooks/useSidebarExpand";
import { CustomThemeOptions } from "libs/theme";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getRouteName } from "utils/mix";
import AvatarPopover from "./AvatarPopover";
import Sidebar, { DrawerHeader, drawerWidth } from "./Sidebar";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open
    ? {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    : {
        marginLeft: "76px",
        width: `calc(100% - 76px)`,
      }),
}));

const Layout = ({ children, ...restProps }: any) => {
  const theme = useTheme() as CustomThemeOptions;

  const navigate = useNavigate();
  const { has_api_access, height } = useHasApiAccess();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { handleExpand, isExpand: open } = useSidebarExpand();

  const headerName = getRouteName(pathname);
  const appBarStyle = useMobileVersion({
    desktop: {
      backgroundColor: "#FFF",
      padding: "20px 24px",
      boxShadow: "none",
      top: height,
    },
    mobile: {
      backgroundColor: "#FFF",
      padding: "12px 16px",
      boxShadow: "none",
      top: height,
    },
  });

  const header = useMobileVersion({
    desktop: (
      <Text
        variant="h4"
        style={{
          color: theme.palette.blueGray[800],
          fontWeight: 400,
          marginLeft: 24,
        }}
      >
        {headerName}
      </Text>
    ),
    mobile: (
      <Grid container alignItems={"center"}>
        <img src={LogoSmall} width={30} alt="burger" />

        <Text
          variant="h4"
          style={{
            color: theme.palette.blueGray[800],
            fontWeight: 400,
            marginLeft: 10,
          }}
        >
          {headerName}
        </Text>
      </Grid>
    ),
  });

  const AppBarComp = useMobileVersion({
    desktop: AppBar,
    mobile: MuiAppBar,
  });

  const appBarProps = useMobileVersion({
    desktop: {
      position: "fixed",
      open,
      style: appBarStyle,
    },
    mobile: {
      open,
      style: appBarStyle,
    },
  });

  const isMobile = useIsMobile();

  return (
    <>
      {!has_api_access && height > 0 && (
        <Grid
          container
          style={{
            backgroundColor: "#eceb98",
            padding: 5,
            gap: 6,
            height,
            position: "fixed",
            zIndex: 9999
          }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text
            variant="body2"
            color="black"
            style={{
              // maxWidth: isMobile ? 400 : 512,
              textWrap: "wrap",
            }}
          >
            Your API access is currently inactive. Please{" "}
            <span
              className="hyperlink"
              onClick={() => {
                dispatch(setShowAPIWarning(false));
                navigate("/billing?tab=top-up");
              }}
            >
              top up
            </span>{" "}
            your account to enable access, or contact our support team for
            assistance.
          </Text>
          <IconButton
            style={{ position: "absolute", right: 5 }}
            onClick={() => {
              dispatch(setShowAPIWarning(false));
            }}
          >
            x
          </IconButton>
        </Grid>
      )}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBarComp {...appBarProps}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleExpand}
                  edge="start"
                >
                  <BurgerIcon />
                </IconButton>
                {header}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" style={{ gap: 20 }}>
                <MessageQuestion
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://proapis.com/faqs", "_blank");
                  }}
                />
                <AvatarPopover />
              </Grid>
            </Grid>
          </Grid>
        </AppBarComp>

        <Sidebar
          open={open}
          onClose={() => (!isMobile ? {} : handleExpand())}
        />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />

          <Grid
            container
            style={{ paddingTop: 24, paddingBottom: 24, marginTop: height }}
          >
            {children}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
