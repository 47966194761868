import { Grid } from "@mui/material";
import Select from "components/Select";
import { useIsMobile, useMobileVersion } from "hooks/useIsMobile";
import BillingContext from "pages/billing/context/BillingContext";
import { useContext } from "react";
import { Status } from "./MySubscriptionTable";

const MySubscriptionHeaderTable = () => {
  const isMobile = useIsMobile();

  const { query, setQuery } = useContext(BillingContext);

  const options = [
    {
      label: "All Status",
      value: "all",
    },
    {
      label: "Active",
      value: Status.ACTIVE,
    },
    {
      label: "Cancelled",
      value: Status.CANCELLED,
    },
    {
      label: "Processing",
      value: Status.PROCESSING,
    },
  ];

  const Selections = useMobileVersion({
    desktop: () => (
      <Select
        formControlStyle={{ width: 123 }}
        value={query.status}
        options={options}
        onChange={(e) => {
          setQuery((prev: any) => ({
            ...prev,
            status: e.target.value,
          }));
        }}
      />
    ),
    mobile: () => (
      <Select
        formControlStyle={{
          width: 123,
        }}
        value={query.status}
        options={options}
        onChange={(e) => {
          setQuery((prev: any) => ({
            ...prev,
            status: e.target.value,
          }));
        }}
      />
    ),
  });
  return (
    <Grid
      container
      justifyContent={isMobile ? "flex-start" : "flex-end"}
      alignItems={isMobile ? "flex-start" : "center"}
      direction={isMobile ? "column" : "row"}
      gap={1}
      style={{ marginBottom: 5, marginTop: isMobile ? 5 : 0 }}
    >
      <Grid item>
        <Selections />
      </Grid>
    </Grid>
  );
};

export default MySubscriptionHeaderTable;
