import { useTheme } from "@emotion/react";
import { FormControlLabel, FormGroup } from "@mui/material";
import { CustomThemeOptions } from "libs/theme";
import * as React from "react";
import { Controller } from "react-hook-form";
import { ControllerProps } from "react-spring";
import CheckboxComp from "../Checkbox";

type Props = Omit<ControllerProps, "render" | "control" | "name"> & {
  control: any;
  name: string;
  labelStyle?: React.CSSProperties;
  title?: string;
};
const Checkbox: React.FC<Props> = ({
  control,
  name,
  labelStyle,
  title,
  ...restProps
}) => {
  const theme = useTheme() as CustomThemeOptions;

  return (
    <FormGroup style={{ gap: 16 }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <FormControlLabel
              style={{
                height: 20,
                margin: 0,
              }}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "19.6px",
                  color: theme.palette.blueGray[600],
                  ...labelStyle,
                },
              }}
              control={
                <CheckboxComp
                  style={{
                    padding: 0,
                    width: 18,
                    height: 18,
                    marginRight: 8,
                  }}
                  {...field}
                  checked={field.value}
                />
              }
              label={title}
            />
          );
        }}
        {...restProps}
      />
    </FormGroup>
  );
};

export default Checkbox;
