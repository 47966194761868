import { Grid, GridProps } from "@mui/material";
import Box from "components/Box";
import { useTheme } from "hooks/useTheme";
import ButtonWrapper from "pages/develop/components/ButtonWrapper";
import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BillingProfileTable from "./components/BillingProfile/BillingProfile";
import InvoicesTable from "./components/Invoices/InvoicesTable";
import PaymentMethodsTable from "./components/PaymentMethods/PaymentMethodsTable";
import ServiceQuotasTable from "./components/ServiceQuotas/ServiceQuotasTable";
import SubscriptionMain from "./components/Subscriptions/SubscriptionMain";
import TopUpMain from "./components/TopUp/TopUpMain";
import BillingContext, { defaultQuery } from "./context/BillingContext";

export const BoxWrapper = ({ children, style, ...props }: GridProps) => {
  return (
    <Box style={{ padding: 10, ...style }} {...props}>
      {children}
    </Box>
  );
};

enum Tab {
  SUBSCRIPTIONS = "subscriptions",
  TOP_UP = "top-up",
  PAYMENT_METHODS = "payment-methods",
  INVOICES = "invoices",
  BILLING_PROFILE = "billing-profile",
  SERVICE_QUOTAS = "service-quotas",
}

export default function Billing() {
  const theme = useTheme();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [query, setQuery] = useState<any>({
    ...defaultQuery,
  });

  const queryParams = new URLSearchParams(search);
  const tabParam = (queryParams.get("tab") ?? Tab.SUBSCRIPTIONS) as Tab;
  const tableMySubscriptionRef = useRef<any>(null);

  return (
    <BillingContext.Provider
      value={{
        query,
        setQuery,
        tableMySubscriptionRef,
      }}
    >
      <BoxWrapper>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container style={{ gap: 8 }}>
              {[
                {
                  label: "Subscriptions",
                  value: "subscriptions",
                },
                {
                  label: "Top-up",
                  value: "top-up",
                },
                {
                  label: "Payment Methods",
                  value: "payment-methods",
                },
                {
                  label: "Invoices",
                  value: "invoices",
                },
                {
                  label: "Billing Profile",
                  value: "billing-profile",
                },
                {
                  label: "Service Quotas",
                  value: "service-quotas",
                },
              ].map((item, index) => {
                return (
                  <ButtonWrapper
                    style={{
                      backgroundColor:
                        tabParam === item.value
                          ? theme.palette.primary.light
                          : "transparent",
                      color:
                        tabParam === item.value
                          ? theme.palette.primary.main
                          : theme.palette.blueGray[500],
                      height: 40,
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.main,
                        boxShadow: "none",
                      },
                    }}
                    onClick={() => {
                      navigate(`/billing?tab=${item.value}`);
                    }}
                    key={index}
                  >
                    {item.label}
                  </ButtonWrapper>
                );
              })}
            </Grid>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </BoxWrapper>

      {tabParam === Tab.SERVICE_QUOTAS ? (
        <ServiceQuotasTable />
      ) : (
        <BoxWrapper style={{ marginTop: 8, padding: 20 }}>
          {tabParam === Tab.SUBSCRIPTIONS && <SubscriptionMain />}
          {tabParam === Tab.TOP_UP && <TopUpMain />}
          {tabParam === Tab.PAYMENT_METHODS && <PaymentMethodsTable />}
          {tabParam === Tab.INVOICES && <InvoicesTable />}
          {tabParam === Tab.BILLING_PROFILE && <BillingProfileTable />}
        </BoxWrapper>
      )}
    </BillingContext.Provider>
  );
}
