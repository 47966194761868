import { Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import AutoTopUp from "./components/AutoTopUp";
import CustomTopUp from "./components/CustomTopUp";

enum TabValue {
  AUTO_TOP_UP = "auto-top-up",
  CUSTOM_TOP_UP = "custom-top-up",
}
const CustomTopUpMain = () => {
  const [value, setValue] = useState<TabValue>(TabValue.CUSTOM_TOP_UP);

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Tabs value={value} onChange={handleChange}>
        <Tab
          value={TabValue.CUSTOM_TOP_UP}
          style={{ textTransform: "none" }}
          label="Custom Top-up"
        />
        <Tab
          value={TabValue.AUTO_TOP_UP}
          style={{ textTransform: "none" }}
          label="Auto Top-up"
        />
      </Tabs>
      {value === TabValue.AUTO_TOP_UP && <AutoTopUp />}
      {value === TabValue.CUSTOM_TOP_UP && <CustomTopUp />}
    </Grid>
  );
};

export default CustomTopUpMain;
