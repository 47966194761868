import { Alert, Grid, InputAdornment } from "@mui/material";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Dialog from "components/Dialog";
import Text from "components/Text";
import Input from "components/form-control/Input";
import { useForm } from "react-hook-form";

import { useTheme } from "@mui/system";
import { useIsMobile, useMobileVersion } from "hooks/useIsMobile";
import { useCustomSearchParams } from "hooks/useParams";
import { CustomThemeOptions } from "libs/theme";
import { updateQuota } from "pages/billing/actions/updateQuota";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { format2RawNumeric } from "utils/mix";
import { ButtonWrapper, Props } from "../PaymentMethods/FormDialog";

export default function FormDialog({ refresh }: Props) {
  const theme = useTheme() as CustomThemeOptions;
  const { Backdrop, close, open } = useBackdrop();
  const [params] = useCustomSearchParams();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      new_per_request_price: 0,
      new_concurrency: 0,
    },
  });
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const openDialog = !!params.id;

  const mutation = useMutation(updateQuota, {
    onSuccess: () => {
      refresh();
      close();
      handleClose();
    },
    onError: () => {
      close();
    },
  });

  const onSubmit = (data: any) => {
    const id = Number(params.id);
    if (!!id) {
      open();
      mutation.mutate({
        new_per_request_price: data.new_per_request_price
          ? format2RawNumeric(data.new_per_request_price)
          : undefined,
        new_concurrency: data.new_concurrency
          ? format2RawNumeric(data.new_concurrency)
          : undefined,
        service_id: id,
      });
    }
  };
  const handleClose = () => {
    reset({ new_concurrency: 0, new_per_request_price: 0 });
    navigate("/billing?tab=service-quotas");
  };

  const btnStyle = useMobileVersion({
    desktop: {},
    mobile: {
      width: "unset",
      flexGrow: 1,
    },
  });
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      containerStyle={{ width: isMobile ? "unset" : 560 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="column"
          style={{
            gap: 16,
          }}
        >
          <Text
            variant="h3"
            color={theme.palette.blueGray[800]}
            fontWeight={500}
          >
            Quota Increase
          </Text>

          <Grid container style={{ gap: 16 }} flexDirection={"column"}>
            <Grid item>
              <Input
                control={control}
                name="new_per_request_price"
                label="New Price Per Request"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                sx={{
                  "& .MuiInputBase-input": {
                    paddingLeft: "0px !important",
                  },
                }}
                numeric
              />
            </Grid>
            <Grid item>
              <Input
                control={control}
                name="new_concurrency"
                label="New Concurrency"
                numeric
              />
            </Grid>
          </Grid>
          <Grid container>
            <Alert severity="info">
              You can request an increase for either the price per request, the
              concurrency limit, or both. If you only wish to change one limit,
              you can leave the other field blank. For easier and faster
              approvals, please ensure that your requested limits are
              reasonable.
            </Alert>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 14, gap: 10 }}
          >
            <ButtonWrapper
              variant="outlined"
              style={{
                backgroundColor: "inherit",
                border: 1.5,
                height: "44px",
                ...btnStyle,
              }}
              sx={{
                "&:hover": {
                  border: 1.5,
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </ButtonWrapper>
            <ButtonWrapper type="submit" style={btnStyle}>
              Save
            </ButtonWrapper>
          </Grid>
        </Grid>
        <Backdrop />
      </form>
    </Dialog>
  );
}
