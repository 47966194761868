import { useTheme } from "@mui/material";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import { useMobileVersion } from "hooks/useIsMobile";
import { FC } from "react";

type Props = ButtonProps & {
  height?: "medium" | "large";
};
const Button: FC<Props> = ({ style, height, sx, ...props }) => {
  const theme = useTheme();
  const styleCustom = useMobileVersion({
    desktop: {
      padding: "10px 16px",
    },
    mobile: {
      padding: "10px 6px",
      "& .MuiButton-startIcon": {
        marginRight: 0,
      },
    },
  });
  return (
    <MuiButton
      variant="contained"
      sx={{
        backgroundColor: theme.palette.primary["main"],
        fontWeight: 700,
        textTransform: "none",
        borderRadius: 8,
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        height: height === "medium" ? 36 : style?.height,
        ...styleCustom,
        ...style,
        ...sx,
      }}
      {...props}
    />
  );
};

export default Button;
