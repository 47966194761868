import { Grid, Popover, PopoverProps } from "@mui/material";
import React, { useState } from "react";
import Text from "./Text";

interface CustomPopoverProps extends Omit<PopoverProps, "open" | "anchorEl"> {
  PopoverHandler: React.ReactNode;
  PopoverContent: React.ReactNode;
  onClose?: () => void;
  label?: string;
}
const CustomPopover = (props: CustomPopoverProps) => {
  const { onClose, PopoverContent, PopoverHandler, label, ...rest } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose && onClose();
  };
  return (
    <Grid container direction={"column"}>
      {label && (
        <Text variant="h6" style={{ marginBottom: 8 }}>
          {label}
        </Text>
      )}
      <div onClick={handleClick}>{PopoverHandler}</div>
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose} {...rest}>
        {PopoverContent}
      </Popover>
    </Grid>
  );
};
export default CustomPopover;
