import { Grid } from "@mui/material";
import { getCountries, getDialingCodes } from "actions/misc";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import Divide from "components/Divide";
import Switch from "components/Switch";
import Text from "components/Text";
import Autocomplete from "components/form-control/Autocomplete";
import Input from "components/form-control/Input";
import { setUser } from "features/userSlice";
import { useIsMobile } from "hooks/useIsMobile";
import { emailRule } from "pages/auth/utils/rules";
import { getTaxTypes } from "pages/billing/actions/getTaxTypes";
import { updateBillingProfile } from "pages/billing/actions/updateBillingProfile";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { countryToFlag } from "utils/mix";

const BillingProfile = () => {
  const user = useSelector((state: any) => state.user.user);
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, watch, reset, getValues } = useForm({
    defaultValues: {
      billing_name: "",
      billing_email: "",
      is_taxable: false,
      tax_type: "",
      tax_id: "",
      country: {
        label: "",
        value: "",
      },
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      postal_code: "",
    },
  });

  useEffect(() => {
    if (user?.billing_profile) {
      const country = countriesOptions.find(
        (el: any) => el.value === user?.billing_profile?.country
      );
      reset({
        ...user.billing_profile,
        country,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.billing_profile]);

  const isTaxable = watch("is_taxable");
  const { Backdrop, close, open } = useBackdrop();

  const { data: countries, refetch: refreshCountries } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountries,
    staleTime: Infinity,
    enabled: false,
  });

  const { data: dialingCodes, refetch: refreshDialingCodes } = useQuery({
    queryKey: ["dialing_codes"],
    queryFn: getDialingCodes,
    staleTime: Infinity,
    enabled: false,
  });

  // useQuery to getTaxTypes
  const { data: taxTypes, refetch: refreshTaxTypes } = useQuery({
    queryKey: ["tax_types"],
    queryFn: getTaxTypes,
    staleTime: Infinity,
    enabled: false,
  });

  useEffect(() => {
    refreshCountries();
    refreshDialingCodes();
    refreshTaxTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countriesOptions = useMemo(
    () =>
      countries?.map((country: any) => {
        const prefix = dialingCodes?.find(
          (el: any) => el.country_code === country.code
        )?.dialing_code;
        return {
          label: `${countryToFlag(country.code)} ${
            prefix ? `(${prefix}) ` : ""
          }${country.name}`,
          value: country.name,
        };
      }) ?? [],
    [countries, dialingCodes]
  );

  useEffect(() => {
    if (countriesOptions?.length) {
      const country = countriesOptions.find(
        (el: any) => el.value === user?.billing_profile?.country
      );
      setValue("country", country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesOptions, user?.billing_profile?.country]);

  const mutation = useMutation(updateBillingProfile, {
    onSuccess: (data) => {
      const country = getValues("country")?.value;
      dispatch(
        setUser({
          ...user,
          billing_profile: {
            ...getValues(),
            country,
            tax_type: country
              ? taxTypes?.find((el) => el.country === country)?.id
              : "",
          },
        })
      );
      close();
    },
    onError: () => {
      close();
    },
  });

  const onSubmit = (data: any) => {
    const country = data.country?.value || "";
    open();
    mutation.mutate({
      ...data,
      country,
      tax_type: country
        ? taxTypes?.find((el) => el.country === country)?.id
        : "",
    });
  };

  const isMobile = useIsMobile();
  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container justifyContent={"space-between"} alignItems="center">
          <Text variant="h2">Billing Information</Text>
        </Grid>
      </Grid>

      <Divide
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
      />

      <Grid item style={{ width: isMobile ? "unset" : 720 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" style={{ gap: 20 }}>
            <Grid item>
              <Grid container style={{ gap: 20 }}>
                <Grid item style={{ flexGrow: 1 }}>
                  <Input
                    control={control}
                    name="billing_name"
                    label="Billing Name"
                    rules={{
                      required: "Billing Name is required",
                    }}
                  />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <Input
                    control={control}
                    name="billing_email"
                    label="Billing Email"
                    rules={emailRule}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Input control={control} name="address_line1" label="Address 1" />
            </Grid>
            <Grid item>
              <Input control={control} name="address_line2" label="Address 2" />
            </Grid>

            <Grid item>
              <Grid container style={{ gap: 20 }}>
                <Grid item style={{ flexGrow: 1 }}>
                  <Input control={control} name="city" label="City" />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <Input control={control} name="state" label="State" />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container style={{ gap: 20 }}>
                <Grid
                  item
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Input
                    control={control}
                    name="postal_code"
                    label="Zip Code"
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Autocomplete
                    control={control}
                    name="country"
                    label="Country"
                    options={countriesOptions}
                    onChange={(e, value) => setValue("country", value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                wrap="nowrap"
                style={{ gap: 20 }}
                justifyContent="center"
                alignItems={"center"}
              >
                <Grid item style={{ flexGrow: 1 }}>
                  <Input control={control} name="tax_id" label="VAT No." />
                </Grid>

                <Grid
                  item
                  style={{ flexGrow: 1, minWidth: isMobile ? "unset" : 350 }}
                >
                  <Text variant="h6" style={{ marginBottom: 8 }}>
                    Is Taxable
                  </Text>
                  <Switch
                    checked={isTaxable}
                    label=""
                    isForm
                    onChange={() => {
                      setValue("is_taxable", !isTaxable);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button fullWidth type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
          <Backdrop />
        </form>
      </Grid>
    </Grid>
  );
};

export default BillingProfile;
