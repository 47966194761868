const SettingSvg = ({ fill = "#607D8B" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill={fill}
    >
      <path
        opacity="0.4"
        d="M1.66669 11.2339V9.76718C1.66669 8.90052 2.37502 8.18385 3.25002 8.18385C4.75835 8.18385 5.37502 7.11718 4.61669 5.80885C4.18335 5.05885 4.44169 4.08385 5.20002 3.65052L6.64169 2.82552C7.30002 2.43385 8.15002 2.66718 8.54169 3.32552L8.63335 3.48385C9.38335 4.79218 10.6167 4.79218 11.375 3.48385L11.4667 3.32552C11.8584 2.66718 12.7084 2.43385 13.3667 2.82552L14.8084 3.65052C15.5667 4.08385 15.825 5.05885 15.3917 5.80885C14.6334 7.11718 15.25 8.18385 16.7584 8.18385C17.625 8.18385 18.3417 8.89218 18.3417 9.76718V11.2339C18.3417 12.1005 17.6334 12.8172 16.7584 12.8172C15.25 12.8172 14.6334 13.8838 15.3917 15.1922C15.825 15.9505 15.5667 16.9172 14.8084 17.3505L13.3667 18.1755C12.7084 18.5672 11.8584 18.3339 11.4667 17.6755L11.375 17.5172C10.625 16.2089 9.39169 16.2089 8.63335 17.5172L8.54169 17.6755C8.15002 18.3339 7.30002 18.5672 6.64169 18.1755L5.20002 17.3505C4.44169 16.9172 4.18335 15.9422 4.61669 15.1922C5.37502 13.8838 4.75835 12.8172 3.25002 12.8172C2.37502 12.8172 1.66669 12.1005 1.66669 11.2339Z"
        fill={fill}
      />
      <path
        d="M10 13.2077C11.4958 13.2077 12.7084 11.9951 12.7084 10.4993C12.7084 9.00358 11.4958 7.79102 10 7.79102C8.50425 7.79102 7.29169 9.00358 7.29169 10.4993C7.29169 11.9951 8.50425 13.2077 10 13.2077Z"
        fill={fill}
      />
    </svg>
  );
};

export default SettingSvg;
