import AutocompleteComp, { AutocompleteProps } from "components/Autocomplete";
import { FC } from "react";
import { Controller, ControllerProps } from "react-hook-form";

type Props = AutocompleteProps & {
  control: any;
  rules?: ControllerProps["rules"];
  name: string;
};
const Autocomplete: FC<Props> = ({ control, rules, name, ...restProps }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <AutocompleteComp {...field} {...restProps} />}
      rules={rules}
    />
  );
};

export default Autocomplete;
