import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { Paths } from "./path";
import Notfound from "pages/NotFound";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {Paths.map((path) =>
          path.isPrivate ? (
            <Route
              key={path.path}
              path={path.path}
              element={
                path.component ? (
                  <PrivateRoute>{<path.component />}</PrivateRoute>
                ) : null
              }
            />
          ) : (
            <Route
              key={path.path}
              path={path.path}
              element={
                path.component ? (
                  <PublicRoute>{<path.component />}</PublicRoute>
                ) : null
              }
            />
          )
        )}
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
