import { Grid, useTheme } from "@mui/material";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import Text from "components/Text";
import Input from "components/form-control/Input";
import { AuthState, setAuthState, setEmail } from "features/userSlice";
import { CustomThemeOptions } from "libs/theme";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { forgotPassword } from "./actions/forgotPassword";
import { emailRule } from "./utils/rules";

const ForgotPassword: React.FC = () => {
  const theme = useTheme() as CustomThemeOptions;
  const dispatch = useDispatch();

  const { Backdrop, close, open } = useBackdrop();

  const { handleSubmit, control, getValues } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const mutation = useMutation(forgotPassword, {
    onSuccess: (data) => {
      dispatch(setEmail(getValues("email")));
      dispatch(setAuthState(AuthState.NEW_PASSWORD));
      close();
    },
    onError: () => {
      close();
    },
  });

  const onSubmit = (data: any) => {
    open();
    mutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant="h1">Forgot Password</Text>

      <Text
        variant="body2"
        style={{ marginTop: 10, color: theme.palette.blueGray["500"] }}
      >
        Let's fix that. Provide us with the email address you use to log in to
        Sprout, and we'll send you a link to reset your password.
      </Text>
      <Input
        control={control}
        name="email"
        label="Email Address"
        style={{ marginTop: 20 }}
        rules={emailRule}
      />
      <Button
        style={{ marginTop: "20px" }}
        fullWidth
        color="primary"
        type="submit"
      >
        Reset
      </Button>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ marginTop: 20, gap: 10 }}
      >
        <Text variant="body2" style={{ color: theme.palette.blueGray[500] }}>
          Back to
        </Text>
        <Text
          variant="menu02"
          style={{
            color: theme.palette.primary.main,
            fontWeight: 600,
            textDecoration: "none",
          }}
          onClick={() => {
            dispatch(setAuthState(AuthState.LOGIN));
          }}
        >
          Sign In
        </Text>
      </Grid>
      <Backdrop />
    </form>
  );
};

export default ForgotPassword;
