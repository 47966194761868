import { ButtonProps } from "@mui/material";
import Button from "components/Button";
import { useMobileVersion } from "hooks/useIsMobile";

const ButtonWrapper = ({ children, style, ...restProps }: ButtonProps) => {
  const padding = useMobileVersion({
    desktop: "10px 16px",
    mobile: "10px 6px",
  });
  return (
    <Button
      style={{
        padding,
        borderRadius: 8,
        boxShadow: "none",
        fontWeight: 600,
        height: 36,
        ...style,
      }}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default ButtonWrapper;
