import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Pagination,
  PaginationItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  debounce,
  useTheme,
} from "@mui/material";
import { ReactComponent as PrevIcon } from "assets/icons/arrow-left.svg";
import { ReactComponent as NextIcon } from "assets/icons/arrow-right.svg";
import { ReactComponent as SortIcon } from "assets/icons/chevron-down.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as CloseIcon } from "assets/icons/x1.svg";
import { CustomThemeOptions } from "libs/theme";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Text from "./Text";
import { useMobileVersion } from "hooks/useIsMobile";
import axios from "libs/axios";
import Button from "./Button";
import Divide from "./Divide";
import Input from "./Input";
import queryString from "querystring";
interface Column {
  id: string;
  label: string;
  style?: React.CSSProperties;
  cellRender?: (
    value: any,
    rawData: any,
    isExpanded?: boolean
  ) => React.ReactNode;
  sortable?: boolean;
  searchable?: boolean;
  sortAlias?: string;
  editable?: boolean;
  deletable?: boolean;
  dataType?: "string" | "number" | "date";
}

interface TableProps {
  columns: Column[];
  data?: any[];
  fetchMethod?: "GET" | "POST";
  fetchUrl?: string;
  allowDownload?: boolean;
  onEdit?: (row: any) => void;
  onDelete?: (row: any) => void;
  allowDelete?: boolean;
  accordionContent?: (row: any) => React.ReactNode;
  customActions?: (row: any) => React.ReactNode;
  header?: () => React.ReactNode;
  query?: Record<string, any>;
  isPagination?: boolean;
  onCellChanged?: (newValue: any, rowData: any) => void;
}

interface PaginationProps {
  total: number;
  per_page: number;
  total_pages: number;
  page: number;
  has_next: boolean;
  has_prev: boolean;
}

interface Params {
  page: number;
  per_page: number;
  order_by?: string;
  [key: string]: any;
}

const DataTable = forwardRef<any, TableProps>(
  (
    {
      columns,
      data: dataInitial = [],
      fetchMethod = "GET",
      fetchUrl,
      allowDownload,
      allowDelete,
      onEdit,
      onDelete,
      accordionContent,
      customActions,
      header,
      query,
      onCellChanged,
      isPagination = true,
    },
    ref
  ) => {
    const theme = useTheme() as CustomThemeOptions;
    const [order, setOrder] = useState<"asc" | "desc">("asc");
    const [orderBy, setOrderBy] = useState<string | undefined>("");
    const [openRow, setOpenRow] = useState<number | null>(null);
    const [data, setData] = useState(dataInitial);
    const [pagination, setPagination] = useState<PaginationProps>({
      has_next: false,
      has_prev: false,
      page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    });

    const [search, setSearch] = useState<{ [key: string]: any }>({});
    const [isLoading, setIsLoading] = useState(false);

    const [editCell, setEditCell] = useState<{
      id: number;
      field: string;
      value: any;
    } | null>(null);
    const [cellHovered, setCellHovered] = useState<any>(null);

    const callApi = useCallback(
      (
        paginationParams = {
          page: pagination.page,
          per_page: pagination.per_page,
          orderBy,
        }
      ) => {
        setIsLoading(true);
        const params: Params = {
          page: paginationParams.page,
          per_page: paginationParams.per_page,
        };
        if (orderBy) {
          params["order_by"] = order === "asc" ? orderBy : `-${orderBy}`;
        }
        if (query) {
          Object.keys(query).forEach((key) => {
            params[key] = query[key];
          });
        }
        if (search) {
          Object.keys(search).forEach((key) => {
            params[key] = search[key];
          });
        }
        axios({
          method: fetchMethod,
          url: fetchUrl,
          params,
          paramsSerializer: (params) => {
            return queryString.stringify(params);
          },
        })
          .then((response) => {
            if (response.data) {
              setData(response.data.data);
              setOrderBy(orderBy);
              setIsLoading(false);
              if (isPagination) {
                setPagination(response.data.pagination);
              }
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setIsLoading(false);
          });
      },
      [
        fetchMethod,
        fetchUrl,
        isPagination,
        order,
        orderBy,
        pagination.page,
        pagination.per_page,
        query,
        search,
      ]
    );

    useImperativeHandle(ref, () => ({
      refresh: callApi,
      reset: () => {
        callApi({
          page: 1,
          per_page: 10,
          orderBy: "",
        });
        setOrderBy("");
      },
    }));

    useEffect(() => {
      if (fetchMethod && fetchUrl) {
        callApi();
      }
    }, [callApi, fetchMethod, fetchUrl]);

    const handleCellClick = (id: number, field: string, value: string) => {
      setEditCell({ id, field, value });
    };

    const handleCellChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditCell({
        ...(editCell as any),
        value: event.target.value,
      });
    };

    const onCloseCell = () => {
      setEditCell(null);
    };

    const onSaveCell = (oldValue: any, rowData: any) => {
      if (oldValue !== editCell?.value) {
        onCellChanged?.(editCell?.value, rowData);
        const newData = data.map((row) => {
          if (String(row.id) === String(editCell?.id)) {
            return {
              ...row,
              [editCell?.field as any]: editCell?.value,
            };
          }
          return row;
        });
        setData([...newData]);
      }

      onCloseCell();
    };

    const handleRowClick = (index: number) => {
      setOpenRow(openRow === index ? null : index);
    };

    const handleSort = (columnId: string) => {
      const isAsc = orderBy === columnId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(columnId);
    };

    const handleChangePage = (event: any, newPage: number) => {
      setPagination((prev) => ({
        ...prev,
        page: newPage,
      }));
    };

    const cellRender = (row: any, column: Column, index: number) => {
      const { deletable = true } = column;

      if (editCell?.id === (row.id || index) && editCell?.field === column.id) {
        return (
          <Grid container>
            <Input
              value={editCell.value}
              onChange={handleCellChange}
              autoFocus
              style={{
                height: "26px",
                maxWidth: 100,
              }}
              inputStyle={{
                height: 26,
                fontSize: 12,
                lineHeight: "18px",
                color: "#212121",
                fontWeight: 400,
                padding: "1px 8px !important",
              }}
              sx={{
                borderRadius: "4px !important",
                "& .MuiOutlinedInput-input": {
                  height: "8px !important",
                },
              }}
            />
            <Button
              style={{
                padding: "3px 8px",
                borderRadius: "4px !important",
                width: 43,
                height: 26,
                marginLeft: "8px",
              }}
              onClick={() => onSaveCell(row[column.id], row)}
            >
              Save
            </Button>

            <IconButton
              style={{ padding: 0, marginLeft: 4 }}
              onClick={onCloseCell}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        );
      }
      if (column.cellRender) {
        return column.cellRender(row[column.id], row, openRow === index);
      }

      if (column.editable) {
        return (
          <Grid container style={{ gap: 16 }} alignItems="center">
            <span>{row[column.id]}</span>
            {cellHovered?.id === row.id && cellHovered.field === column.id ? (
              <>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() =>
                    handleCellClick(row.id || index, column.id, row[column.id])
                  }
                >
                  <EditIcon />
                </IconButton>
                {row[column.id] && deletable && (
                  <IconButton style={{ padding: 0 }} onClick={() => {}}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </>
            ) : null}
          </Grid>
        );
      }
      return row[column.id];
    };

    const PrevBtn = useMobileVersion({
      desktop: () => (
        <ButtonPagination startIcon={<PrevIcon />}>Prev</ButtonPagination>
      ),
      mobile: () => (
        <ButtonPagination startIcon={<PrevIcon />}>{""}</ButtonPagination>
      ),
    });

    const NextBtn = useMobileVersion({
      desktop: () => (
        <ButtonPagination endIcon={<NextIcon />}>Next</ButtonPagination>
      ),
      mobile: () => (
        <ButtonPagination endIcon={<NextIcon />}>{""}</ButtonPagination>
      ),
    });

    const handleSearch = (value: string, id: string) => {
      if (value) {
        search[id] = value;
        setSearch({ ...search });
      } else {
        delete search[id];
        setSearch({ ...search });
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearch = useCallback(debounce(handleSearch, 500), []);

    return (
      <>
        {!!header && header()}
        <Box sx={{ overflow: "auto" }}>
          <Box
            sx={{
              width: "100%",
              display: "table",
              tableLayout: "fixed",
              position: "relative",
            }}
          >
            <Table>
              <TableHead
                sx={{
                  height: 50,
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        border: "none",
                        verticalAlign: "text-top",
                        ...column.style,
                      }}
                    >
                      {column.sortable ? (
                        <TableSortLabel
                          active={orderBy?.includes(
                            column.sortAlias || column.id
                          )}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={() =>
                            handleSort(column.sortAlias || column.id)
                          }
                          IconComponent={SortIcon}
                        >
                          <Text
                            variant="h6"
                            style={{
                              color: theme.palette.blueGray[600],
                              fontWeight: 400,
                              height: 20,
                            }}
                          >
                            {column.label}
                          </Text>
                        </TableSortLabel>
                      ) : (
                        <Text
                          variant="h6"
                          style={{
                            color: theme.palette.blueGray[600],
                            fontWeight: 400,
                            height: 20,
                          }}
                        >
                          {column.label}
                        </Text>
                      )}
                      {column.searchable && (
                        <Input
                          inputStyle={{
                            height: 26,
                            fontSize: 12,
                            lineHeight: "18px",
                            color: "#212121",
                            marginTop: column.sortable ? 4 : 6,
                          }}
                          onChange={(event) => {
                            debounceSearch(event.target.value, column.id);
                          }}
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length ? (
                  data.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            index % 2 === 0
                              ? "rgba(227, 242, 253, 0.30)"
                              : "inherit",
                        }}
                        onClick={() => handleRowClick(index)}
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            sx={{ border: "none" }}
                            style={column.style}
                            onMouseEnter={() => {
                              setCellHovered({ id: row.id, field: column.id });
                            }}
                            onMouseLeave={() => {
                              setCellHovered(null);
                            }}
                          >
                            {cellRender(row, column, index)}
                          </TableCell>
                        ))}
                        <TableCell align="right" sx={{ border: "none" }}>
                          {!!customActions && customActions(row)}
                          {onEdit && (
                            <IconButton onClick={() => onEdit(row)}>
                              <EditIcon />
                            </IconButton>
                          )}
                          {onDelete && (
                            <IconButton onClick={() => onDelete(row)}>
                              <DeleteIcon />
                            </IconButton>
                          )}
                          {allowDownload && (
                            <IconButton>
                              <DownloadIcon />
                            </IconButton>
                          )}
                          {allowDelete && (
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          )}
                          {}
                        </TableCell>
                      </TableRow>
                      {accordionContent && (
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            sx={{ border: "none" }}
                            colSpan={columns.length + 1}
                          >
                            <Collapse
                              in={openRow === index}
                              timeout="auto"
                              unmountOnExit
                            >
                              {accordionContent(row)}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <TableRow
                    sx={{
                      backgroundColor: "rgba(227, 242, 253, 0.30)",
                    }}
                  >
                    <TableCell
                      sx={{ border: "none" }}
                      colSpan={columns.length + 1}
                      align="center"
                    >
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {isLoading && (
              <>
                <Grid
                  style={{
                    position: "absolute",
                    zIndex: 99,
                    top: 0,
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    width: "10000px",
                    background: "#b2cfe578",
                    justifyContent: "center",
                    borderRadius: "8px",
                  }}
                ></Grid>
                <Grid
                  style={{
                    position: "absolute",
                    zIndex: 100,
                    top: "50%",
                    left: "50%",
                    backgroundColor: "#FFF",
                    padding: "3px 8px",
                    borderRadius: 4,
                    border: `1px solid ${theme.palette.blueGray[100]}`,
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  }}
                >
                  <Text variant="menu02" color={theme.palette.blueGray[600]}>
                    Loading data
                  </Text>
                </Grid>
              </>
            )}
          </Box>
        </Box>
        {isPagination && (
          <Grid container direction={"column"}>
            <Divide />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
              sx={{ marginTop: "20px", padding: "2px" }}
            >
              <PaginationItem
                sx={{
                  padding: 0,
                  margin: 0,
                  "&:hover": {
                    backgroundColor: "unset !important",
                  },
                }}
                page={pagination.page - 1}
                disabled={!pagination.has_prev}
                onClick={(event) =>
                  handleChangePage(event, pagination.page - 1)
                }
                slots={{
                  previous: () => <PrevBtn />,
                }}
                type="previous"
              />
              <Pagination
                style={{ width: "auto" }}
                sx={{
                  "& .MuiPaginationItem-page": {
                    width: "36px",
                    height: "36px",
                  },
                }}
                count={pagination.total_pages}
                siblingCount={0}
                page={pagination.page}
                onChange={handleChangePage}
                color="primary"
                hideNextButton
                hidePrevButton
              />
              <PaginationItem
                sx={{
                  padding: 0,
                  margin: 0,
                  "&:hover": {
                    backgroundColor: "unset !important",
                  },
                }}
                page={pagination.page + 1}
                disabled={!pagination.has_next}
                onClick={(event) =>
                  handleChangePage(event, pagination.page + 1)
                }
                slots={{
                  next: () => <NextBtn />,
                }}
                type="next"
              />
            </Box>
          </Grid>
        )}
      </>
    );
  }
);

export default DataTable;

const ButtonPagination = ({
  children,
  startIcon,
  endIcon,
}: {
  children: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}) => {
  const theme = useTheme() as CustomThemeOptions;

  const sx = useMobileVersion({
    desktop: {},
    mobile: {
      padding: 0,
      minWidth: "36px",
      height: "38px",
      "& .MuiButton-icon": {
        margin: 0,
      },
    },
  });

  return (
    <Button
      style={{
        backgroundColor: "transparent",
        borderRadius: 8,
        border: `1.5px solid ${theme.palette.blueGray[100]}`,
        height: 36,
        boxShadow: "none",
      }}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        "&:hover": {
          backgroundColor: theme.palette.blueGray[200],
          boxShadow: "none",
        },
        ...sx,
      }}
    >
      <Text
        variant="menu02"
        color={theme.palette.blueGray[600]}
        fontWeight={600}
      >
        {children}
      </Text>
    </Button>
  );
};
