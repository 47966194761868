import { format } from "date-fns";
import { Paths } from "routes/path";

export const isPrivateRoute = (path: string) => {
  return Paths.some((el) => el.path === path && el.isPrivate);
};

export const getRouteName = (path: string) => {
  const route = Paths.find((el) => el.path === path);
  return route?.name || "";
};

export const hexToRgba = (hex: string, alpha: number = 1): string => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const countryToFlag = (isoCode: string) => {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
};

export const upperFirstChar = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatCurrency = (value: number, currency: string = "usd") => {
  // Convert value to string to count decimal places
  const decimalCount = value.toString().split(".")[1]?.length || 0;
  const minimumFractionDigits = decimalCount;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits,
  }).format(value);
};

export const format2RawNumeric = (value: any) => {
  return value.replace(/,/g, "");
};

export const formatNumeric = (value: number, allowDecimal = false) => {
  const newValue = new Intl.NumberFormat("en-US").format(value);
  return newValue.replace(allowDecimal ? "" : ".00", "");
};

export const formatDateToISOString = (value: Date) => {
  return format(value, "yyyy-MM-dd'T'HH:mm:ss'Z'");
};

export const formatDate = (value: any, formatString?: string) => {
  return format(value, formatString ?? "MM/dd/yyyy HH:mm");
};
