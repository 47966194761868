import { Grid } from "@mui/material";
import { useTheme } from "@mui/system";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import Chip from "components/Chip";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import DataTable from "components/DataTable";
import { useParams } from "hooks/useParams";
import { CustomThemeOptions } from "libs/theme";
import { cancelSubscription } from "pages/billing/actions/cancelSubscription";
import BillingContext from "pages/billing/context/BillingContext";
import { useContext } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { formatCurrency, formatNumeric, upperFirstChar } from "utils/mix";
import SubscriptionsHeaderTable from "./MySubscriptionHeaderTable";
import { createSubscription } from "pages/billing/actions/createSubscription";

export enum Status {
  ACTIVE = "active",
  CANCELLED = "cancelled",
  PROCESSING = "processing",
}
const MySubscriptionTable = () => {
  const theme = useTheme() as CustomThemeOptions;
  const { Backdrop, close, open } = useBackdrop();
  const navigate = useNavigate();
  const mutation = useMutation(cancelSubscription, {
    onSuccess: (data) => {
      tableMySubscriptionRef.current?.refresh();
      close();
    },
    onError: () => {
      close();
    },
  });
  const { query, tableMySubscriptionRef } = useContext(BillingContext);

  const deleteId = useParams("delete_id");
  const planId = useParams("plan_id");

  const mutationSubscribe = useMutation(createSubscription, {
    onSuccess: (data) => {
      tableMySubscriptionRef.current?.refresh();
      close();
    },
    onError: () => {
      close();
    },
  });

  const handleSubscription = (plan_id: number) => {
    open();
    mutationSubscribe.mutate({
      plan_id,
    });
  };

  const handleUnsubscribe = (subscription_id: number) => {
    open();
    mutation.mutate(subscription_id);
  };

  const handleClose = () => {
    navigate("/billing?tab=subscriptions");
  };

  const getColor = (value: Status) => {
    switch (value) {
      case Status.ACTIVE:
        return theme.palette.primary["main"];

      case Status.CANCELLED:
        return theme.palette.deepOrange["500"];
      default:
        return theme.palette.blueGray["500"];
    }
  };

  return (
    <>
      <DataTable
        ref={tableMySubscriptionRef}
        columns={[
          {
            id: "plan",
            label: "Service name",
            style: {
              minWidth: 120,
            },
            cellRender(value) {
              return (
                <Grid
                  container
                  style={{
                    gap: 10,
                    fontWeight: 600,
                    color: theme.palette.blueGray[800],
                  }}
                  alignItems={"center"}
                >
                  {value.service.name}
                  {value.is_private && <ChipWrapper text="Private" />}
                </Grid>
              );
            },
          },
          {
            id: "plan",
            label: "Plan Name",
            style: {
              fontWeight: 600,
              minWidth: 200,
              color: theme.palette.blueGray[800],
            },
            cellRender(value) {
              return value.name;
            },
          },
          {
            id: "plan",
            label: "Price",
            style: {
              fontWeight: 600,
              color: theme.palette.blueGray[800],
            },
            cellRender(value) {
              return formatCurrency(value.price);
            },
          },
          {
            id: "plan",
            label: "Cost Per Request",
            style: {
              fontWeight: 600,
              color: theme.palette.blueGray[800],
            },
            cellRender(value) {
              return formatCurrency(value.cost_per_request);
            },
          },
          {
            id: "plan",
            label: "Concurrency",
            style: {
              fontWeight: 600,
              color: theme.palette.blueGray[800],
            },
            cellRender(value) {
              return formatNumeric(value.concurrency);
            },
          },
          {
            id: "status",
            label: "Status",
            cellRender(value) {
              return (
                <ChipWrapper
                  text={upperFirstChar(value)}
                  color={getColor(value)}
                />
              );
            },
          },
        ]}
        customActions={(row) =>
          row.status !== Status.CANCELLED ? (
            <Button
              style={{
                height: 36,
                backgroundColor: theme.palette.deepOrange["500"],
              }}
              onClick={() => {
                navigate(`/billing?tab=subscriptions&delete_id=${row.id}`);
              }}
              color="warning"
            >
              Unsubscribe
            </Button>
          ) : null
        }
        header={SubscriptionsHeaderTable}
        fetchUrl="billing/subscriptions"
        query={query}
      />
      <ConfirmDialog
        open={!!deleteId}
        onClose={handleClose}
        onConfirm={() => {
          handleClose();
          handleUnsubscribe(Number(deleteId));
        }}
      />

      <ConfirmDialog
        open={!!planId}
        onClose={handleClose}
        onConfirm={() => {
          handleClose();
          handleSubscription(Number(planId));
        }}
      />
      <Backdrop />
    </>
  );
};

export default MySubscriptionTable;

const ChipWrapper = ({ text, color }: { text: string; color?: string }) => {
  const theme = useTheme();
  return (
    <Chip
      text={text}
      color={color || theme.palette.primary["main"]}
      dotDisplay={false}
      style={{
        padding: "3px 12px",
        fontWeight: 400,
      }}
    />
  );
};
