import { Alert, Grid } from "@mui/material";
import { usageAnalytics } from "actions/usageAnalytics";
import ChartWidgetIcon from "assets/icons/chart-widget.png";
import PaymentIcon from "assets/icons/payment.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import Autocomplete from "components/Autocomplete";
import Box from "components/Box";
import DateRangePicker from "components/DateRangePicker";
import IconButton from "components/IconButton";
import Select from "components/Select";
import { useCustomSearchParams } from "hooks/useParams";
import { useTheme } from "hooks/useTheme";
import { FC, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { formatCurrency, formatNumeric } from "utils/mix";
import Card from "../../components/Card";
import Chart from "./components/Chart";

type CardItemProps = {
  title: string;
  body: string;
  isFetching?: boolean;
  icon?: string;
  iconWidth?: number;
};
const CardItem: FC<CardItemProps> = ({
  title,
  body,
  isFetching,
  icon = ChartWidgetIcon,
  iconWidth = 56,
}) => {
  return (
    <Grid item style={{ flexGrow: 1 }}>
      <Card
        title={title}
        body={body}
        icon={<img src={icon} width={iconWidth} alt="" />}
        style={{
          minWidth: 120,
          alignItems: "flex-start",
          gap: "5px",
        }}
        isLoading={isFetching}
      />
    </Grid>
  );
};

export default function UsageAnalytics() {
  const theme = useTheme();

  const [params, setSearchParams] = useCustomSearchParams({
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    filter_by: "all",
  });

  const [isLoadingOverall, setIsLoadingOverall] = useState(false);

  const { data, refetch, isFetching, isLoading } = useQuery({
    queryKey: "timeSeriesStats",
    queryFn: async () => {
      const filterParams: any = {
        date_range: [params.startDate, params.endDate],
      };

      if (params.filter_by !== "all") {
        filterParams["filter_by"] = params.filter_by;
        if (params.filter_id) {
          filterParams["filter_id"] = params.filter_id;
        }
      }
      return await usageAnalytics(filterParams);
    },
    staleTime: Infinity,
    enabled: false,
    onSuccess(data) {
      setIsLoadingOverall(false);
    },
    onError() {
      setIsLoadingOverall(false);
    },
  });

  useEffect(() => {
    if (params.filter_by === "all" || params.filter_id) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.startDate, params.endDate, params.filter_by, params.filter_id]);

  const today = new Date();

  const dateRangeParams = {
    startDate: new Date(params.startDate),
    endDate: new Date(params.endDate),
    key: "selection",
  };

  const filterValue = useMemo(() => {
    const defaultValue = {
      value: params.filter_id ?? "",
      label: params.filter_name ?? "",
      name: params.filter_name ?? "",
    };
    return defaultValue;
  }, [params.filter_id, params.filter_name]);

  return (
    <Grid container style={{ gap: 24 }}>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={3}
      >
        <Grid item>
          <Grid container gap={3}>
            <Grid item style={{ minWidth: 150 }}>
              <Select
                fullWidth
                label={"Filter By"}
                value={params.filter_by || "all"}
                options={[
                  {
                    label: "All",
                    value: "all",
                  },
                  {
                    label: "Service",
                    value: "service",
                  },
                  {
                    label: "API Key",
                    value: "api_key",
                  },
                ]}
                onChange={(e) => {
                  setSearchParams({
                    ...params,
                    filter_by: String(e.target.value),
                    filter_id: "",
                    filter_name: "",
                  });
                  if (e.target.value === "all") {
                    setIsLoadingOverall(true);
                  }
                }}
              />
            </Grid>
            <Grid item style={{ minWidth: 220 }}>
              {params.filter_by && params.filter_by !== "all" && (
                <Autocomplete
                  label={params.filter_by === "service" ? "Service" : "API Key"}
                  value={filterValue}
                  options={[]}
                  onChange={(e, value) => {
                    setIsLoadingOverall(true);
                    setSearchParams({
                      ...params,
                      filter_id: value.value || value.id,
                      filter_name: value.name || value.label,
                    });
                  }}
                  height={37}
                  disableClearable={true as any}
                  url={
                    params.filter_by === "service"
                      ? "/products/services"
                      : "/devs/api-keys"
                  }
                  getOptionLabel={(option) => {
                    if (params.filter_by === "service") {
                      return option.name;
                    }
                    return option.label;
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => {
              if (params.filter_by === "all" || params.filter_id) {
                refetch();
              }
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Alert severity="info">
        Statistics are retrieved based on your configured timezone. You can
        update your timezone in the{" "}
        <Link
          to="/my-account"
          style={{
            color: theme.palette.primary[800],
            fontWeight: 500,
          }}
        >
          settings.
        </Link>
      </Alert>
      <Grid container justifyContent="space-between" style={{ gap: 24 }}>
        <CardItem
          title="Balance"
          body={formatCurrency(data?.summary?.current_balance || 0)}
          icon={PaymentIcon}
          iconWidth={32}
          isFetching={isLoading || isLoadingOverall}
        />
        <CardItem
          title="All Time Spend"
          body={formatCurrency(data?.summary?.costs?.all_time || 0)}
          icon={ChartWidgetIcon}
          isFetching={isLoading || isLoadingOverall}
        />
        <CardItem
          title="Current Month Spend"
          body={formatCurrency(data?.summary?.costs?.current_month || 0)}
          icon={ChartWidgetIcon}
          isFetching={isLoading || isLoadingOverall}
        />
        <CardItem
          title="All Time Requests"
          body={formatNumeric(data?.summary?.requests?.all_time ?? 0)}
          icon={ChartWidgetIcon}
          isFetching={isLoading || isLoadingOverall}
        />
        <CardItem
          title="Monthly Requests"
          body={formatNumeric(data?.summary?.requests?.current_month ?? 0)}
          icon={ChartWidgetIcon}
          isFetching={isLoading || isLoadingOverall}
        />
      </Grid>
      <Box container gap={3} justifyContent={"flex-start"}>
        <Grid item>
          <Grid item>
            <DateRangePicker
              value={dateRangeParams}
              onChange={(ranges: any) => {
                setSearchParams({
                  ...params,
                  startDate:
                    ranges.selection?.startDate ||
                    new Date(ranges.startDate).toISOString(),
                  endDate:
                    ranges.selection?.endDate ||
                    new Date(ranges.endDate).toISOString(),
                });
              }}
              maxDate={today}
              label="Date Range"
            />
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between" style={{ gap: 24 }}>
          <CardItem
            title="Total Requests"
            body={formatNumeric(
              data?.time_period_stats.aggregate_stats.total_requests ?? 0
            )}
            icon={ChartWidgetIcon}
            isFetching={isFetching}
            iconWidth={96}
          />
          <CardItem
            title="Agg. Cost"
            body={formatCurrency(
              data?.time_period_stats.aggregate_stats.total_cost ?? 0
            )}
            icon={ChartWidgetIcon}
            isFetching={isFetching}
            iconWidth={96}
          />
          <CardItem
            title="Avg. Latency"
            body={`${
              data?.time_period_stats.aggregate_stats.average_latency ?? 0
            } ms`}
            icon={ChartWidgetIcon}
            isFetching={isFetching}
            iconWidth={96}
          />
        </Grid>
        <Chart
          isFetching={isFetching}
          timeSeries={data?.time_period_stats?.timeseries}
          date_range={[params.startDate, params.endDate]}
        />
      </Box>
    </Grid>
  );
}
