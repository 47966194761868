import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

type UserUpdate = {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  notification_ids: number[];
  timezone_str: string;
};
export const updateUser = async ({
  user_id,
  email,
  first_name,
  last_name,
  notification_ids,
  timezone_str,
}: UserUpdate) => {
  try {
    const response = await axios.put(`/account/${user_id}`, {
      user_id,
      email,
      first_name,
      last_name,
      notification_ids,
      timezone_str,
    });

    toast.success("Profile updated successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to update. Please try again"
    );
    throw error;
  }
};
