import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export enum AuthState {
  LOGIN,
  TWO_FACTOR_AUTH,
  FORGOT_PASSWORD,
  NEW_PASSWORD,
  SIGN_UP,
  VERIFY_EMAIL,
}

interface Consumer {
  id: number;
  custom_price?: number;
  concurrency: number;
  service: {
    id: number;
    name: string;
  };
}
interface billing_profile {
  billing_name: string;
  billing_email: string;
  is_taxable: boolean;
  tax_type: string;
  tax_id: string;
  country: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  postal_code: string;
}
interface User {
  balance: number;
  created_at: string;
  email: string;
  email_confirmed: boolean;
  first_name: string;
  has_api_access: boolean;
  id: number;
  is_admin: boolean;
  last_billing_date: string | null;
  last_name: string;
  total_spend: number;
  two_factor_enabled: boolean;
  billing_profile?: billing_profile;
  auto_top_up: boolean;
  top_up_amount: number;
  top_up_threshold: number;
  notifications: number[];
  consumers: Consumer[];
  timezone_str: string;
}

interface UserState {
  user: null | User;
  authState: AuthState;
  prevAuthState: AuthState | null;
  email?: string;
  sidebarOpen: boolean;
  isLoading: boolean;
  showAPIWarning: boolean;
}

const SIDEBAR_EXPAND = "sidebar_expand";

const initialState: UserState = {
  user: null,
  authState: AuthState.LOGIN,
  prevAuthState: null,
  sidebarOpen: localStorage.getItem(SIDEBAR_EXPAND) === "true" || false,
  isLoading: false,
  showAPIWarning: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.email = "";
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUser: (state, action: PayloadAction<User | any>) => {
      state.user = Object.assign(state.user || {}, action.payload);
    },
    logout: (state) => {
      Cookies.remove("token");
      state.user = null;
      state.authState = AuthState.LOGIN;
      state.email = "";
    },
    setAuthState: (state, action: PayloadAction<AuthState>) => {
      if (state.authState) {
        state.prevAuthState = state.authState;
      }
      state.authState = action.payload;
    },
    setSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShowAPIWarning: (state, action: PayloadAction<boolean>) => {
      state.showAPIWarning = action.payload;
    },
  },
});

export const {
  login,
  logout,
  setAuthState,
  setEmail,
  setUser,
  setSidebarOpen,
  setIsLoading,
  setShowAPIWarning,
} = userSlice.actions;

export default userSlice.reducer;
