import { Grid } from "@mui/material";
import { ReactComponent as AddIcon } from "assets/icons/plus.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import { useTheme } from "hooks/useTheme";
import { useNavigate } from "react-router";
import IconButton from "../../../components/IconButton";
import ButtonWrapper from "./ButtonWrapper";
import { useContext } from "react";
import DevelopContext from "../context/DevelopContext";

const KeysAction = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { keyTableRef } = useContext(DevelopContext);
  return (
    <Grid container style={{ gap: 8 }}>
      {/* <IconButton>
        <SearchIcon />
      </IconButton> */}
      <IconButton
        onClick={() => {
          keyTableRef.current?.refresh();
        }}
      >
        <RefreshIcon />
      </IconButton>
      <ButtonWrapper
        startIcon={<AddIcon />}
        sx={{
          "&:hover": {
            backgroundColor: theme.palette.primary[700],
            color: "#FFF",
            boxShadow: "none",
          },
        }}
        onClick={() => {
          navigate("/develop?type=add");
        }}
      >
        Add Key
      </ButtonWrapper>
    </Grid>
  );
};

export default KeysAction;
