import axios from "libs/axios";

export const getCountries = async () => {
  try {
    const response = await axios.get("/misc/countries");

    return response.data;
  } catch (error: any) {
    return [];
  }
};

export const getTaxIDs = async () => {
  try {
    const response = await axios.get("/misc/tax-ids");

    return response.data;
  } catch (error: any) {
    return [];
  }
};

export const getDialingCodes = async (): Promise<
  {
    country_code: string;
    dialing_code: string;
  }[]
> => {
  try {
    const response = await axios.get("/misc/dialing-codes");

    return response.data;
  } catch (error: any) {
    return [];
  }
};

export const getServices = async (): Promise<
  {
    name: string;
    id: number;
  }[]
> => {
  try {
    const response = await axios.get("/products/services", {
      params: {
        per_page: 50,
      },
    });

    return response.data?.data;
  } catch (error: any) {
    return [];
  }
};

export const getApiKeys = async (): Promise<
  {
    alltime_calls: number;
    alltime_spend: number;
    id: number;
    service: {
      id: number;
      name: string;
    };
    label: string;
    created_at: string;
    last_used: string;
  }[]
> => {
  try {
    const response = await axios.get("/devs/api-keys", {
      params: {
        per_page: 50,
      },
    });
    return response.data?.data;
  } catch (error) {
    return [];
  }
};

type SupportNotification = {
  id: number;
  slug: string;
  label: string;
};
export const getSupportNotifications = async (): Promise<
  SupportNotification[]
> => {
  try {
    const response = await axios.get("/misc/supported-notifications");

    return response.data;
  } catch (error: any) {
    return [];
  }
};

export const getTimezones = async (): Promise<string[]> => {
  try {
    const response = await axios.get("/misc/timezones");
    return response.data.data;
  } catch (error) {
    return [];
  }
};
