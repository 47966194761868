import React from "react";

type Props = {
  keyTableRef: any;
  logTableRef: any;
  // query:any;
  // setQuery:(query:any) => void
};

const DevelopContext = React.createContext<Props>({
  keyTableRef: null,
  logTableRef: null,
  // query: {},
  // setQuery : ()=>{}
});

export default DevelopContext;
