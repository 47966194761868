import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

export const forgotPassword = async ({ email }: { email: string }) => {
  try {
    const response = await axios.post("/auth/reset-password-init", {
      email,
    });

    toast.success(`Successfully sent reset password email to ${email}`);
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail ||
        `Failed to send reset password email to ${email}`
    );
    throw error;
  }
};
