import { Grid, GridProps } from "@mui/material";

const Box = ({ children, style, ...props }: GridProps) => {
  return (
    <Grid
      container
      style={{
        padding: 20,
        borderRadius: 16,
        boxShadow:
          "0px 0px 2px 0px rgba(153, 169, 182, 0.20), 0px 12px 24px -4px rgba(153, 169, 182, 0.12)",
        ...style,
      }}
      justifyContent={"center"}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default Box;
