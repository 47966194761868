import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

export const cancelSubscription = async (subscription_id: number) => {
  try {
    const response = await axios.delete(
      `/billing/cancel-subscription/${subscription_id}`
    );
    toast.success("Subscription cancelled successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to cancel. Please try again"
    );
    throw error;
  }
};
