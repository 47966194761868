import { Grid, useTheme } from "@mui/material";
import Button from "components/Button";
import Text from "components/Text";
import Input from "components/form-control/Input";
import { AuthState, setAuthState } from "features/userSlice";
import { CustomThemeOptions } from "libs/theme";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { verifyEmail } from "./actions/verifyEmail";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import { useSelector } from "react-redux";
import { RootState } from "libs/store";
import { emailRule, otpRule } from "./utils/rules";
import { sendVerificationEmail } from "./actions/sendVerificationEmail";

const VerifyEmail: React.FC = () => {
  const { user, email, prevAuthState } = useSelector(
    (state: RootState) => state.user
  );
  const theme = useTheme() as CustomThemeOptions;
  const dispatch = useDispatch();
  const { Backdrop, close, open } = useBackdrop();

  const { handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: {
      email: email || user?.email || "",
      otp: "",
    },
  });

  useEffect(() => {
    setValue("email", email || user?.email || "");
  }, [email, setValue, user]);

  const mutation = useMutation(verifyEmail, {
    onSuccess: () => {
      dispatch(
        setAuthState(
          prevAuthState === AuthState.FORGOT_PASSWORD
            ? AuthState.NEW_PASSWORD
            : AuthState.LOGIN
        )
      );
      close();
    },
    onError: () => {
      close();
    },
  });

  const mutationResendOTP = useMutation(sendVerificationEmail, {
    onSuccess: () => {
      close();
    },
    onError: () => {
      close();
    },
  });

  const resendOTP = () => {
    open();
    const email = getValues("email");
    mutationResendOTP.mutate({ email });
  };

  const onSubmit = (data: any) => {
    open();
    mutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant="h1">Verify Email</Text>

      <Text
        variant="body2"
        style={{ marginTop: 10, color: theme.palette.blueGray["500"] }}
      >
        Let's fix that. Provide us with the email address you use to log in to
        Sprout, and we'll send you a link to reset your password.
      </Text>
      <Input
        control={control}
        name="email"
        label="Email Address"
        style={{ marginTop: 20 }}
        rules={emailRule}
      />

      <Input
        control={control}
        name="otp"
        label="OTP"
        style={{ marginTop: 20 }}
        placeholder="Enter the One time password"
        rules={otpRule}
      />
      <Grid container style={{ marginTop: 5, gap: 5 }}>
        <Grid item>
          <Text variant="body2" color={theme.palette.blueGray[500]}>
            Didn't receive OTP code?
          </Text>
        </Grid>
        <Grid item>
          <Text
            variant="body2"
            color={theme.palette.primary["main"]}
            fontWeight={600}
            style={{
              cursor: "pointer",
            }}
            onClick={resendOTP}
          >
            Resend
          </Text>
        </Grid>
      </Grid>
      <Button
        style={{ marginTop: "30px" }}
        fullWidth
        color="primary"
        type="submit"
      >
        Verify
      </Button>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ marginTop: 20, gap: 10 }}
      >
        <Text variant="body2" style={{ color: theme.palette.blueGray[500] }}>
          Back to
        </Text>
        <Text
          variant="menu02"
          style={{
            color: theme.palette.primary.main,
            fontWeight: 600,
            textDecoration: "none",
          }}
          onClick={() => {
            dispatch(setAuthState(AuthState.LOGIN));
          }}
        >
          Sign In
        </Text>
      </Grid>
      <Backdrop />
    </form>
  );
};

export default VerifyEmail;
