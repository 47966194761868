import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

export const updateBillingProfile = async (data: {
  billing_name: string;
  billing_email: string;
  is_taxable: boolean;
  tax_type: string;
  tax_id: string;
  country: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  postal_code: string;
}) => {
  try {
    const response = await axios.put("/billing/profile", data);
    toast.success("Billing Profile updated successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to update. Please try again"
    );
    throw error;
  }
};
