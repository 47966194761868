export const passwordRule = {
  required: "Password is required",
  minLength: {
    value: 8,
    message: "Password must have at least 8 characters",
  },
  maxLength: {
    value: 64,
    message: "Password must not exceed 64 characters",
  },
  pattern: {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,64}$/,
    message:
      "Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character (e.g., @, $, !, %, *, ?, &, #)",
  },
};

export const emailRule = {
  required: "Email is required",
  pattern: {
    value:
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    message: "Entered value does not match email format",
  },
};

export const otpRule = {
  required: "OTP is required",
  minLength: {
    value: 6,
    message: "OTP must have at least 6 digits",
  },
  maxLength: {
    value: 6,
    message: "OTP must not exceed 6 digits",
  },
  pattern: {
    value: /^[0-9]{6}$/,
    message: "OTP must be 6 digits",
  },
};
