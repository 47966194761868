import { Grid } from "@mui/material";
import Button from "components/Button";
import Chip from "components/Chip";
import DataTable from "components/DataTable";
import Divide from "components/Divide";
import RefreshSvg from "components/icons/Refresh";
import { format } from "date-fns";
import { useTheme } from "hooks/useTheme";
import throttle from "lodash.throttle";
import { useRef } from "react";
import { formatCurrency, upperFirstChar } from "utils/mix";

const InvoicesTable = () => {
  const theme = useTheme();

  const ref = useRef<any>(null);

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container direction="row">
          <Grid item>
            {/* <Input
              sx={{
                borderRadius: "8px !important",
                height: "36px",
                "& .MuiOutlinedInput-input": {
                  padding: "8px 16px !important",
                  borderRadius: "8px !important",
                },
              }}
              placeholder="Search"
              style={{
                height: 36,
                width: 263,
              }}
            /> */}
          </Grid>
          <Grid
            item
            //  style={{ marginLeft: 20 }}
          >
            <Button
              height="medium"
              onClick={throttle(() => {
                ref.current?.reset();
              }, 1000)}
              startIcon={<RefreshSvg fill="#FFF" />}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Divide
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <DataTable
        ref={ref}
        columns={[
          {
            id: "number",
            label: "Number",
            style: {
              fontWeight: 600,
              minWidth: 150,
              color: theme.palette.blueGray[800],
            },
          },
          {
            id: "amount",
            label: "Amount",
            style: {
              fontWeight: 600,
              color: theme.palette.blueGray[800],
            },
            cellRender(value, rawData) {
              // format value with prefix based on currency
              return formatCurrency(value, rawData.currency || "usd");
            },
            sortable: true,
          },
          {
            id: "description",
            label: "Description",
            style: {
              maxWidth: 200,
              minWidth: 170,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
          },
          {
            id: "status",
            label: "Status",
            style: {
              fontWeight: 600,
              color: theme.palette.blueGray[800],
            },
            sortable: true,
            cellRender(value) {
              return (
                <Chip
                  text={upperFirstChar(value)}
                  color={
                    value === "paid"
                      ? theme.palette.green[600]
                      : theme.palette.primary[600]
                  }
                />
              );
            },
          },
          {
            id: "created_at",
            label: "Date",
            cellRender(value) {
              return format(value, "MM/dd/yyyy HH:mm");
            },
            sortable: true,
            style: {
              minWidth: 150,
            },
          },
        ]}
        fetchUrl="/billing/invoices"
        customActions={(row) => {
          return (
            <Button
              height="medium"
              style={{
                minWidth: 115,
              }}
              onClick={() => {
                window.open(`${row.invoice_url}`, "_blank");
              }}
            >
              View Invoice
            </Button>
          );
        }}
      />
    </Grid>
  );
};

export default InvoicesTable;
