const CardSvg = ({ fill = "#607D8B" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/bulk/card">
        <g id="card">
          <path
            id="Vector"
            opacity="0.4"
            d="M18.3334 7.5V13.7167C18.3334 15.625 16.7834 17.1667 14.875 17.1667H5.12502C3.21669 17.1667 1.66669 15.625 1.66669 13.7167V7.5H18.3334Z"
            fill={fill}
          />
          <path
            id="Vector_2"
            d="M18.3334 6.28325V7.49992H1.66669V6.28325C1.66669 4.37492 3.21669 2.83325 5.12502 2.83325H14.875C16.7834 2.83325 18.3334 4.37492 18.3334 6.28325Z"
            fill={fill}
          />
          <path
            id="Vector_3"
            d="M6.66667 14.375H5C4.65833 14.375 4.375 14.0917 4.375 13.75C4.375 13.4083 4.65833 13.125 5 13.125H6.66667C7.00833 13.125 7.29167 13.4083 7.29167 13.75C7.29167 14.0917 7.00833 14.375 6.66667 14.375Z"
            fill={fill}
          />
          <path
            id="Vector_4"
            d="M12.0833 14.375H8.75C8.40833 14.375 8.125 14.0917 8.125 13.75C8.125 13.4083 8.40833 13.125 8.75 13.125H12.0833C12.425 13.125 12.7083 13.4083 12.7083 13.75C12.7083 14.0917 12.425 14.375 12.0833 14.375Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

export default CardSvg;
