const CodeCircleSvg = ({ fill = "#607D8B" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/bulk/code-circle">
        <g id="code-circle">
          <path
            id="Vector"
            opacity="0.4"
            d="M10 18.3334C14.6024 18.3334 18.3334 14.6025 18.3334 10.0001C18.3334 5.39771 14.6024 1.66675 10 1.66675C5.39765 1.66675 1.66669 5.39771 1.66669 10.0001C1.66669 14.6025 5.39765 18.3334 10 18.3334Z"
            fill={fill}
          />
          <g id="Group">
            <path
              id="Vector_2"
              d="M6.66666 12.2917C6.50832 12.2917 6.34999 12.2334 6.22499 12.1084L4.55833 10.4417C4.31666 10.2001 4.31666 9.80003 4.55833 9.55837L6.22499 7.8917C6.46666 7.65003 6.86666 7.65003 7.10832 7.8917C7.34999 8.13337 7.34999 8.53341 7.10832 8.77508L5.88332 10.0001L7.10832 11.225C7.34999 11.4667 7.34999 11.8667 7.10832 12.1084C6.98332 12.2334 6.82499 12.2917 6.66666 12.2917Z"
              fill={fill}
            />
          </g>
          <g id="Group_2">
            <path
              id="Vector_3"
              d="M13.3334 12.2917C13.175 12.2917 13.0167 12.2334 12.8917 12.1084C12.65 11.8667 12.65 11.4667 12.8917 11.225L14.1167 10.0001L12.8917 8.77508C12.65 8.53341 12.65 8.13337 12.8917 7.8917C13.1334 7.65003 13.5334 7.65003 13.775 7.8917L15.4417 9.55837C15.6834 9.80003 15.6834 10.2001 15.4417 10.4417L13.775 12.1084C13.65 12.2334 13.4917 12.2917 13.3334 12.2917Z"
              fill={fill}
            />
          </g>
          <g id="Group_3">
            <path
              id="Vector_4"
              d="M9.16666 12.5666C9.08333 12.5666 9 12.55 8.925 12.5166C8.60833 12.3833 8.45834 12.0167 8.6 11.6917L10.2667 7.79995C10.4 7.48328 10.7667 7.33327 11.0833 7.47494C11.4 7.60827 11.55 7.97498 11.4083 8.29998L9.74166 12.1916C9.64166 12.425 9.40833 12.5666 9.16666 12.5666Z"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CodeCircleSvg;
