import { ButtonProps, Grid, InputAdornment } from "@mui/material";
import { ReactComponent as TopUpIcon } from "assets/icons/top-up.svg";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import Dialog from "components/Dialog";
import Divide from "components/Divide";
import Text from "components/Text";
import Input from "components/form-control/Input";
import { useIsMobile } from "hooks/useIsMobile";
import { useTheme } from "hooks/useTheme";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { format2RawNumeric } from "utils/mix";
import { customTopUp } from "../actions/customTopUp";

const ButtonWrapper = (props: ButtonProps) => (
  <Button {...props} style={{ width: 172, ...props.style }} />
);

const CustomTopUp = () => {
  const theme = useTheme();

  const { Backdrop, close, open } = useBackdrop();
  const [isShowTopUp, setIsShowTopUp] = useState(false);
  const [isShowWarning, setIsShowWarning] = useState(false);

  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      amount: 0,
    },
  });

  const closeWarning = () => {
    setIsShowWarning(false);
  };

  const mutation = useMutation(customTopUp, {
    onSuccess: () => {
      close();
      setIsShowTopUp(true);
    },
    onError: () => {
      close();
    },
  });

  const onSubmit = (data: any) => {
    open();
    mutation.mutate(data);
  };

  const handleClose = () => {
    setValue("amount", 0);
    setIsShowTopUp(false);
  };

  const isMobile = useIsMobile();

  return (
    <Grid container direction="column">
      <Divide
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
      />

      <Grid item style={{ width: !isMobile ? 480 : "unset" }}>
        <form
          onSubmit={handleSubmit(() => {
            setIsShowWarning(true);
          })}
        >
          <Grid container direction="column" style={{ gap: 20 }}>
            <Grid item>
              <Input
                control={control}
                name="amount"
                label="Amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                sx={{
                  "& .MuiInputBase-input": {
                    paddingLeft: "0px !important",
                  },
                }}
                rules={{
                  required: "Amount is required",
                }}
                numeric
              />
            </Grid>
            <Grid item>
              <Button fullWidth type="submit">
                Top-up
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Dialog open={isShowTopUp} onClose={handleClose}>
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          direction={"column"}
        >
          <TopUpIcon />
          <Text
            variant="h3"
            color={theme.palette.blueGray[600]}
            fontWeight={600}
            style={{ marginTop: "16px" }}
          >
            Top-up
          </Text>
          <Text
            style={{
              fontSize: "48px",
              fontWeight: 700,
              lineHeight: "60px",
              color: theme.palette.blueGray[900],
              marginTop: "8px",
            }}
          >
            ${getValues("amount")}
          </Text>
          <Text
            variant="subtitle2"
            style={{ marginTop: "12px" }}
            color={theme.palette.blueGray[500]}
            fontWeight={400}
          >
            Balance Added Successfully.
          </Text>
          <Grid container style={{ marginTop: 30 }}>
            <Grid item style={{ flexGrow: 1 }}>
              <ButtonWrapper
                fullWidth
                variant="outlined"
                style={{
                  backgroundColor: "inherit",
                  border: 1.5,
                  width: isMobile ? "100%" : 360,
                  height: "44px",
                }}
                sx={{
                  "&:hover": {
                    border: 1.5,
                  },
                }}
                onClick={handleClose}
              >
                Close
              </ButtonWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <Backdrop />
      <ConfirmDialog
        open={isShowWarning}
        onClose={closeWarning}
        onConfirm={() => {
          closeWarning();
          onSubmit({
            amount: format2RawNumeric(getValues("amount")),
          });
        }}
        message={`This transaction will charge $${getValues(
          "amount"
        )} to your card. Please confirm you want to proceed!`}
      />
    </Grid>
  );
};

export default CustomTopUp;
