import {
  Dialog as DialogMui,
  DialogProps as DialogMuiProps,
  Grid,
  IconButton,
} from "@mui/material";
import { FC } from "react";
import { ReactComponent as CloseIcon } from "assets/icons/x.svg";
import { useIsMobile } from "hooks/useIsMobile";

export type DialogProps = DialogMuiProps & {
  containerStyle?: React.CSSProperties;
};
const Dialog: FC<DialogProps> = ({ children, containerStyle, ...props }) => {
  const isMobile = useIsMobile();
  return (
    <DialogMui {...props} onClose={props.onClose}>
      <Grid
        container
        direction={"column"}
        style={{
          padding: 30,
          minWidth: isMobile ? "90%" : 420,
          ...containerStyle,
        }}
      >
        <Grid item>
          <IconButton
            aria-label="close"
            onClick={(e) => props.onClose?.(e, "backdropClick")}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </DialogMui>
  );
};

export default Dialog;
