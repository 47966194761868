import { Grid, GridProps, useTheme } from "@mui/material";
import { CustomThemeOptions } from "libs/theme";

const Divide = ({ style }: GridProps) => {
  const theme = useTheme() as CustomThemeOptions;
  return (
    <Grid
      style={{
        backgroundColor: theme.palette.blueGray[50],
        height: 1,
        ...style,
      }}
    />
  );
};
export default Divide;
