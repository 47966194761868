import { Grid, useTheme } from "@mui/material";
import Button from "components/Button";
import Text from "components/Text";
import Input from "components/form-control/Input";
import InputPassword from "components/form-control/InputPassword";
import { AuthState, login, setAuthState } from "features/userSlice";
import { CustomThemeOptions } from "libs/theme";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { registerUser } from "./actions/registerUser";
import { emailRule, passwordRule } from "./utils/rules";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";

const SignUp: React.FC = () => {
  const theme = useTheme() as CustomThemeOptions;
  const dispatch = useDispatch();
  const { Backdrop, close, open } = useBackdrop();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: "",
      password: "",
      first_name: "",
      last_name: "",
    },
  });

  const mutation = useMutation(registerUser, {
    onSuccess: (data) => {
      dispatch(login(data));
      dispatch(setAuthState(AuthState.VERIFY_EMAIL));
      close();
    },
    onError: () => {
      close();
    },
  });

  const onSubmit = (data: any) => {
    open();
    mutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant="h1" style={{ marginBottom: 20 }}>
        Sign Up
      </Text>
      <Input
        control={control}
        name="email"
        label="Email Address"
        style={{ marginTop: 20 }}
        rules={emailRule}
      />

      <Input
        control={control}
        name="first_name"
        label="First Name"
        style={{ marginTop: 20 }}
        rules={{
          required: "First Name is required",
        }}
      />
      <Input
        control={control}
        name="last_name"
        label="Last Name"
        style={{ marginTop: 20 }}
        rules={{
          required: "Last Name is required",
        }}
      />

      <InputPassword
        control={control}
        name="password"
        style={{ marginTop: 20 }}
        label="Password"
        type="password"
        rules={passwordRule}
      />
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        style={{ marginTop: 8, gap: 5 }}
      >
        <Text variant="body2" style={{ color: theme.palette.blueGray[500] }}>
          It must be between 8 and 64 characters long.
        </Text>
      </Grid>

      <Button
        style={{ marginTop: "30px" }}
        fullWidth
        color="primary"
        type="submit"
      >
        Sign Up
      </Button>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ marginTop: 20, gap: 10 }}
      >
        <Text variant="body2" style={{ color: theme.palette.blueGray[500] }}>
          Already have an account?
        </Text>
        <Text
          variant="menu02"
          style={{
            color: theme.palette.primary.main,
            fontWeight: 600,
            textDecoration: "none",
          }}
          onClick={() => {
            dispatch(setAuthState(AuthState.LOGIN));
          }}
        >
          Sign In
        </Text>
      </Grid>
      <Backdrop />
    </form>
  );
};

export default SignUp;
