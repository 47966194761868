import { Box, FormHelperText, useTheme } from "@mui/material";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import Text from "components/Text";
import { CustomThemeOptions } from "libs/theme";
import { MuiOtpInput } from "mui-one-time-password-input";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { twoFactorAuthenticate } from "./actions/twoFactorAuthenticate";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { AuthState, login, setAuthState } from "features/userSlice";
import { useIsMobile } from "hooks/useIsMobile";

const TwoFactorAuthentication: React.FC = () => {
  const theme = useTheme() as CustomThemeOptions;
  const { Backdrop, close, open } = useBackdrop();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: "",
      otp: "",
    },
  });

  const mutation = useMutation(twoFactorAuthenticate, {
    onSuccess: (data) => {
      Cookies.set("token", data.access_token);
      dispatch(login(data.user));
      navigate("/dashboard");
      Cookies.remove("session");
      close();
    },
    onError: (err: any) => {
      close();
      if (err.response?.status === 400) {
        dispatch(setAuthState(AuthState.LOGIN));
      }
    },
  });

  const isMobile = useIsMobile();

  const onSubmit = (data: any) => {
    open();
    const { session_id } = JSON.parse(Cookies.get("session") || "{}");
    mutation.mutate({ ...data, session_id });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant="h1">Two-factor authentication</Text>

      <Text
        variant="body2"
        style={{ marginTop: 10, color: theme.palette.blueGray["500"] }}
      >
        Enter the 6-digit authentication code generated by your app:
      </Text>

      <Controller
        name="otp"
        control={control}
        rules={{ validate: (value) => value.length === 6 }}
        render={({ field, fieldState }) => (
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <Text variant="h6" style={{ marginBottom: 8 }}>
              Verification code
            </Text>
            <MuiOtpInput
              sx={{ width: isMobile ? undefined : 480, gap: 3 }}
              {...field}
              length={6}
              TextFieldsProps={
                isMobile
                  ? undefined
                  : {
                      style: {
                        width: 60,
                        height: 60,
                      },
                      InputProps: {
                        style: {
                          height: 60,
                          fontSize: 28,
                        },
                      },
                    }
              }
            />
            {fieldState.invalid ? (
              <FormHelperText error>OTP invalid</FormHelperText>
            ) : null}
          </Box>
        )}
      />

      <Button
        style={{ marginTop: "20px" }}
        fullWidth
        color="primary"
        type="submit"
      >
        Verify
      </Button>
      <Backdrop />
    </form>
  );
};

export default TwoFactorAuthentication;
