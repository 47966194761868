import instance from "axios";
import { API_URL } from "constants/environments";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const cancelMap: any = {};

const nonCancellableRoutes = ["/stats/usage-analytics"];

const axios = instance.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(
  (config) => {
    const cancel = config.url ? cancelMap[config.url] : undefined;
    if (cancel && !nonCancellableRoutes.includes(config.url ?? "")) {
      cancel();
    }
    config.cancelToken = new instance.CancelToken((c) => {
      if (config.url && !nonCancellableRoutes.includes(config.url)) {
        cancelMap[config.url] = c;
      }
    });

    const token = Cookies.get("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const wait = (ms: number = 3000) =>
  new Promise((resolve) => setTimeout(resolve, ms));

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (instance.isCancel(error)) {
      console.log("Request canceled", error.message);
      return Promise.resolve({});
    } else {
      // If status code is 401, redirect to login page
      if (error.response?.status === 401) {
        Cookies.remove("token");
        if (window.location.pathname !== "/") {
          toast.error("Session expired, please login again.", {
            autoClose: 2000,
          });
          await wait();
          window.location.href = "/";
        }
        return Promise.reject(error);
      }
      // handle other errors
      return Promise.reject(error);
    }
  }
);
export default axios;
