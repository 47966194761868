import { IconButton, InputAdornment } from "@mui/material";
import { ReactComponent as EyeOff } from "assets/icons/eye-off.svg";
import { ReactComponent as EyeOn } from "assets/icons/eye-on.svg";
import { forwardRef, useRef, useState } from "react";
import Input, { InputProps } from "./Input";

type Props = InputProps;

const InputPassword = forwardRef<any, Props>((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickShowPassword = () => {
    const currentFocus = inputRef.current;
    const cursorPosition = currentFocus?.selectionStart;
    setShowPassword((show) => !show);

    if (currentFocus && cursorPosition !== null) {
      setTimeout(() => {
        currentFocus.focus();
        currentFocus.setSelectionRange(
          cursorPosition as number,
          cursorPosition as number
        );
      }, 0);
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <Input
      {...props}
      type={showPassword ? "text" : "password"}
      inputRef={inputRef}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <EyeOff /> : <EyeOn />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
});

export default InputPassword;
