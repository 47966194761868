import { ReactComponent as CheckboxIcon } from "assets/icons/checkbox.svg";
import { ReactComponent as CheckedIcon } from "assets/icons/checked.svg";

import { Checkbox as CheckboxMui, CheckboxProps } from "@mui/material";
import { forwardRef } from "react";

const Checkbox = forwardRef<any, CheckboxProps>((props, ref) => (
  <CheckboxMui
    ref={ref}
    icon={<CheckboxIcon />}
    checkedIcon={<CheckedIcon />}
    {...props}
  />
));

export default Checkbox;
