import { useLocation, useSearchParams } from "react-router-dom";

export const useParams = (param: string, defaultValue = "") => {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  return queryParams.get(param) ?? defaultValue;
};

export const useCustomSearchParams = (
  defaultParams: { [key: string]: any } = {}
) => {
  const [searchParams, setSearchParams] = useSearchParams(defaultParams);
  // consol
  const customParams: { [key: string]: any } = {};
  searchParams.forEach((value, key) => {
    const getAllValue = searchParams.getAll(key);
    customParams[key] = getAllValue.length === 1 ? getAllValue[0] : getAllValue;
  });
  return [customParams as any, setSearchParams];
};
