import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

type SetNewPassword = {
  email: string;
  password: string;
  otp: string;
};
export const setNewPassword = async ({
  email,
  otp,
  password,
}: SetNewPassword) => {
  try {
    const response = await axios.post("/auth/update-password", {
      email,
      otp,
      password,
    });

    toast.success("Password updated successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to update password"
    );
    throw error;
  }
};
