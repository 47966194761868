import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

export const deleteApiKey = async (key_id: string) => {
  try {
    const response = await axios.delete(`/devs/delete-api-key/${key_id}`);
    toast.success("API Key deleted successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to delete. Please try again"
    );
    throw error;
  }
};
