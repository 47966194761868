import CardSvg from "components/icons/Card";
import CodeCircleSvg from "components/icons/CodeCircle";
import DashboardSvg from "components/icons/Dashboard";
import LockSvg from "components/icons/Lock";
import SettingSvg from "components/icons/Setting";
import Auth from "pages/auth/Auth";
import Billing from "pages/billing/Billing";
import Dashboard from "pages/dashboard/Dashboard";
import Develop from "pages/develop/Develop";
import Profile from "pages/profile/Profile";
import { ReactComponent as DocumentSvg } from "assets/icons/document-text.svg";
import { ReactComponent as ChartPie } from "assets/icons/chart-pie.svg";

import UsageAnalytics from "pages/usageAnalytics/UsageAnalytics";

export enum Path {
  Home = "/",
  Dashboard = "/dashboard",
  Develop = "/develop",
  Billing = "/billing",
  UsageAnalytics = "/usage-analytics",
  MyAccount = "/my-account",
  TwoFaSecurity = "/2fa-security",
  APIDocs = "https://docs.proapis.com",
}

export const Paths: {
  path: string;
  component: any;
  isPrivate?: boolean;
  name?: string;
  icon?: React.ReactNode;
  isHeader?: boolean;
  isInvisible?: boolean;
  isExternal?: boolean;
  color?: string;
}[] = [
  {
    path: Path.Home,
    component: Auth,
    isPrivate: false,
    isInvisible: true,
  },
  {
    path: Path.Dashboard,
    component: Dashboard,
    name: "Dashboard",
    isPrivate: true,
    icon: <DashboardSvg />,
  },
  {
    path: Path.UsageAnalytics,
    component: UsageAnalytics,
    name: "Usage Analytics",
    isPrivate: true,
    icon: (
      <ChartPie
        style={{
          width: 20,
          height: 20,
        }}
      />
    ),
    color: "rgb(96, 125, 139)",
  },
  {
    path: Path.Develop,
    component: Develop,
    name: "Developers",
    isPrivate: true,
    icon: <CodeCircleSvg />,
  },
  {
    path: Path.Billing,
    component: Billing,
    name: "Billing",
    isPrivate: true,
    icon: <CardSvg />,
  },
  {
    path: Path.APIDocs,
    component: null,
    name: "API Docs",
    isPrivate: true,
    isExternal: true,
    icon: <DocumentSvg />,
  },
  {
    path: Path.MyAccount,
    name: "My Account",
    icon: <SettingSvg />,
    isPrivate: true,
    component: Profile,
    isHeader: true,
  },
  {
    path: Path.TwoFaSecurity,
    name: "2FA security",
    icon: <LockSvg />,
    isPrivate: true,
    component: Profile,
    isHeader: true,
  },
];
