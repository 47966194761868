import { ThemeOptions, createTheme } from "@mui/material/styles";

export interface CustomThemeOptions extends ThemeOptions {
  palette: {
    primary: {
      light: string;
      main: string;
      dark: string;
      100: string;
      200: string;
      300: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    blueGray: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    deepOrange: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    green: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
  };
}

const theme = createTheme({
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  palette: {
    primary: {
      light: "#E3F2FD",
      main: "#2196F3",
      dark: "#1E88E5",
      100: "#BBDEFB",
      200: "#90CAF9",
      300: "#64B5F6",
      600: "#42A5F5",
      700: "#1976D2",
      800: "#1565C0",
      900: "#0D47A1",
    },
    blueGray: {
      50: "#ECEFF1",
      100: "#CFD8DC",
      200: "#B0BEC5",
      300: "#90A4AE",
      400: "#78909C",
      500: "#607D8B",
      600: "#3A515B",
      700: "#1D2D35",
      800: "#121E23",
      900: "#09090B",
    },
    deepOrange: {
      50: "#FBE9E7",
      100: "#FFCCBC",
      200: "#FFAB91",
      300: "#FF8A65",
      400: "#FF7043",
      500: "#FF5722",
      600: "#F4511E",
      700: "#E64A19",
      800: "#D84315",
      900: "#BF360C",
    },
    green: {
      50: "#E8F5E9",
      100: "#C8E6C9",
      200: "#A5D6A7",
      300: "#81C784",
      400: "#66BB6A",
      500: "#4CAF50",
      600: "#43A047",
      700: "#388E3C",
      800: "#2E7D32",
      900: "#1B5E20",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#2196F3 !important",
            },
          },
        },
        input: {
          borderRadius: "8px !important",
          backgroundColor: "#FFF",
          padding: "10px 16px !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px !important",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: "8px !important",
        },
      },
    },
  },
} as CustomThemeOptions);

export default theme;
