import { Grid } from "@mui/material";
import { useTheme } from "@mui/system";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ReactComponent as AddIcon } from "assets/icons/plus.svg";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import Chip from "components/Chip";
import { useConfirmDialog } from "components/ConfirmDialog/hooks/useConfirmDialog";
import DataTable from "components/DataTable";
import Divide from "components/Divide";
import Text from "components/Text";
import { STRIPE_PUBLIC_KEY } from "constants/environments";
import { format } from "date-fns";
import { useParams } from "hooks/useParams";
import { CustomThemeOptions } from "libs/theme";
import { useRef } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useNavigate } from "react-router-dom";
import FormDialog from "./FormDialog";
import { deletePaymentMethod } from "./actions/deletePaymentMethod";
import { saveDefaultMethod } from "./actions/saveDefaultMethod";
import PaymentIcon from "components/PaymentIcon";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const PaymentMethodsTable = () => {
  const theme = useTheme() as CustomThemeOptions;
  const navigate = useNavigate();

  const { Backdrop, close: closeLoading, open: openLoading } = useBackdrop();

  const { ConfirmDialog, open, close } = useConfirmDialog();

  const targetId = useParams("target_id");

  const ref = useRef<any>();

  const queryClient = new QueryClient();

  const handleDelete = async (targetId: number) => {
    close();
    openLoading();
    await deletePaymentMethod(targetId);
    closeLoading();
    ref.current?.refresh();
  };

  const handleMakeDefault = async (targetId: number) => {
    openLoading();
    await saveDefaultMethod({ id: targetId });
    closeLoading();
    ref?.current?.refresh();
  };
  return (
    <QueryClientProvider client={queryClient}>
      <Grid container direction="column">
        <Grid item>
          <Grid container justifyContent={"space-between"} alignItems="center">
            <Text variant="h2">All cards</Text>
            <Button
              startIcon={<AddIcon />}
              style={{ height: 36 }}
              onClick={() => {
                navigate(`/billing?tab=payment-methods&id=new`);
              }}
            >
              Add Card
            </Button>
          </Grid>
        </Grid>

        <Divide
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        />

        <DataTable
          ref={ref}
          columns={[
            {
              id: "card_brand",
              label: "Card Name",
              style: {
                fontWeight: 600,
                color: theme.palette.blueGray[800],
                minWidth: 140,
              },
              cellRender(value) {
                return (
                  <Grid
                    container
                    direction={"row"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <PaymentIcon width={26} id={value.toLowerCase()} />
                    {value.toUpperCase()}
                  </Grid>
                );
              },
            },
            {
              id: "name",
              label: "Card Holder Name",
              cellRender(value) {
                return value;
              },
              style: {
                minWidth: 170,
              },
            },
            {
              id: "card_last_four",
              label: "Card No.",
              style: {
                minWidth: 110,
                fontWeight: 600,
                color: theme.palette.blueGray[800],
              },
              cellRender(value, rawData) {
                return (
                  <Grid
                    container
                    direction={"row"}
                    alignItems={"center"}
                    gap={1}
                  >
                    **** {value}
                  </Grid>
                );
              },
            },
            {
              id: "card_expiration",
              label: "Expire Date",
              cellRender(value) {
                return format(new Date(value), "MM/yy");
              },
              style: {
                minWidth: 110,
              },
            },
            {
              id: "is_default",
              label: "Status",
              cellRender(value) {
                return value ? (
                  <Chip
                    text={"Default"}
                    color={theme.palette.primary["main"]}
                    dotDisplay={false}
                    style={{
                      padding: "3px 12px",
                    }}
                  />
                ) : (
                  ""
                );
              },
            },
          ]}
          fetchUrl="/billing/payment-methods"
          customActions={(row) =>
            !row.is_default && (
              <Button
                height="medium"
                onClick={() => handleMakeDefault(row.id)}
                style={{
                  padding: "3px 8px",
                  height: "25px",
                  marginRight: "8px",
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                  }}
                  fontWeight={600}
                >
                  Make Default
                </Text>
              </Button>
            )
          }
          onDelete={(row) => {
            open();
            navigate(`/billing?tab=payment-methods&target_id=${row.id}`);
          }}
        />
        <Elements stripe={stripePromise}>
          <FormDialog
            refresh={() => {
              ref?.current?.refresh();
            }}
          />
        </Elements>
      </Grid>

      <ConfirmDialog
        onConfirm={() => {
          handleDelete(Number(targetId));
        }}
        variant="warning"
      />
      <Backdrop />
    </QueryClientProvider>
  );
};

export default PaymentMethodsTable;
