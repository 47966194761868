import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

export const verifyEmail = async ({
  email,
  otp,
}: {
  email: string;
  otp: number;
}) => {
  try {
    const response = await axios.post("/auth/verify-email", {
      email,
      otp,
    });

    toast.success("Successfully verified email");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(error.response?.data?.detail || "Failed to verify email");
    throw error;
  }
};
