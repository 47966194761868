import { Theme } from "@mui/material";
import { useMediaQuery } from "@mui/system";

export const useIsMobile = () => {
  return useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
};

type UseMobileVersionProps = { desktop: any; mobile: any };
export const useMobileVersion = (data: UseMobileVersionProps) => {
  const isMobile = useIsMobile();

  return isMobile ? data.mobile : data.desktop;
};
