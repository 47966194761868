import {
  FormControl,
  MenuItem,
  Select as SelectMui,
  SelectProps as SelectMuiProps,
} from "@mui/material";
import { useTheme } from "hooks/useTheme";
import React, { forwardRef } from "react";
import Text from "./Text";

export type SelectProps = Omit<SelectMuiProps, "variant"> & {
  formControlStyle?: React.CSSProperties;
  options?: { label: string; value: string; icon?: React.ReactNode }[];
  label?: string;
  textHelper?: string | React.ReactNode;
  fullWidth?: boolean;
  variant?: "default" | "standard";
};
const Select = forwardRef<any, SelectProps>(
  (
    {
      value,
      formControlStyle,
      options,
      label,
      textHelper,
      fullWidth,
      variant = "default",
      ...restProps
    },
    ref
  ) => {
    const theme = useTheme();
    const getTextHelper = () => {
      if (textHelper) {
        if (typeof textHelper === "string") {
          return <Text>{textHelper}</Text>;
        }
        return textHelper;
      }
      return undefined;
    };
    return (
      <FormControl
        style={{ width: fullWidth ? "100%" : 97, ...formControlStyle }}
      >
        {label && (
          <Text variant="h6" style={{ marginBottom: 8 }}>
            {label}
          </Text>
        )}
        <SelectMui
          ref={ref}
          value={value}
          label=""
          {...restProps}
          sx={{
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              border: `1px solid ${theme.palette.blueGray[100]}`,
              ...(variant === "default"
                ? {
                    padding: "6px 12px !important",
                    maxHeight: "36px",
                  }
                : {
                    padding: "10px 16px !important",
                    maxHeight: "40px",
                  }),
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ...restProps.sx,
          }}
          style={{
            ...(variant === "default" ? { height: 36 } : { height: 43 }),
            ...restProps.style,
          }}
        >
          {options
            ? options.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.value}
                  sx={{ borderRadius: "0px !important" }}
                >
                  <Text
                    variant="menu02"
                    color={theme.palette.blueGray[500]}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    {option.icon}
                    {option.label}
                  </Text>
                </MenuItem>
              ))
            : null}
        </SelectMui>
        {getTextHelper()}
      </FormControl>
    );
  }
);

export default Select;
