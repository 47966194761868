import { useState } from "react";
import ConfirmDialogComp from "../ConfirmDialog";

export const useConfirmDialog = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);

  const close = () => setIsOpen(false);

  const ConfirmDialog = ({
    onConfirm,
    variant = "default",
  }: {
    onConfirm: () => void;
    variant?: "default" | "danger" | "warning";
  }) => (
    <ConfirmDialogComp
      open={isOpen}
      onClose={close}
      onConfirm={onConfirm}
      variant={variant}
    />
  );

  return { ConfirmDialog, open, close };
};
