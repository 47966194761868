import { ButtonProps, Grid, GridProps, useTheme } from "@mui/material";
import Box from "components/Box";
import Button from "components/Button";
import { CustomThemeOptions } from "libs/theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Path, Paths } from "routes/path";
import MyAccount from "./components/MyAccount";
import TwoFASecurity from "./components/TwoFASecurity";

const BoxWrapper = ({ children, style, ...props }: GridProps) => {
  return (
    <Box style={{ padding: 10, ...style }} {...props}>
      {children}
    </Box>
  );
};

const ButtonWrapper = ({ children, style, ...restProps }: ButtonProps) => {
  return (
    <Button
      style={{
        padding: "10px 16px",
        borderRadius: 8,
        boxShadow: "none",
        fontWeight: 600,
        height: 36,
        ...style,
      }}
      {...restProps}
    >
      {children}
    </Button>
  );
};
export default function Profile() {
  const theme = useTheme() as CustomThemeOptions;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <BoxWrapper>
        <Grid container>
          <Grid item>
            <Grid container style={{ gap: 8 }}>
              {Paths.filter((el) => el.isHeader).map((item, index) => {
                return (
                  <ButtonWrapper
                    style={{
                      backgroundColor:
                        pathname === item.path
                          ? theme.palette.primary.light
                          : "transparent",
                      color:
                        pathname === item.path
                          ? theme.palette.primary.main
                          : theme.palette.blueGray[500],
                      height: 40,
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.main,
                        boxShadow: "none",
                      },
                    }}
                    onClick={() => navigate(item.path)}
                    key={index}
                  >
                    {item.name}
                  </ButtonWrapper>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </BoxWrapper>

      <BoxWrapper style={{ marginTop: 20 }}>
        {pathname === Path.MyAccount && <MyAccount />}
        {pathname === Path.TwoFaSecurity && <TwoFASecurity />}
      </BoxWrapper>
    </>
  );
}
