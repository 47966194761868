import { ButtonProps } from "@mui/material";
import Button from "components/Button";
import { useTheme } from "hooks/useTheme";

const IconButton = ({ children, ...props }: ButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      {...props}
      style={{
        backgroundColor: theme.palette.blueGray[50],
        width: 40,
        height: 36,
        borderRadius: 8,
        minWidth: 40,
        padding: "10px 12px !important",
        boxShadow: "none",
        ...props.style,
      }}
      sx={{
        "&:hover": {
          backgroundColor: theme.palette.blueGray[100],
          boxShadow: "none",
        },
        ...props.sx,
      }}
    >
      {children}
    </Button>
  );
};

export default IconButton;
