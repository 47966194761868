const DashboardSvg = ({ fill = "#607D8B" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/bulk/element-3">
        <g id="element-3">
          <path
            id="Vector"
            opacity="0.4"
            d="M18.3333 7.09984V3.3165C18.3333 2.1415 17.8 1.6665 16.475 1.6665H13.1083C11.7833 1.6665 11.25 2.1415 11.25 3.3165V7.0915C11.25 8.27484 11.7833 8.7415 13.1083 8.7415H16.475C17.8 8.74984 18.3333 8.27484 18.3333 7.09984Z"
            fill={fill}
          />
          <path
            id="Vector_2"
            d="M18.3333 16.475V13.1083C18.3333 11.7833 17.8 11.25 16.475 11.25H13.1083C11.7833 11.25 11.25 11.7833 11.25 13.1083V16.475C11.25 17.8 11.7833 18.3333 13.1083 18.3333H16.475C17.8 18.3333 18.3333 17.8 18.3333 16.475Z"
            fill={fill}
          />
          <path
            id="Vector_3"
            d="M8.75002 7.09984V3.3165C8.75002 2.1415 8.21669 1.6665 6.89169 1.6665H3.52502C2.20002 1.6665 1.66669 2.1415 1.66669 3.3165V7.0915C1.66669 8.27484 2.20002 8.7415 3.52502 8.7415H6.89169C8.21669 8.74984 8.75002 8.27484 8.75002 7.09984Z"
            fill={fill}
          />
          <path
            id="Vector_4"
            opacity="0.4"
            d="M8.75002 16.475V13.1083C8.75002 11.7833 8.21669 11.25 6.89169 11.25H3.52502C2.20002 11.25 1.66669 11.7833 1.66669 13.1083V16.475C1.66669 17.8 2.20002 18.3333 3.52502 18.3333H6.89169C8.21669 18.3333 8.75002 17.8 8.75002 16.475Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

export default DashboardSvg;
