import { Backdrop as BackdropMui, CircularProgress } from "@mui/material";

const Backdrop = ({ open }: { open: boolean }) => (
  <BackdropMui
    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
  >
    <CircularProgress color="inherit" />
  </BackdropMui>
);

export default Backdrop;
