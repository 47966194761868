import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import Button from "components/Button";
import DataTable from "components/DataTable";
import Text from "components/Text";
import { format } from "date-fns";
import { CustomThemeOptions } from "libs/theme";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { formatCurrency, upperFirstChar } from "utils/mix";
import Chip from "../../../components/Chip";

const Table = () => {
  const theme = useTheme() as CustomThemeOptions;

  return (
    <Grid
      container
      style={{
        padding: 20,
        borderRadius: 16,
        boxShadow:
          "0px 0px 2px 0px rgba(153, 169, 182, 0.20), 0px 12px 24px -4px rgba(153, 169, 182, 0.12)",
      }}
      justifyContent={"center"}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Text
          variant="h5"
          style={{ color: theme.palette.blueGray[800], fontWeight: 400 }}
        >
          Invoices
        </Text>

        {/* <DateRangePicker
          value={state}
          onChange={(ranges: any) => setState(ranges)}
        /> */}
      </Grid>
      <Grid container style={{ marginTop: 20 }}>
        <DataTable
          columns={[
            {
              id: "description",
              label: "Description",
              style: {
                maxWidth: 200,
                minWidth: 170,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              },
            },
            {
              id: "amount",
              label: "Amount",
              style: {
                fontWeight: 600,
                color: theme.palette.blueGray[800],
              },
              cellRender(value, rawData) {
                // format value with prefix based on currency
                return formatCurrency(value, rawData.currency || "usd");
              },
              sortable: true,
            },
            {
              id: "status",
              label: "Status",
              style: {
                fontWeight: 600,
                color: theme.palette.blueGray[800],
              },
              sortable: true,
              cellRender(value) {
                return (
                  <Chip
                    text={upperFirstChar(value)}
                    color={
                      value === "paid"
                        ? theme.palette.green[600]
                        : theme.palette.primary[600]
                    }
                  />
                );
              },
            },
            {
              id: "created_at",
              label: "Date",
              cellRender(value) {
                return format(value, "MM/dd/yyyy HH:mm");
              },
              sortable: true,
              style: {
                minWidth: 150,
              },
            },
          ]}
          fetchUrl="/billing/invoices"
          customActions={(row) => {
            return (
              <Button
                height="medium"
                style={{
                  minWidth: 115,
                }}
                onClick={() => {
                  window.open(`${row.invoice_url}`, "_blank");
                }}
              >
                View Invoice
              </Button>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Table;
