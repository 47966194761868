import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";

export const getUser = async () => {
  try {
    const response = await axios.get("/account/me");

    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to get user. Please try again"
    );
    throw error;
  }
};
