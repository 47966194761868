import {
  FormControlLabel,
  Switch as SwitchMui,
  SwitchProps,
  styled,
  useTheme,
} from "@mui/material";
import Text from "./Text";
import { CustomThemeOptions } from "libs/theme";

type Props = SwitchProps & {
  label?: string;
  isForm?: boolean;
};
const Switch = styled(({ label, isForm, ...props }: Props) => {
  const theme = useTheme() as CustomThemeOptions;

  return (
    <FormControlLabel
      label={
        label ? (
          <Text
            variant="h6"
            color={theme.palette.blueGray[800]}
            fontWeight={400}
            style={{ marginLeft: 8 }}
          >
            {label}
          </Text>
        ) : undefined
      }
      sx={{
        ...(label
          ? {}
          : {
              margin: 0,
              marginBottom: isForm ? "20px" : 0,
            }),
      }}
      control={
        <SwitchMui
          focusVisibleClassName=".Mui-focusVisible"
          disableRipple
          {...props}
        />
      }
    ></FormControlLabel>
  );
})(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#2196F3",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#2196F3",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#DEE3E5",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default Switch;
