import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import querystring from "querystring";

export const loginUser = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  try {
    const response = await axios.post(
      "/auth/sign-in",
      querystring.stringify({
        username: email,
        password,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    // Check response status is 428 >>>> Precondition Required
    if (error.response?.status === 428) {
      // If status is 428, redirect to two factor authentication page
      return error.response.data.detail;
    }
    // Check response status is 403 >>>> Precondition Required
    if (error.response?.status === 403) {
      // If status is 403, redirect to verify email page
      return 403;
    }
    sendErrorMessage(
      error.response?.data?.detail || "Failed to login. Please try again"
    );
    throw error;
  }
};
