import axios from "libs/axios";
import { toast } from "react-toastify";

type SetNewPassword = {
  session_id: string;
  otp: string;
};
export const twoFactorAuthenticate = async ({
  session_id,
  otp,
}: SetNewPassword) => {
  try {
    const response = await axios.post("/auth/complete-2fa", {
      session_id,
      otp,
    });

    toast.success("Successfully authenticated with 2FA");
    return response.data;
  } catch (error: any) {
    toast.error(
      error.response?.data?.detail || "Failed to authenticate with 2FA"
    );
    throw error;
  }
};
