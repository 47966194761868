import { useTheme } from "@emotion/react";
import { FormControlLabel, FormGroup } from "@mui/material";
import { CustomThemeOptions } from "libs/theme";
import * as React from "react";
import { Controller } from "react-hook-form";
import { ControllerProps } from "react-spring";
import Checkbox from "../Checkbox";
import Text from "../Text";

type Props = Omit<ControllerProps, "render" | "control" | "name"> & {
  control: any;
  options: { label: string; value: string }[];
  name: string;
  labelStyle?: React.CSSProperties;
  title?: string;
};
const CheckboxGroup: React.FC<Props> = ({
  control,
  name,
  options,
  labelStyle,
  title,
  ...restProps
}) => {
  const theme = useTheme() as CustomThemeOptions;

  return (
    <FormGroup style={{ gap: 16 }}>
      {title && (
        <Text variant="h5" color={theme.palette.blueGray[800]} fontWeight={500}>
          {title}
        </Text>
      )}
      {options.map((option) => (
        <Controller
          key={option.value}
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <FormControlLabel
                style={{
                  height: 20,
                  margin: 0,
                }}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "19.6px",
                    color: theme.palette.blueGray[600],
                    ...labelStyle,
                  },
                }}
                control={
                  <Checkbox
                    style={{
                      padding: 0,
                      width: 18,
                      height: 18,
                      marginRight: 8,
                    }}
                    checked={field?.value?.includes(option.value)}
                    onChange={(e) => {
                      let newValue;

                      if (e.target.checked) {
                        newValue = [...(field.value || []), option.value];
                      } else {
                        newValue = field.value?.filter(
                          (item: any) => item !== option.value
                        );
                      }

                      field.onChange(newValue);
                    }}
                  />
                }
                label={option.label}
              />
            );
          }}
          {...restProps}
        />
      ))}
    </FormGroup>
  );
};

export default CheckboxGroup;
