import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

type Update = {
  password: string;
};
export const twoFASetup = async ({ password }: Update) => {
  try {
    const response = await axios.post(`/account/2fa-setup`, { password });

    toast.success("Two Factor Authentication setup successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to setup 2FA. Please try again"
    );
    throw error;
  }
};
