import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

export const registerUser = async ({
  email,
  password,
  first_name,
  last_name,
}: {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
}) => {
  try {
    const response = await axios.post("/auth/create-account", {
      email,
      password,
      first_name,
      last_name,
      timezone_str: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    toast.success("Account created successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to create account"
    );
    throw error;
  }
};
