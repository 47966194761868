import { Alert, Grid } from "@mui/material";
import { useTheme } from "@mui/system";
import { ReactComponent as MinusIcon } from "assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus1.svg";
import DataTable from "components/DataTable";
import { format, startOfMonth } from "date-fns";
import { useCustomSearchParams } from "hooks/useParams";
import { CustomThemeOptions } from "libs/theme";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils/mix";

const LogsTable = () => {
  const theme = useTheme() as CustomThemeOptions;
  const [searchParams] = useCustomSearchParams({
    startDate: startOfMonth(new Date()).toISOString(),
    endDate: new Date().toISOString(),
  });

  const query = useMemo(() => {
    return searchParams;
  }, [searchParams]);

  const renderHeader = () => {
    return (
      <Alert severity="info" style={{ marginBottom: 5 }}>
        Logs are retrieved based on your configured timezone. You can update
        your timezone in the{" "}
        <Link
          to="/my-account"
          style={{
            color: theme.palette.primary[800],
            fontWeight: 500,
          }}
        >
          settings.
        </Link>
      </Alert>
    );
  };

  return (
    <DataTable
      header={renderHeader}
      columns={[
        {
          id: "request_uri",
          label: "Request URI",
          cellRender: (value: any, row, isExpanded?: boolean) => {
            return (
              <Grid
                container
                style={{
                  cursor: "pointer",
                  gap: 8,
                  fontWeight: isExpanded ? 800 : undefined,
                  flexWrap: "nowrap",
                  textWrap: "nowrap",
                }}
              >
                {isExpanded ? <MinusIcon /> : <PlusIcon />}
                {value}
              </Grid>
            );
          },
          style: {
            minWidth: 260,
          },
        },
        {
          id: "request_id",
          label: "Request ID",
          searchable: true,
          style: {
            minWidth: 350,
            textWrap: "nowrap",
          },
        },
        {
          id: "request_method",
          label: "Request Method",
          style: {
            minWidth: 140,
          },
        },
        {
          id: "cost",
          label: "Cost",
          style: {
            fontWeight: 600,
            color: theme.palette.blueGray[800],
            minWidth: 140,
          },
          cellRender(value) {
            return formatCurrency(value);
          },
          sortable: true,
        },
        {
          id: "latency",
          label: "Latency",
          sortable: true,
          cellRender(value) {
            return `${value} ms`;
          },
          style: {
            minWidth: 140,
          },
        },
        {
          id: "client_ip",
          label: "Client IP",
          searchable: true,
          style: {
            minWidth: 180,
          },
        },
        {
          id: "status_code",
          label: "Status Code",
          sortable: true,
          searchable: true,
          style: {
            minWidth: 120,
          },
        },
        {
          id: "@timestamp",
          label: "Date",
          sortable: true,
          sortAlias: "timestamp",
          cellRender(value) {
            return format(value, "MM/dd/yyyy HH:mm");
          },
          style: {
            minWidth: 145,
          },
        },
      ]}
      fetchUrl="/devs/api-logs"
      accordionContent={AccordionContent}
      query={query}
    />
  );
};

export default LogsTable;

const AccordionContent = (row: any) => {
  const prettyJson = JSON.stringify(
    {
      request_body: row.request_body,
      response_body: row.response_body,
    },
    null,
    2
  );
  return <pre style={{ textWrap: "wrap" }}>{prettyJson}</pre>;
};
