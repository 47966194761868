import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

export const deletePaymentMethod = async (payment_method_id: number) => {
  try {
    const response = await axios.delete(
      `billing/delete-payment-method/${payment_method_id}`
    );

    toast.success("Successfully deleted payment method");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to delete payment method"
    );
    throw error;
  }
};
