import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

export const sendVerificationEmail = async ({ email }: { email: string }) => {
  try {
    const response = await axios.post("/auth/send-verification-email", {
      email,
    });

    toast.success("Sent verification email successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to send verification email"
    );
    throw error;
  }
};
