import { Typography, TypographyProps, useTheme } from "@mui/material";
import { useMobileVersion } from "hooks/useIsMobile";
import { CustomThemeOptions } from "libs/theme";
import React, { FC, useMemo } from "react";

export type TextProps = Omit<TypographyProps, "variant"> & {
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "h6-bold"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "menu02";
  to?: string;
  color?: string;
  fontWeight?: number;
};
const Text: FC<TextProps> = ({ color, fontWeight, ...props }) => {
  const theme = useTheme() as CustomThemeOptions;

  const fontSize = useMobileVersion({
    desktop: {
      h1: 32,
      subtitle1: 20,
    },
    mobile: {
      h1: 24,
      subtitle1: 16,
    },
  });

  const style: React.CSSProperties = useMemo(() => {
    switch (props.variant) {
      case "h1":
        return {
          fontWeight: fontWeight ?? 700,
          lineHeight: "48px",
          color: color ?? theme.palette.blueGray?.["900"],
          fontSize: fontSize.h1,
        };
      case "h2":
        return {
          fontSize: 28,
          fontWeight: fontWeight ?? 700,
          lineHeight: "42px",
          color: color ?? theme.palette.blueGray?.["900"],
        };
      case "h3":
        return {
          fontSize: 24,
          fontWeight: fontWeight ?? 700,
          lineHeight: "33.6px",
          color: color ?? theme.palette.blueGray?.["900"],
        };
      case "h4":
        return {
          fontSize: 20,
          fontWeight: fontWeight ?? 700,
          lineHeight: "24px",
          color: color ?? theme.palette.blueGray?.["900"],
        };
      case "h5":
        return {
          fontSize: 16,
          fontWeight: fontWeight ?? 700,
          lineHeight: "24px",
          color,
        };
      case "h6":
        return {
          fontSize: 14,
          fontWeight: fontWeight ?? 500,
          lineHeight: "19.6px",
          color,
        };
      case "h6-bold":
        return {
          fontSize: 14,
          fontWeight: fontWeight ?? 700,
          lineHeight: "19.6px",
          color: color ?? theme.palette.blueGray?.["900"],
        };
      case "subtitle1":
        return {
          fontSize: fontSize.subtitle1,
          fontWeight: fontWeight ?? 400,
          lineHeight: "30px",
          color: color ?? theme.palette.blueGray?.["500"],
        };
      case "subtitle2":
        return {
          fontSize: 16,
          fontWeight: fontWeight ?? 700,
          lineHeight: "24px",
          color: color ?? theme.palette.blueGray?.["900"],
        };
      case "body1":
        return {
          fontSize: 16,
          fontWeight: fontWeight ?? 400,
          lineHeight: "24px",
          color,
        };
      case "body2":
        return {
          fontSize: 14,
          fontWeight: fontWeight ?? 400,
          lineHeight: "22px",
          color,
        };
      case "menu02":
        return {
          fontSize: 14,
          fontWeight: fontWeight ?? 400,
          lineHeight: "19.6px",
          color: color ?? theme.palette.blueGray?.["900"],
        };
      case "caption":
        return {
          fontSize: 12,
          fontWeight: fontWeight ?? 400,
          lineHeight: "18px",
          color,
        };
      default:
        return {};
    }
  }, [color, fontWeight, props.variant, theme.palette.blueGray, fontSize]);

  return (
    <Typography
      {...props}
      style={{
        margin: 0,
        ...style,
        ...props.style,
        cursor: props.onClick ? "pointer" : undefined,
      }}
      variant={undefined}
    />
  );
};

export default Text;
