import { ButtonProps, Grid } from "@mui/material";
import Checkbox from "components/form-control/Checkbox";
import Dialog from "components/Dialog";
import Text from "components/Text";
import Input from "components/form-control/Input";
import { useTheme } from "hooks/useTheme";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "components/Button";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { getClientSecret } from "./actions/getClientSecret";
import { useMutation } from "react-query";
import { savePaymentMethod } from "./actions/savePaymentMethod";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import "./styles.css";
import { sendErrorMessage } from "libs/toast";
import { useIsMobile, useMobileVersion } from "hooks/useIsMobile";

export const ButtonWrapper = (props: ButtonProps) => (
  <Button {...props} style={{ width: 242, ...props.style }} />
);

export type Props = {
  refresh: () => void;
};
const FormDialog: FC<Props> = ({ refresh }) => {
  const theme = useTheme();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  const stripe = useStripe();
  const elements = useElements();
  const { Backdrop, close, open } = useBackdrop();

  const mutation = useMutation(savePaymentMethod, {
    onSuccess: () => {
      refresh();
      close();
      handleClose();
    },
    onError: () => {
      close();
    },
  });

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      card_holder_name: "",
      card_no: "",
      expire_date: "",
      cvv: "",
      is_default: false,
    },
  });

  useEffect(() => {
    if (id && id !== "new") {
      reset({
        card_holder_name: "Guy Hawkins",
        card_no: "5412 4744 6587 4123",
        expire_date: "05/2025",
        cvv: "357",
        is_default: true,
      });
    } else {
      reset({
        card_holder_name: "",
        card_no: "",
        expire_date: "",
        cvv: "",
        is_default: false,
      });
    }
  }, [id, reset]);

  const openDialog = !!id;

  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    if (!stripe || !elements) {
      return;
    }

    open();

    const cardNumberElement = elements.getElement(CardNumberElement);

    const { client_secret, error: err } = await getClientSecret();

    if (!client_secret) {
      sendErrorMessage(err || "Failed to save card");
      close();
      return;
    }

    const { error, setupIntent } = await stripe.confirmCardSetup(
      client_secret,
      {
        payment_method: {
          card: cardNumberElement!!,
          billing_details: {
            name: data.card_holder_name,
          },
        },
      }
    );

    if (error) {
      sendErrorMessage(error?.message || "Failed to save card");
      close();
      return;
    }

    if (setupIntent.status === "succeeded") {
      mutation.mutate({
        stripe_id: setupIntent.payment_method as string,
        setup_intent_id: setupIntent.id,
        set_default: data.is_default,
      });
      return;
    }
    close();
    sendErrorMessage(setupIntent.cancellation_reason || "Failed to save card");
  };

  const handleClose = () => {
    navigate("/billing?tab=payment-methods");
  };

  const isMobile = useIsMobile();

  const btnStyle = useMobileVersion({
    desktop: {},
    mobile: {
      width: "unset",
      flexGrow: 1,
    },
  });
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      containerStyle={{ width: isMobile ? "unset" : 560 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="column"
          style={{
            gap: 16,
          }}
        >
          <Text
            variant="h3"
            color={theme.palette.blueGray[800]}
            fontWeight={500}
          >
            Add new card
          </Text>

          <label>
            <Text variant="h6" style={{ marginBottom: 8 }}>
              Card number
            </Text>
            <CardNumberElement
              options={{
                showIcon: true,
                disableLink: true,
                placeholder: "**** **** **** ****",
              }}
            />
          </label>
          <Grid container style={{ gap: 16 }}>
            <Grid item style={{ flexGrow: 1, width: 242 }}>
              <label>
                <Text variant="h6" style={{ marginBottom: 8 }}>
                  Expiration date
                </Text>
                <CardExpiryElement />
              </label>
            </Grid>
            <Grid item style={{ flexGrow: 1, width: 242 }}>
              <label>
                <Text variant="h6" style={{ marginBottom: 8 }}>
                  CVC
                </Text>
                <CardCvcElement />
              </label>
            </Grid>
          </Grid>
          <Input
            label="Card Holder Name"
            control={control}
            name="card_holder_name"
          />
          <Checkbox
            control={control}
            name="is_default"
            title="Set as default"
          />

          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 14, gap: 10 }}
          >
            <ButtonWrapper
              variant="outlined"
              style={{
                backgroundColor: "inherit",
                border: 1.5,
                height: "44px",
                ...btnStyle,
              }}
              sx={{
                "&:hover": {
                  border: 1.5,
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </ButtonWrapper>
            <ButtonWrapper type="submit" style={btnStyle}>
              Save
            </ButtonWrapper>
          </Grid>
        </Grid>
        <Backdrop />
      </form>
    </Dialog>
  );
};

export default FormDialog;
