import { setSidebarOpen } from "features/userSlice";
import { RootState } from "libs/store";
import { useDispatch, useSelector } from "react-redux";

const SIDEBAR_EXPAND = "sidebar_expand";

export const useSidebarExpand = () => {
  const isExpand = useSelector((state: RootState) => state.user.sidebarOpen);
  const dispatch = useDispatch();
  const handleExpand = () => {
    dispatch(setSidebarOpen(!isExpand));
    localStorage.setItem(SIDEBAR_EXPAND, JSON.stringify(!isExpand));
  };

  return { isExpand, handleExpand };
};
