import axios from "libs/axios";

export const getTaxTypes = async (): Promise<
  {
    country: string;
    id: string;
  }[]
> => {
  try {
    const response = await axios.get("/billing/tax-types");

    return response.data;
  } catch (error: any) {
    return [];
  }
};
