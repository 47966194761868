import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

type SaveData = {
  stripe_id: string;
  setup_intent_id: string;
  set_default?: boolean;
};
export const savePaymentMethod = async ({
  stripe_id,
  setup_intent_id,
  set_default = false,
}: SaveData) => {
  try {
    const response = await axios.post("/billing/save-payment-method", {
      stripe_id,
      setup_intent_id,
      set_default,
    });

    toast.success("Successfully saved payment method");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to save payment method"
    );
    throw error;
  }
};
