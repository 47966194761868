import { ButtonProps, Grid, IconButton } from "@mui/material";
import { useTheme } from "@mui/system";
import { ReactComponent as CopyIcon } from "assets/icons/clipboard-list.svg";
import { ReactComponent as ChartStatIcon } from "assets/icons/stats.svg";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import DataTable from "components/DataTable";
import Dialog from "components/Dialog";
import Input from "components/Input";
import Text from "components/Text";
import Autocomplete from "components/form-control/Autocomplete";
import { format } from "date-fns";
import { useIsMobile, useMobileVersion } from "hooks/useIsMobile";
import { useParams } from "hooks/useParams";
import { CustomThemeOptions } from "libs/theme";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Path } from "routes/path";
import { createApiKey } from "../actions/createApiKey";
import { deleteApiKey } from "../actions/deleteApiKey";
import { updateApiKeyLabel } from "../actions/updateApiKey";
import DevelopContext from "../context/DevelopContext";

const ButtonWrapper = (props: ButtonProps) => (
  <Button {...props} style={{ width: 242, ...props.style }} />
);

const KeysTable = () => {
  const theme = useTheme() as CustomThemeOptions;
  const { Backdrop, close, open: openBackdrop } = useBackdrop();
  const [apiKey, setApiKey] = useState("");

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      service: null,
    },
  });

  console.log("service", watch("service"));

  const { keyTableRef } = useContext(DevelopContext);

  const [searchState, setSearchState] = useState("");

  const deleteId = useParams("id");

  const navigate = useNavigate();
  const type = useParams("type");
  const isMobile = useIsMobile();

  const mutation = useMutation(createApiKey, {
    onSuccess: (data: any) => {
      close();
      setApiKey(data.api_key);
      keyTableRef.current.refresh();
    },
    onError: () => {
      close();
    },
  });

  const mutationDelete = useMutation(deleteApiKey, {
    onSuccess: () => {
      close();
      keyTableRef.current.refresh();
    },
    onError: () => {
      close();
    },
  });

  const mutationUpdateLabel = useMutation(updateApiKeyLabel, {
    onSuccess: () => {
      close();
    },
    onError: () => {
      close();
    },
  });

  const handelOnChange = (e: any) => {
    setSearchState(e.target.value);
  };

  const onSubmit = async (data: any) => {
    handleClose();
    openBackdrop();
    mutation.mutate({
      service_id: data.service.id,
    });
  };

  const btnStyle = useMobileVersion({
    desktop: {},
    mobile: {
      width: "unset",
      flexGrow: 1,
    },
  });

  const handleClose = () => {
    navigate("/develop");
  };
  const open = type === "add";

  const handleClosePrompt = () => {
    setApiKey("");
  };

  const handleDelete = () => {
    navigate("/develop");
    openBackdrop();
    mutationDelete.mutate(deleteId);
  };

  const handleChangeLabel = (newValue: any, rowData: any) => {
    openBackdrop();
    mutationUpdateLabel.mutate({ label: newValue, id: rowData.id });
  };

  function copyToClipboard(text: string) {
    if (!navigator.clipboard) {
      // Clipboard API not supported, use fallback method
      return fallbackCopyToClipboard(text);
    }
    return navigator.clipboard.writeText(text).then(
      function () {
        // Success! Text copied
        console.log("Copied to clipboard!");
      },
      function (err) {
        // Failed to copy
        console.error("Failed to copy to clipboard:", err);
      }
    );
  }

  function fallbackCopyToClipboard(text: string) {
    // Create a temporary element
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);

    // Select the text content
    textArea.focus();
    textArea.select();

    try {
      // Try copying with execCommand (deprecated)
      const successful = document.execCommand("copy");
      const msg = successful
        ? "Copied to clipboard!"
        : "Fallback copying failed";
      console.log(msg);
    } catch (err) {
      console.error("Fallback copying failed:", err);
    }

    // Remove the temporary element
    document.body.removeChild(textArea);
  }

  const handleCopy = () => {
    copyToClipboard(apiKey);
    toast.success("API Key copied to clipboard");
  };

  return (
    <>
      <DataTable
        header={() => (
          <Input
            placeholder="Search API Key by label"
            value={searchState}
            onChange={handelOnChange}
            style={{
              width: !isMobile ? 320 : "100%",
              marginBottom: 5,
            }}
          />
        )}
        ref={keyTableRef}
        columns={[
          {
            id: "id",
            label: "API Key ID",
            style: {
              fontWeight: 600,
              color: theme.palette.blueGray[800],
              minWidth: 80,
            },
          },
          {
            id: "label",
            label: "Key Label",
            editable: true,
            deletable: false,
            style: {
              minWidth: 270,
            },
          },
          {
            id: "service",
            label: "Service",
            cellRender(value) {
              return value.name;
            },
          },
          {
            id: "last_used",
            label: "Last Used",
            sortable: true,
            cellRender(value) {
              return value ? format(value, "MM/dd/yyyy HH:mm") : "";
            },
            style: {
              minWidth: 170,
            },
          },
          {
            id: "created_at",
            label: "Created At",
            sortable: true,
            cellRender(value) {
              return format(value, "MM/dd/yyyy HH:mm");
            },
            style: {
              minWidth: 145,
            },
          },
        ]}
        fetchUrl="devs/api-keys"
        onDelete={(row) => navigate(`/develop?id=${row.id}&type=delete`)}
        onCellChanged={handleChangeLabel}
        customActions={(row) => {
          return (
            <IconButton
              onClick={() =>
                navigate(
                  `${Path.UsageAnalytics}?filter_by=api_key&filter_id=${row.id}&filter_name=${row.label}`
                )
              }
            >
              <ChartStatIcon style={{ width: 24, height: 24 }} />
            </IconButton>
          );
        }}
        query={{
          search: searchState,
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        containerStyle={{ width: isMobile ? "unset" : 560 }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="column"
            style={{
              gap: 16,
            }}
          >
            <Text
              variant="h3"
              color={theme.palette.blueGray[800]}
              fontWeight={500}
            >
              Add new API
            </Text>

            <Grid item>
              <Autocomplete
                fullWidth
                control={control}
                key="service_id"
                label="Service"
                name="service"
                style={{
                  height: 42,
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 42,
                    padding: "8px 16px",
                  },
                  "& .MuiInputBase-input": {
                    height: 0,
                    paddingLeft: "0px !important",
                  },
                  "& .Mui-expanded": {},
                }}
                options={[]}
                url="/products/services"
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => {
                  setValue("service", value);
                }}
              />
            </Grid>

            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 14, gap: 10 }}
            >
              <ButtonWrapper
                variant="outlined"
                style={{
                  backgroundColor: "inherit",
                  border: 1.5,
                  height: "44px",
                  ...btnStyle,
                }}
                sx={{
                  "&:hover": {
                    border: 1.5,
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </ButtonWrapper>
              <ButtonWrapper type="submit" style={btnStyle}>
                Save
              </ButtonWrapper>
            </Grid>
          </Grid>
        </form>
      </Dialog>
      <ConfirmDialog
        open={!!apiKey}
        onClose={handleClosePrompt}
        customActions={
          <ButtonWrapper
            onClick={handleClosePrompt}
            fullWidth
            style={{ flexGrow: 1 }}
          >
            Close
          </ButtonWrapper>
        }
        header=""
        message=""
      >
        <Text variant="h3">API Key</Text>
        <Grid container justifyContent={"center"} gap={1}>
          <Grid item>
            <Text
              variant="h6"
              style={{
                backgroundColor: theme.palette.blueGray[50],
                padding: 8,
                borderRadius: 8,
                height: 36,
              }}
            >
              {apiKey}
            </Text>
          </Grid>
          <Grid item>
            <IconButton onClick={handleCopy}>
              <CopyIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Text
          variant="h5"
          fontWeight={400}
          style={{ marginTop: 5, maxWidth: 380, textAlign: "center" }}
          color={theme.palette.blueGray[600]}
        >
          Please copy the API Key and store it securely (it won't be shown
          again).
        </Text>
      </ConfirmDialog>

      <ConfirmDialog
        open={!!deleteId}
        onClose={() => {
          navigate("/develop");
        }}
        onConfirm={handleDelete}
      ></ConfirmDialog>

      <Backdrop />
    </>
  );
};

export default KeysTable;
