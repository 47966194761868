import { Grid, useTheme } from "@mui/material";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import Text from "components/Text";
import Input from "components/form-control/Input";
import InputPassword from "components/form-control/InputPassword";
import { AuthState, setAuthState } from "features/userSlice";
import { RootState } from "libs/store";
import { CustomThemeOptions } from "libs/theme";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "./actions/forgotPassword";
import { setNewPassword } from "./actions/setNewPassword";
import { otpRule, passwordRule } from "./utils/rules";

const NewPassword: React.FC = () => {
  const theme = useTheme() as CustomThemeOptions;
  const userEmail = useSelector((state: RootState) => state.user.email);
  const { Backdrop, close, open } = useBackdrop();

  const dispatch = useDispatch();

  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      password: "",
      confirm_password: "",
      otp: "",
    },
  });

  const mutation = useMutation(setNewPassword, {
    onSuccess: (data) => {
      dispatch(setAuthState(AuthState.LOGIN));
      open();
    },
    onError: () => {
      close();
    },
  });

  const onSubmit = (data: any) => {
    open();
    mutation.mutate({
      ...data,
      email: userEmail,
    });
  };

  const mutationResendOTP = useMutation(forgotPassword, {
    onSuccess: () => {
      close();
    },
    onError: () => {
      close();
    },
  });

  const resendOTP = () => {
    open();
    mutationResendOTP.mutate({ email: userEmail as string });
  };

  const password = watch("password");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant="h1" style={{ marginBottom: 20 }}>
        Set New Password
      </Text>

      <Text
        variant="body2"
        style={{ marginTop: 10, color: theme.palette.blueGray["500"] }}
      >
        We've sent you an email containing an OTP. Let's check it and use it to
        reset your password
      </Text>

      <InputPassword
        control={control}
        name="password"
        style={{ marginTop: 20 }}
        label="Password"
        type="password"
        rules={passwordRule}
      />
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        style={{ marginTop: 8, gap: 5 }}
      >
        <Text variant="body2" style={{ color: theme.palette.blueGray[500] }}>
          It must be between 8 and 64 characters long.
        </Text>
      </Grid>

      <InputPassword
        name="confirm_password"
        control={control}
        style={{ marginTop: 20 }}
        label="Confirm Password"
        type="password"
        rules={{
          required: "Password is required",
          validate: (value) =>
            value === password || "The passwords do not match",
        }}
      />

      <Input
        control={control}
        name="otp"
        label="OTP"
        style={{ marginTop: 20 }}
        placeholder="Enter the One time password"
        rules={otpRule}
      />

      <Grid container style={{ marginTop: 5, gap: 5 }}>
        <Grid item>
          <Text variant="body2" color={theme.palette.blueGray[500]}>
            Didn't receive OTP code?
          </Text>
        </Grid>
        <Grid item>
          <Text
            variant="body2"
            color={theme.palette.primary["main"]}
            fontWeight={600}
            style={{
              cursor: "pointer",
            }}
            onClick={resendOTP}
          >
            Resend
          </Text>
        </Grid>
      </Grid>

      <Button
        style={{ marginTop: "30px" }}
        fullWidth
        color="primary"
        type="submit"
      >
        Done
      </Button>
      <Backdrop />
    </form>
  );
};

export default NewPassword;
