import axios from "libs/axios";

export const getClientSecret = async (): Promise<{
  client_secret: string;
  error?: string;
}> => {
  try {
    const response = await axios.post("/billing/setup-intent");

    return response.data;
  } catch (error: any) {
    return {
      client_secret: "",
      error: error.response?.data?.detail || "Failed to get client secret",
    };
  }
};
