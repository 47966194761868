import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";

type SaveData = {
  amount: number;
};
export const customTopUp = async (data: SaveData) => {
  try {
    const response = await axios.post("/billing/top-up", data);
    // toast.success("Top-up successful");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to update. Please try again"
    );
    throw error;
  }
};
