import { Grid, InputAdornment } from "@mui/material";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import Divide from "components/Divide";
import Switch from "components/Switch";
import Text from "components/Text";
import Input from "components/form-control/Input";
import { setUser } from "features/userSlice";
import { useIsMobile } from "hooks/useIsMobile";
import { useTheme } from "hooks/useTheme";
import { RootState } from "libs/store";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { disableAutoTopUp } from "../actions/disableAutoTopUp";
import { enableAutoTopUp } from "../actions/enableAutoTopUp";
import { format2RawNumeric } from "utils/mix";

const AutoTopUp = () => {
  const theme = useTheme();
  const user = useSelector((state: RootState) => state.user.user);

  const { Backdrop, close, open } = useBackdrop();

  const dispatch = useDispatch();

  const { control, handleSubmit, watch, setValue, reset, getValues } = useForm({
    defaultValues: {
      top_up_amount: 0,
      autoTopUp: false,
      top_up_threshold: 0,
    },
  });

  const autoTopUp = watch("autoTopUp");

  const mutation = useMutation(enableAutoTopUp, {
    onSuccess: () => {
      close();
      dispatch(
        setUser({
          auto_top_up: true,
          top_up_amount: format2RawNumeric(getValues("top_up_amount")),
          top_up_threshold: format2RawNumeric(getValues("top_up_threshold")),
        })
      );
    },
    onError: () => {
      close();
    },
  });

  const mutationDisable = useMutation(disableAutoTopUp, {
    onSuccess: () => {
      close();
      setValue("autoTopUp", false);
      dispatch(
        setUser({
          auto_top_up: false,
          top_up_amount: 0,
          top_up_threshold: 0,
        })
      );
    },
    onError: () => {
      close();
    },
  });

  useEffect(() => {
    if (user?.id) {
      reset({
        autoTopUp: user.auto_top_up,
        top_up_amount: user.top_up_amount,
        top_up_threshold: user.top_up_threshold,
      });
    }
  }, [reset, user]);

  const onSubmit = (data: any) => {
    open();
    mutation.mutate({
      ...data,
      top_up_amount: format2RawNumeric(data.top_up_amount),
      top_up_threshold: format2RawNumeric(data.top_up_threshold),
    });
  };

  const handleSwitch = async () => {
    if (autoTopUp && user?.auto_top_up) {
      open();
      mutationDisable.mutate();
    } else {
      setValue("autoTopUp", !autoTopUp);
    }
  };

  const isMobile = useIsMobile();

  return (
    <Grid container direction="column">
      <Divide
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
      />

      <Grid item style={{ width: isMobile ? "unset" : 480 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" style={{ gap: 20 }}>
            <Grid item>
              <Grid container style={{ gap: 30 }}>
                <Text variant="h5" color={theme.palette.blueGray[800]}>
                  Auto Top-up
                </Text>
                <Switch checked={autoTopUp} label="" onChange={handleSwitch} />
              </Grid>
            </Grid>

            {autoTopUp && (
              <>
                <Grid item>
                  <Input
                    control={control}
                    name="top_up_threshold"
                    label="Threshold Limit"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    sx={{
                      "& .MuiInputBase-input": {
                        paddingLeft: "0px !important",
                      },
                    }}
                    disabled={!!user?.auto_top_up}
                    rules={{
                      required: "Threshold Limit is required",
                      min: { value: 50, message: "Minimum value is 50" },
                    }}
                    numeric
                  />
                </Grid>
                <Grid item>
                  <Grid container wrap="nowrap" style={{ gap: 20 }}>
                    <Input
                      control={control}
                      name="top_up_amount"
                      label="Amount"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      disabled={!!user?.auto_top_up}
                      sx={{
                        "& .MuiInputBase-input": {
                          paddingLeft: "0px !important",
                        },
                      }}
                      rules={{
                        required: "Amount is required",
                        min: { value: 100, message: "Minimum value is 100" },
                      }}
                      numeric
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {!user?.auto_top_up && autoTopUp && (
              <Grid item>
                <Button fullWidth type="submit">
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>
      <Backdrop />
    </Grid>
  );
};

export default AutoTopUp;
