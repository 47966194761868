import React, { FC } from "react";

type Props = {
  style?: React.CSSProperties;
  id: string;
  transparent?: boolean;
  width?: string | number;
};
const PaymentIcon: FC<Props> = ({ style, width, id, transparent }) => {
  return (
    <img
      src={`/assets/icons/payments/${id}.svg`}
      style={{
        width,
        ...style,
      }}
      onError={(e) => {
        // e.currentTarget.src = "/assets/icons/payments/placeholder.webp";
        e.currentTarget.src = "/assets/icons/payments/default.png";
      }}
      alt={` payment icon`}
    />
  );
};

export default PaymentIcon;
