import {
  Autocomplete as AutocompleteMui,
  AutocompleteProps as AutocompleteMuiProps,
  CircularProgress,
  Grid,
  TextField,
  debounce,
} from "@mui/material";
import { GridDirection, ResponsiveStyleValue } from "@mui/system";
import { useTheme } from "hooks/useTheme";
import axios from "libs/axios";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import Text from "./Text";

export type AutocompleteProps = Omit<
  AutocompleteMuiProps<any, false, false, false>,
  "renderInput"
> & {
  label?: string;
  placeholder?: string;
  direction?: ResponsiveStyleValue<GridDirection>;
  height?: number;
  url?: string;
};
const Autocomplete = forwardRef<any, AutocompleteProps>(
  (
    { label, placeholder, direction, height = 43, url, options, ...restProps },
    ref
  ) => {
    const theme = useTheme();
    const [inputValue, setInputValue] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const {
      data: dataOptions,
      isFetching,
      refetch,
      remove,
    } = useQuery({
      queryKey: "services",
      queryFn: () => {
        const trimValue = inputValue.trim();
        if (trimValue) {
          if (
            (restProps.getOptionLabel &&
              trimValue ===
                restProps.getOptionLabel?.(restProps.value).trim()) ||
            trimValue === restProps.value?.label?.trim()
          ) {
            if (restProps.value?.isEmpty) {
              return axios.get(String(url));
            }
          }
        }
        return axios.get(`${url}?search=${inputValue}`);
      },
      staleTime: Infinity,
      enabled: false,
    });

    const fetchOptions = async () => {
      if (!url) {
        return;
      }
      refetch();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceFn = useCallback(debounce(fetchOptions, 500), []);

    useEffect(() => {
      if (isOpen) {
        debounceFn();
      } else {
        remove();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, inputValue]);

    const handleInputChange = (_: any, newInputValue: string) => {
      setInputValue(newInputValue);
    };

    const newOptions = useMemo(() => {
      return [...(options ?? []), ...(dataOptions?.data?.data ?? [])];
    }, [options, dataOptions?.data?.data]);

    return (
      <Grid container direction={direction || "column"}>
        {label && (
          <Text variant="h6" style={{ marginBottom: 8 }}>
            {label}
          </Text>
        )}
        <AutocompleteMui
          ref={ref}
          style={{
            height,
            width: "100%",
          }}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          sx={{
            "& .MuiInputBase-root": {
              height,
              padding: "8px 16px",
              fontSize: "14px",
              color: theme.palette.blueGray[500],
            },
            "& .MuiInputBase-input": {
              height: 0,
              paddingLeft: "0px !important",
              borderRadius: "0px !important",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgb(208 216 221)",
            },
          }}
          options={newOptions}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          {...restProps}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                placeholder={placeholder}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isFetching ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            );
          }}
        />
      </Grid>
    );
  }
);

export default Autocomplete;
