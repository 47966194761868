import { FC } from "react";
import { hexToRgba } from "utils/mix";

type Props = {
  text: string;
  color: string;
  dotDisplay?: boolean;
  style?: React.CSSProperties;
};
const Chip: FC<Props> = ({ color, text, dotDisplay = true, style }) => {
  return (
    <div
      style={{
        padding: "3px 8px",
        color,
        backgroundColor: hexToRgba(color, 0.2),
        borderRadius: 4,
        display: "inline-flex",
        alignItems: "center",
        ...style,
      }}
    >
      {dotDisplay && (
        <span
          style={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: color,
            display: "inline-block",
            marginRight: 5,
          }}
        ></span>
      )}
      <span style={{ fontWeight: 400 }}>{text}</span>
    </div>
  );
};

export default Chip;
