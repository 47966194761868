import { Grid } from "@mui/material";
import Img from "assets/images/banner.webp";
import ImgSmall from "assets/images/logo.png";
import { AuthState } from "features/userSlice";
import { useIsMobile, useMobileVersion } from "hooks/useIsMobile";
import { RootState } from "libs/store";
import React from "react";
import { useSelector } from "react-redux";
import { animated, useSpring, useTransition } from "react-spring";
import ForgotPassword from "./ForgotPassword";
import NewPassword from "./NewPassword";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import TwoFactorAuthentication from "./TwoFactorAuthentication";
import VerifyEmail from "./VerifyEmail";

const Auth: React.FC = () => {
  const authState = useSelector((state: RootState) => state.user.authState);

  const getHeight = () => {
    switch (authState) {
      case AuthState.LOGIN:
        return "458px";
      case AuthState.TWO_FACTOR_AUTH:
        return "246px";
      case AuthState.SIGN_UP:
        return "557px";
      case AuthState.FORGOT_PASSWORD:
        return "300px";
      case AuthState.NEW_PASSWORD:
        return "398px";
      case AuthState.VERIFY_EMAIL:
        return "400px";
    }
  };

  const transitions = useTransition(authState, {
    from: { maxHeight: "0px" },
    enter: { maxHeight: getHeight() },
    leave: { maxHeight: "0px" },
    config: { duration: 10 },
  });

  const containerStyle = useSpring({
    from: { height: "500px" },
    to: { height: getHeight() },
  });
  return (
    <AuthLayout>
      <animated.div style={containerStyle}>
        {transitions((style, item) => {
          const renderContent = () => {
            switch (item) {
              case AuthState.LOGIN:
                return <SignIn />;
              case AuthState.TWO_FACTOR_AUTH:
                return <TwoFactorAuthentication />;
              case AuthState.FORGOT_PASSWORD:
                return <ForgotPassword />;
              case AuthState.NEW_PASSWORD:
                return <NewPassword />;
              case AuthState.VERIFY_EMAIL:
                return <VerifyEmail />;
              case AuthState.SIGN_UP:
                return <SignUp />;
            }
          };
          return <animated.div style={style}>{renderContent()}</animated.div>;
        })}
      </animated.div>
    </AuthLayout>
  );
};

export default Auth;

const AuthLayout = ({ children }: any) => {
  const isMobile = useIsMobile();

  const image = useMobileVersion({
    desktop: (
      <Grid item>
        <img
          src={Img}
          style={{
            width: "100%",
            height: "100vh",
          }}
          alt="Banner"
        />
      </Grid>
    ),
    mobile: (
      <Grid item style={{ padding: 16, margin: "auto" }}>
        <img src={ImgSmall} width={184} alt="Banner" />
      </Grid>
    ),
  });

  const style = useMobileVersion({
    desktop: { flexGrow: 1, paddingLeft: 120, paddingRight: 120 },
    mobile: { padding: 16, width: "100vw" },
  });

  const containerStyle = useMobileVersion({
    desktop: { height: "100%", width: 480, margin: "auto" },
    mobile: { height: "100%", width: "100%", margin: "auto" },
  });
  return (
    <Grid container wrap="nowrap" direction={isMobile ? "column" : "row"}>
      {image}
      <Grid item style={style}>
        <Grid
          container
          justifyContent={"center"}
          direction={"column"}
          style={containerStyle}
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
