import { Grid } from "@mui/material";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import IconButton from "../../../components/IconButton";

import Autocomplete from "components/Autocomplete";
import CustomPopover from "components/CustomPopover";
import DateRangePicker from "components/DateRangePicker";
import { startOfMonth } from "date-fns";
import { useIsMobile } from "hooks/useIsMobile";
import { useCustomSearchParams } from "hooks/useParams";

const LogsAction = () => {
  const [params, setSearchParams] = useCustomSearchParams({
    startDate: startOfMonth(new Date()).toISOString(),
    endDate: new Date().toISOString(),
  });

  const isMobile = useIsMobile();

  const onSelectFilter =
    (key: string) =>
    (
      event: any,
      value: { id: number; name?: string; label?: string } | null
    ) => {
      const newParams = { ...params };
      if (value) {
        newParams[key] = value.id;
        if (key === "service_id") {
          newParams["service_name"] = value.name;
        } else {
          newParams["name"] = value.label;
        }
      } else {
        delete newParams[key];
      }
      setSearchParams(newParams);
    };

  const dateRangeParams = {
    startDate: new Date(params.startDate),
    endDate: new Date(params.endDate),
    key: "selection",
  };

  const today = new Date();

  const serviceValue = params.service_id
    ? {
        value: params.service_id,
        name: params.service_name,
      }
    : null;

  const apiKeyValue = params.api_key_id
    ? {
        value: params.api_key_id,
        label: params.name,
      }
    : null;

  return (
    <Grid
      container
      style={{ gap: 8, marginTop: isMobile ? 10 : 0 }}
      direction={"row"}
    >
      <Grid item>
        <CustomPopover
          PopoverHandler={
            <IconButton>
              <FilterIcon />
            </IconButton>
          }
          PopoverContent={
            <Grid
              container
              direction="column"
              style={{
                gap: 16,
                padding: 16,
                minWidth: 150,
              }}
            >
              <Autocomplete
                key="service_id"
                label="Service"
                value={serviceValue}
                style={{
                  height: 42,
                  width: isMobile ? 150 : 300,
                }}
                onChange={onSelectFilter("service_id")}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 42,
                    padding: "8px 16px",
                  },
                  "& .MuiInputBase-input": {
                    height: 0,
                    paddingLeft: "0px !important",
                  },
                  "& .Mui-expanded": {},
                }}
                options={[]}
                url="/products/services"
                getOptionLabel={(option) => option.name}
              />

              <Autocomplete
                label="API Key"
                value={apiKeyValue}
                onChange={onSelectFilter("api_key_id")}
                style={{
                  height: 42,
                  width: isMobile ? 150 : 300,
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 42,
                    padding: "8px 16px",
                  },
                  "& .MuiInputBase-input": {
                    height: 0,
                    paddingLeft: "0px !important",
                  },
                }}
                options={[]}
                url="/devs/api-keys"
                getOptionLabel={(option) => option.label}
              />
            </Grid>
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </Grid>
      <Grid item>
        <DateRangePicker
          value={dateRangeParams}
          onChange={(ranges: any) => {
            const startDate =
              ranges.selection?.startDate ||
              new Date(ranges.startDate).toISOString();

            const endDate =
              ranges.selection?.endDate ||
              new Date(ranges.endDate).toISOString();

            setSearchParams({
              ...params,
              startDate,
              endDate,
            });
          }}
          maxDate={today}
        />
      </Grid>
    </Grid>
  );
};

export default LogsAction;
