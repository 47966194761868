const LogoutSvg = ({ fill = "#607D8B" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill={fill}
    >
      <path
        opacity="0.4"
        d="M11.0333 2.16602C11.425 2.16602 11.75 2.48268 11.75 2.88268V18.1243C11.75 18.516 11.4333 18.841 11.0333 18.841C6.12501 18.841 2.70001 15.416 2.70001 10.5077C2.70001 5.59935 6.13335 2.16602 11.0333 2.16602Z"
        fill={fill}
      />
      <path
        d="M17.1167 10.1168L14.75 7.7418C14.5083 7.50013 14.1083 7.50013 13.8666 7.7418C13.625 7.98346 13.625 8.38346 13.8666 8.62513L15.1667 9.92513H7.19165C6.84998 9.92513 6.56665 10.2085 6.56665 10.5501C6.56665 10.8918 6.84998 11.1751 7.19165 11.1751H15.1667L13.8666 12.4751C13.625 12.7168 13.625 13.1168 13.8666 13.3585C13.9916 13.4835 14.15 13.5418 14.3083 13.5418C14.4667 13.5418 14.625 13.4835 14.75 13.3585L17.1167 10.9835C17.3583 10.7501 17.3583 10.3585 17.1167 10.1168Z"
        fill={fill}
      />
    </svg>
  );
};

export default LogoutSvg;
