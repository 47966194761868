import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

type SaveData = {
  id: number;
};
export const saveDefaultMethod = async ({ id }: SaveData) => {
  try {
    const response = await axios.post("/billing/set-default-method", {
      id,
    });

    toast.success("Successfully saved default method");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to save default method"
    );
    throw error;
  }
};
