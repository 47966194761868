import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

type SaveData = {
  top_up_threshold: number;
  top_up_amount: number;
};
export const enableAutoTopUp = async (data: SaveData) => {
  try {
    const response = await axios.post("/billing/enable-auto-top-up", data);
    toast.success("Auto Top-up enabled successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to update. Please try again"
    );
    throw error;
  }
};
