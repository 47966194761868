import { toast } from "react-toastify";

export const sendErrorMessage = (error: any) => {
  if (error.response?.data?.detail) {
    if (Array.isArray(error.response.data.detail)) {
      error.response.data.detail.forEach((item: any) => {
        toast.error(item.msg);
      });
    } else {
      toast.error(error.response.data.detail);
    }
    return;
  }
  if (Array.isArray(error)) {
    error.forEach((item: any) => {
      toast.error(item.msg);
    });
    return;
  }
  if (typeof error === "string") {
    toast.error(error);
    return;
  }
  toast.error("Failed to perform the operation. Please try again");
};
