import { ThemeProvider } from "@mui/material";
import theme from "libs/theme";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "routes/Router";
import "./App.css";
const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router />
      <ToastContainer limit={3} autoClose={3000} />
    </ThemeProvider>
  );
};

export default App;
