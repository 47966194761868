import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

export const disableAutoTopUp = async () => {
  try {
    const response = await axios.post("/billing/disable-auto-top-up");
    toast.success("Auto Top-up disabled successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to update. Please try again"
    );
    throw error;
  }
};
