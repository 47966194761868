import { Grid } from "@mui/material";
import DataTable from "components/DataTable";
import Text from "components/Text";
import { useCustomSearchParams } from "hooks/useParams";
import { useTheme } from "hooks/useTheme";
import { BoxWrapper } from "pages/billing/Billing";
import { useRef } from "react";
import { formatCurrency, formatNumeric } from "utils/mix";
import FormDialog from "./FormDialog";
import Button from "components/Button";

export default function ServiceQuotasTable() {
  const ref = useRef<any>();
  const theme = useTheme();
  const [, setSearchParams] = useCustomSearchParams();
  return (
    <Grid container style={{ marginTop: 8, gap: 24 }}>
      <BoxWrapper>
        <DataTable
          ref={ref}
          columns={[
            {
              id: "service_name",
              label: "Service Name",
              style: {
                fontWeight: 600,
                minWidth: 150,
                color: theme.palette.blueGray[800],
              },
            },
            {
              id: "request_price",
              label: "Request price",
              style: {
                minWidth: 150,
                fontWeight: 600,
              },
              cellRender(value) {
                return formatCurrency(value);
              },
            },
            {
              id: "concurrency_limit",
              label: "Concurrency limit",
              style: {
                minWidth: 150,
              },
              cellRender(value) {
                return formatNumeric(value);
              },
            },
          ]}
          fetchUrl="/quotas/service-quotas"
          isPagination={false}
          customActions={(row) => (
            <Button
              style={{ height: 36 }}
              onClick={() => {
                setSearchParams({
                  tab: "service-quotas",
                  id: row.service_id,
                });
              }}
            >
              Upgrade Limits
            </Button>
          )}
        />
      </BoxWrapper>

      <BoxWrapper
        style={{
          backgroundColor: theme.palette.primary["light"],
        }}
      >
        <Grid container direction="column" style={{ padding: 6 }}>
          <Text variant="subtitle1" color={"#000"} fontWeight={600}>
            Quotas & Limits Overview
          </Text>
          <ul>
            <li>
              <Text variant="menu02">
                <strong>Default Access:</strong> Unsubscribed users receive
                default concurrency and pricing.
              </Text>
            </li>
            <li>
              <Text variant="menu02">
                <strong>Subscribed Plans:</strong> Subscription adjusts your
                limits and pricing according to your plan.
              </Text>
            </li>
            <li>
              <Text variant="menu02">
                <strong>High-Volume Customization:</strong> For PAYG or
                high-volume users, we offer custom plans tailored to optimize
                both concurrency and cost.
              </Text>
            </li>
          </ul>
          <Text variant="subtitle2">
            For custom solutions, please contact our{" "}
            <a
              href="https://proapis.com/contact-us"
              target="_blank"
              rel="noreferrer"
            >
              support team
            </a>
          </Text>
        </Grid>
      </BoxWrapper>
      <FormDialog refresh={() => ref.current?.refresh()} />
    </Grid>
  );
}
