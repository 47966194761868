import Layout from "components/Layout";
import { login, setIsLoading } from "features/userSlice";
import { useAuth } from "hooks/useAuth";
import { RootState } from "libs/store";
import { getUser } from "pages/auth/actions/getUser";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }: any) => {
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const isAuthenticated = auth.isAuthenticated();

  const getMe = useCallback(async () => {
    dispatch(setIsLoading(true));
    const user = await getUser();

    dispatch(login(user));
    dispatch(setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && !user?.id) {
      getMe();
    }
  }, [getMe, isAuthenticated, user?.id]);

  return auth.isAuthenticated() ? (
    <Layout>{children}</Layout>
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
};

export default PrivateRoute;
