import { Grid } from "@mui/material";
import { usageAnalytics } from "actions/usageAnalytics";
import ChartWidgetIcon from "assets/icons/chart-widget.png";
import PaymentIcon from "assets/icons/payment.svg";
import { useQuery } from "react-query";
import { formatCurrency, formatNumeric } from "utils/mix";
import Card from "../../components/Card";
import Table from "./components/Table";

export default function Dashboard() {
  const { data } = useQuery({
    queryKey: "usageAnalytics",
    queryFn: () => {
      return usageAnalytics({});
    },
  });

  return (
    <Grid container style={{ gap: 24 }}>
      <Grid container justifyContent="space-between" style={{ gap: 24 }}>
        <Grid
          item
          style={{
            flexGrow: 1,
          }}
        >
          <Card
            title="Balance"
            body={formatCurrency(data?.summary?.current_balance || 0)}
            icon={<img src={PaymentIcon} width={48} alt="" />}
          />
        </Grid>
        <Grid
          item
          style={{
            flexGrow: 1,
          }}
        >
          <Card
            title="Monthly Requests"
            body={formatNumeric(data?.summary?.requests?.current_month ?? 0)}
            icon={<img src={ChartWidgetIcon} width={96} alt="" />}
          />
        </Grid>
        <Grid
          item
          style={{
            flexGrow: 1,
          }}
        >
          <Card
            title="All Time Requests"
            body={formatNumeric(data?.summary?.requests?.all_time ?? 0)}
            icon={<img src={ChartWidgetIcon} width={96} alt="" />}
          />
        </Grid>
      </Grid>
      <Table />
    </Grid>
  );
}
