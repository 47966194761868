import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

export const updateApiKeyLabel = async (data: {
  id: string;
  label: string;
}) => {
  try {
    const response = await axios.patch(`/devs/api-keys/${data.id}`, {
      label: data.label,
    });
    toast.success("API Key updated successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to update. Please try again"
    );
    throw error;
  }
};
