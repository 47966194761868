import axios from "libs/axios";
import { sendErrorMessage } from "libs/toast";
import { toast } from "react-toastify";

type Update = {
  otp: string;
  two_fa_status: "enable" | "disable";
};
export const toggleTwoFA = async ({ otp, two_fa_status }: Update) => {
  try {
    const response = await axios.post(`/account/toggle-2fa`, {
      otp,
      two_fa_status,
    });

    toast.success("Two Factor Authentication updated successfully");
    return response.data;
  } catch (error: any) {
    sendErrorMessage(
      error.response?.data?.detail || "Failed to update. Please try again"
    );
    throw error;
  }
};
