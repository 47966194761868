import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import { TimePeriodStats } from "actions/usageAnalytics";
import {
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  TooltipModel,
} from "chart.js";
import Text from "components/Text";
import { CustomThemeOptions } from "libs/theme";
import { FC, useMemo, useRef } from "react";
import { Line } from "react-chartjs-2";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { formatCurrency, formatDate, formatNumeric } from "utils/mix";

ChartJS.register(Colors);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

type Props = {
  timeSeries?: TimePeriodStats["timeseries"];
  isFetching: boolean;
  date_range: Date[];
};
const Chart: FC<Props> = ({ timeSeries, isFetching, date_range }) => {
  const theme = useTheme() as CustomThemeOptions;

  const chartRef = useRef<any>(null);

  const getOrCreateTooltip = (chart: ChartJS) => {
    let tooltipEl = chart.canvas.parentNode?.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "white";
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.color = "gray";
      tooltipEl.style.opacity = "1";
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.minWidth = "180px";
      tooltipEl.style.boxShadow = "0px 0px 10px 0px rgba(0, 0, 0, 0.4)";

      const table = document.createElement("table");
      table.style.margin = "0px";
      table.style.borderCollapse = "collapse";
      table.style.width = "100%";
      tooltipEl.appendChild(table);
      chart.canvas.parentNode?.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context: {
    chart: ChartJS;
    tooltip: TooltipModel<"line">;
  }) => {
    // Tooltip Element
    const { chart, tooltip } = context;

    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = "0";
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);

      const tableHead = document.createElement("thead");
      titleLines.forEach((title) => {
        const tr = document.createElement("tr");
        const th = document.createElement("th");
        const text = document.createTextNode(title);
        tr.style.borderBottom = "1pt solid #e5e7eb";
        th.style.padding = "6px 12px";

        th.style.textAlign = "start";
        th.appendChild(text);
        tr.appendChild(th);

        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement("tbody");
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];

        const span = document.createElement("span");

        span.style.background = colors.borderColor.toString();
        span.style.borderColor = colors.borderColor.toString();
        span.style.borderWidth = "2px";
        span.style.marginRight = "10px";
        span.style.height = "10px";
        span.style.width = "10px";
        span.style.display = "inline-block";
        span.style.borderRadius = "999px";

        const tr = document.createElement("tr");
        tr.style.backgroundColor = "inherit";
        tr.style.borderWidth = "0";

        const td = document.createElement("td");
        td.style.borderWidth = "0";

        const text = document.createTextNode(body as any);
        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        td.style.padding = `12px 12px ${
          i === bodyLines.length - 1 ? "12px" : "0px"
        } 12px`;

        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot?.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot?.appendChild(tableHead);
      tableRoot?.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = "1";
    const maxLeft = window.innerWidth - tooltipEl.offsetWidth;
    tooltipEl.style.left = Math.min(positionX + tooltip.caretX, maxLeft) + "px";
    tooltipEl.style.bottom = positionY + 70 + "px";
  };

  const labels: string[] = useMemo(() => {
    return (
      timeSeries?.data_points?.map((el) => {
        const date = new Date(el.timestamp);
        if (timeSeries.interval === "year") {
          return formatDate(data, "yyyy");
        }
        if (timeSeries.interval === "month")
          return formatDate(date, "MMMM , yyyy");
        return formatDate(new Date(el.timestamp));
      }) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSeries?.data_points]);

  const datasets = useMemo(() => {
    return (
      [
        {
          name: "Requests",
          field: "requests",
        },
        {
          name: "Cost",
          field: "cost",
          unit: "price",
        },
        {
          name: "Avg. Latency",
          field: "average_latency",
          unit: "ms",
        },
      ].map((service) => {
        return {
          label: service.name,
          unit: service.unit,
          data:
            (timeSeries?.data_points?.map((el) => {
              // @ts-ignore
              return el[service.field as any] ?? 0;
            }) as number[]) ?? [],
          fill: {
            target: "origin",
          },
          tension: 0.3,
          pointRadius: 4,
        };
      }) ?? []
    );
  }, [timeSeries?.data_points]);

  const data = {
    labels,
    datasets,
  };

  return (
    <Grid
      container
      style={{
        padding: 20,
        borderRadius: 16,
        boxShadow:
          "0px 0px 2px 0px rgba(153, 169, 182, 0.20), 0px 12px 24px -4px rgba(153, 169, 182, 0.12)",
        position: "relative",
      }}
      direction={"column"}
      justifyContent={"center"}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Text
          variant="h5"
          style={{ color: theme.palette.blueGray[800], fontWeight: 400 }}
        >
          API Usage
        </Text>
      </Grid>
      <Grid
        container
        style={{
          height: 242,
          marginTop: 20,
          width: "calc(100% - 10px)",
        }}
      >
        <Line
          ref={chartRef}
          options={{
            interaction: {
              mode: "index",
              intersect: false,
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            plugins: {
              legend: {
                position: "top" as const,
                align: "end" as const,
                labels: {
                  usePointStyle: true,
                  boxWidth: 8,
                  boxHeight: 8,
                },
              },
              title: {
                display: false,
              },
              tooltip: {
                enabled: false, // Disable default tooltip,
                external: externalTooltipHandler,
                callbacks: {
                  label: function (context) {
                    const { label, unit } = context.dataset as any;
                    switch (unit) {
                      case "price":
                        return `${label}: ${formatCurrency(context.parsed.y)}`;
                      default:
                        return `${label}: ${formatNumeric(context.parsed.y)} ${
                          unit ?? ""
                        }`;
                    }
                  },
                },
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                  drawOnChartArea: false,
                  z: 0,
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  stepSize: 200,
                },
              },
            },
          }}
          data={data}
        />
      </Grid>
      <Text
        variant="caption"
        style={{
          fontWeight: 500,
          color: theme.palette.blueGray[600],
          marginTop: 8,
          margin: "auto",
        }}
      >
        {formatDate(date_range[0], "MMMM d, yyyy")} -{" "}
        {formatDate(date_range[1], "MMMM d, yyyy")}
      </Text>
      {isFetching && (
        <Grid
          style={{
            position: "absolute",
            zIndex: 99,
            top: 0,
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
            background: "#b2cfe578",
            justifyContent: "center",
            borderRadius: "8px",
            left: 0,
            right: 0,
          }}
        >
          <Grid
            style={{
              backgroundColor: "#FFF",
              padding: "3px 8px",
              borderRadius: 4,
              border: `1px solid ${theme.palette.blueGray[100]}`,
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            }}
          >
            <Text variant="menu02" color={theme.palette.blueGray[600]}>
              Loading data
            </Text>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Chart;
