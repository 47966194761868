import { Grid, useTheme } from "@mui/material";
import { useBackdrop } from "components/Backdrop/hooks/useBackdrop";
import Button from "components/Button";
import Text from "components/Text";
import CheckboxGroup from "components/form-control/CheckboxGroup";
import Input from "components/form-control/Input";
import InputPassword from "components/form-control/InputPassword";
import { AuthState, login, setAuthState, setEmail } from "features/userSlice";
import Cookies from "js-cookie";
import { RootState } from "libs/store";
import { CustomThemeOptions } from "libs/theme";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginUser } from "./actions/loginUser";
import { emailRule } from "./utils/rules";

const SignIn: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const theme = useTheme() as CustomThemeOptions;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Backdrop, close, open } = useBackdrop();

  const { handleSubmit, control, getValues } = useForm({
    defaultValues: {
      email: user?.email || "",
      password: "",
      remember: [],
    },
  });

  const mutation = useMutation(loginUser, {
    onSuccess: (data) => {
      if (data === 403) {
        toast.warning("Please verify your email first.");
        dispatch(setEmail(getValues("email")));
        dispatch(setAuthState(AuthState.VERIFY_EMAIL));
        return;
      }
      if (data.session_id) {
        Cookies.set(
          "session",
          JSON.stringify({
            session_id: data.session_id,
            session_lifetime_seconds: data.session_lifetime_seconds,
          })
        );
        dispatch(setAuthState(AuthState.TWO_FACTOR_AUTH));
      } else {
        Cookies.set("token", data.access_token);
        dispatch(login(data.user));
        navigate("/dashboard");
      }
      close();
    },
    onError: () => {
      close();
    },
  });

  const onSubmit = (data: any) => {
    open();
    mutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant="subtitle1" style={{ marginBottom: 10 }}>
        Welcome Back! Good to see you again!
      </Text>
      <Text variant="h1" style={{ marginBottom: 20 }}>
        Login
      </Text>
      <Input
        name="email"
        control={control}
        label="Email Address"
        style={{ marginTop: 20 }}
        rules={emailRule}
      />

      <InputPassword
        name="password"
        control={control}
        style={{ marginTop: 20 }}
        label="Password"
        type="password"
      />

      <Grid
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ marginTop: 30 }}
      >
        <CheckboxGroup
          name="remember"
          control={control}
          options={[
            {
              label: "Remember me",
              value: "remember",
            },
          ]}
        />
        <Text
          variant="h6-bold"
          style={{
            color: theme.palette.primary.main,
            fontWeight: 600,
            textDecoration: "none",
          }}
          onClick={() => {
            dispatch(setAuthState(AuthState.FORGOT_PASSWORD));
          }}
        >
          Forgot Password?
        </Text>
      </Grid>
      <Button
        style={{ marginTop: "30px" }}
        fullWidth
        color="primary"
        type="submit"
      >
        Login
      </Button>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ marginTop: 20, gap: 10 }}
      >
        <Text variant="body2" style={{ color: theme.palette.blueGray[500] }}>
          Do not have an account?
        </Text>
        <Text
          variant="menu02"
          style={{
            color: theme.palette.primary.main,
            fontWeight: 600,
            textDecoration: "none",
          }}
          onClick={() => {
            dispatch(setAuthState(AuthState.SIGN_UP));
          }}
        >
          Sign Up
        </Text>
      </Grid>
      <Backdrop />
    </form>
  );
};

export default SignIn;
