import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Popover,
  useTheme,
} from "@mui/material";
import { logout } from "features/userSlice";
import { RootState } from "libs/store";
import { CustomThemeOptions } from "libs/theme";
import md5 from "md5";
import { cloneElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "routes/path";
import Divide from "./Divide";
import Text from "./Text";
import LogoutSvg from "./icons/Logout";

const AvatarPopover = () => {
  const theme = useTheme() as CustomThemeOptions;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const hash = user?.email ? md5(user?.email.trim().toLowerCase()) : "";
  const gravatarUrl = `https://www.gravatar.com/avatar/${hash}`;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={handleClick}>
        <Avatar src={gravatarUrl} />
      </IconButton>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid
          style={{
            padding: "16px 8px",
            minWidth: 196,
          }}
        >
          <Text
            variant="body2"
            style={{
              color: theme.palette.blueGray[800],
              fontWeight: 500,
              padding: "0 8px",
            }}
          >
            {user?.first_name} {user?.last_name}
          </Text>
          <Text
            variant="caption"
            style={{
              color: theme.palette.blueGray[500],
              marginTop: 2,
              padding: "0 8px",
            }}
          >
            {user?.email}
          </Text>

          <Divide
            style={{
              marginTop: 10,
            }}
          />

          <List style={{ marginTop: 4, padding: 0 }}>
            {Paths.filter((el) => el.isHeader).map((path, index) => {
              const isMatch = pathname === path.path;
              return (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    display: "block",
                    backgroundColor: isMatch
                      ? theme.palette.primary["light"]
                      : undefined,
                    color: isMatch ? theme.palette.primary["main"] : undefined,
                    borderRadius: "6px",
                    marginBottom: "4px",
                    marginTop: "4px",
                    padding: 0,
                  }}
                  onClick={() => {
                    handleClose();
                    navigate(path.path);
                  }}
                >
                  <ListItemButton
                    sx={{
                      padding: "6px 8px",
                    }}
                  >
                    {path.icon && (
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          justifyContent: "center",
                          marginRight: "8px",
                        }}
                      >
                        {cloneElement(path.icon as any, {
                          fill: isMatch
                            ? theme.palette.primary["main"]
                            : undefined,
                        })}
                      </ListItemIcon>
                    )}
                    <Text
                      variant="body2"
                      style={{
                        ...(isMatch
                          ? {
                              color: theme.palette.primary["main"],
                              fontWeight: 500,
                            }
                          : {
                              color: theme.palette.blueGray[500],
                            }),
                      }}
                    >
                      {path.name}
                    </Text>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>

          <Divide
            style={{
              marginTop: 10,
            }}
          />

          <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor: undefined,
              borderRadius: "6px",
              marginBottom: "4px",
              marginTop: "4px",
              padding: 0,
            }}
            onClick={() => {
              handleClose();
              dispatch(logout());
              navigate("/");
            }}
          >
            <ListItemButton
              sx={{
                padding: "6px 8px",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                  marginRight: "8px",
                }}
              >
                <LogoutSvg />
              </ListItemIcon>
              <Text
                variant="body2"
                style={{
                  color: theme.palette.blueGray[500],
                }}
              >
                Logout
              </Text>
            </ListItemButton>
          </ListItem>
        </Grid>
      </Popover>
    </>
  );
};

export default AvatarPopover;
