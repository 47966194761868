import { useTheme } from "@emotion/react";
import { CheckboxProps, FormControlLabel, FormGroup } from "@mui/material";
import { CustomThemeOptions } from "libs/theme";
import * as React from "react";
import CheckboxComp from "./Checkbox";
import Text from "./Text";

type Props = CheckboxProps & {
  label: string;
  labelStyle?: React.CSSProperties;
  title?: string;
};
const CheckboxGroup: React.FC<Props> = ({
  name,
  label,
  labelStyle,
  title,
  ...restProps
}) => {
  const theme = useTheme() as CustomThemeOptions;

  return (
    <FormGroup style={{ gap: 16 }}>
      {title && (
        <Text variant="h5" color={theme.palette.blueGray[800]} fontWeight={500}>
          {title}
        </Text>
      )}

      <FormControlLabel
        style={{
          height: 20,
          margin: 0,
        }}
        sx={{
          "& .MuiFormControlLabel-label": {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "19.6px",
            color: theme.palette.blueGray[600],
            ...labelStyle,
          },
        }}
        control={
          <CheckboxComp
            style={{
              padding: 0,
              width: 18,
              height: 18,
              marginRight: 8,
            }}
            {...restProps}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default CheckboxGroup;
